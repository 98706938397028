import React, { useContext, useEffect, useState } from 'react';
import { Button, Layout, notification, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { ApplicationContext } from '../../../../context/AppContext';
import translate from '../../../../locale/en_translate.json';
import logsIcon from '../../../../assets/images/nEye.svg';
import usePaginate from '../../../../hooks/usePaginate';
import { initScroller } from '../../../../constants/Common';
import { mapNexusTractorMigrationHistory } from '../../../../lib/dataFormat';
import InfiniteScrollTable from '../../../common/InfiniteScrollTable';
import { getMigrationDataHistory } from '../../API';
import LogDetailsModal from './LogDetailsModal';

interface Props {
  showModal: boolean;
  closeModel: () => void;
  migrationHistory: any;
}

let columns: any[] = [];
export const MigrationHistory: React.FC<Props> = ({
  showModal,
  closeModel,
  migrationHistory,
}: Props) => {
  const { t } = useTranslation();
  const Data: any = migrationHistory;
  const { userDetails } = useContext(ApplicationContext);
  const [loader, setLoader] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize] = useState<any>(25);
  const [detailLogsModal, setDetailLogsModal] = useState<boolean>(false);
  const [editLog, setEditLog] = useState<any>('');
  const [headers, setHeaders] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [initialize, setInitialize] = useState(false);
  const { filterData, setData, activity, hasMore, checkActivity } =
    usePaginate();

  const wrapResult = async (
    pageNumber: number,
    pageSize: number,
    serialNumber: number,
  ) => {
    const result = await getMigrationDataHistory(
      pageNumber,
      pageSize,
      serialNumber,
    );
    return {
      result,
    };
  };

  const getBasestationSurveyLogs = async () => {
    try {
      if (checkActivity()) initScroller();
      setLoader(true);
      const { result } = await wrapResult(
        pageNumber,
        pageSize,
        Data.serial_number,
      );
      setTotal(result._metadata.total_records_count);
      const { records } = result;
      let data = Array.isArray(records) ? records : [];
      data = mapNexusTractorMigrationHistory(data);
      setData(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
      if (!initialize) setInitialize(true);
    }
  };

  const loadData = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      pageNumber !== 1 ? setPageNumber(1) : getBasestationSurveyLogs();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getBasestationSurveyLogs();
    }
  };

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const migrationLogs = (record: any) => {
    setEditLog(record);
    setDetailLogsModal(true);
  };

  useEffect(() => {
    columns = [
      {
        title: `${t(translate.baseStation.tractorName)}`,
        dataIndex: 'tractor_name',
        key: 'tractor_name',
      },
      {
        title: `${t(translate.tractors.sourceOrganization)}`,
        dataIndex: 'source_organization',
        key: 'source_organization',
      },
      {
        title: `${t(translate.tractorMigration.targetOrg)}`,
        dataIndex: 'target_organization',
        key: 'target_organization',
      },
      {
        title: `${t(translate.logViewer.createdTime)}`,
        dataIndex: 'created_date_time',
        key: 'created_date_time',
      },
      {
        title: `${t(translate.logViewer.status)}`,
        dataIndex: 'status',
        key: 'status',
        width: '150px',
      },
      {
        title: `${t(translate.tractorMigration.logs)}`,
        dataIndex: 'id',
        key: 'id',
        width: '130px',
        render: (id: number, record: any) => (
          <>
            <div className="logsimg">
              <img
                src={logsIcon}
                alt="logs"
                onClick={() => migrationLogs(record)}
              />
            </div>
          </>
        ),
      },
    ];
    const header: any = [];
    if (columns) {
      columns.forEach((column: any) =>
        header.push({
          label: column.title,
          key: column.dataIndex,
        }),
      );
      setHeaders(header);
    }
  }, []);

  useEffect(() => {
    loadData();
  }, [userDetails, pageNumber, pageSize]);

  return (
    <Layout>
      <div className="mainContent">
        <div className="tblContainer viewportContainer">
          <Row>
            <div className="common_wrapper tabFilterWidget_wrapper">
              <div className="filters_card personnelCard ad_filters_card">
                <div className="displayFlex">
                  <Button
                    onClick={() => {
                      closeModel();
                    }}
                  >
                    Back
                  </Button>
                </div>
              </div>
              <div className="common_table">
                <InfiniteScrollTable
                  columns={columns}
                  loading={loader}
                  hasMore={hasMore}
                  filterData={filterData}
                  totalcount={total}
                  handleLoadMore={handleLoadMore}
                  filename="Tractor Migration History"
                />
              </div>
            </div>
          </Row>
        </div>
      </div>
      {detailLogsModal && (
        <LogDetailsModal
          showModel={detailLogsModal}
          closeModel={() => setDetailLogsModal(false)}
          logDetails={editLog}
        />
      )}
    </Layout>
  );
};
export default MigrationHistory;
