import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal, notification, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import translate from '../../../../locale/en_translate.json';
import { editComment } from '../../API';

interface Props {
  showModal: boolean;
  closeModal: () => void;
  selectedTicketId: number;
  commentsList: any;
}

const EditCommentModal: React.FC<Props> = ({
  showModal,
  closeModal,
  selectedTicketId,
  commentsList,
}: Props) => {
  const { t } = useTranslation();
  const { TextArea } = Input;
  const [commentData, setCommentData] = useState<string>('');
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (commentData && commentData.trim().length > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [commentData]);

  const updateComment = async () => {
    const { id } = commentsList;
    const payload = {
      id,
      data_key: 'COMMENTS',
      data_values: commentData,
    };
    try {
      const response: { msg: string } = await editComment(
        selectedTicketId,
        payload,
      );
      closeModal();
      notification.success({ message: response.msg });
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    }
  };

  return (
    <>
      <Modal
        title={t(translate.serviceticket.editComment)}
        visible={showModal}
        onOk={() => updateComment()}
        onCancel={() => closeModal()}
        okText="Save"
        okButtonProps={{
          className: 'ant-btn-primary',
          disabled: isDisabled,
        }}
        cancelButtonProps={{
          className: 'displaynone',
        }}
      >
        <Form.Item>
          <TextArea
            style={{ width: '100%' }}
            placeholder=""
            showCount
            defaultValue={commentsList.data_text}
            autoSize={{ minRows: 4, maxRows: 8 }}
            onChange={(e: any) => setCommentData(e.target.value)}
          />
        </Form.Item>
      </Modal>
    </>
  );
};

export default EditCommentModal;
