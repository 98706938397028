import { notification } from 'antd';
import { useContext } from 'react';
import { UpdateComplianceCheck } from '../../../constants/Api';
import { ApplicationContext } from '../../../context/AppContext';

const useComplaince = () => {
  const { userDetails } = useContext(ApplicationContext);
  const updateCompliance = async (data: any) => {
    try {
      await UpdateComplianceCheck(
        userDetails.organization.api_url,
        userDetails.organization_id,
        userDetails?.id,
        data,
      );
    } catch (error: any) {
      notification.error({ message: error.message });
    }
  };

  const updateBulletin = async (data: any) => {
    try {
      await UpdateComplianceCheck(
        userDetails.organization.api_url,
        userDetails.organization_id,
        userDetails?.id,
        data,
      );
    } catch (error: any) {
      notification.error({ message: error.message });
    }
  };

  return { updateCompliance, updateBulletin };
};

export default useComplaince;
