import React, { useContext, useEffect, useState } from 'react';
import { notification, Row } from 'antd';
import Layout from 'antd/lib/layout/layout';
import {
  getAllControls,
  getJumpAndGoDetails,
  getTractorsListData,
} from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import { ShadowView, TractorsList } from '../../constants/types';
import {
  fromToDateWrapper,
  getDateTimes,
  getTime,
  getTractorAvailableState,
  initScroller,
  tractorSort,
} from '../../constants/Common';
import 'react-datepicker/dist/react-datepicker.css';
import translate from '../../locale/en_translate.json';
import { useTranslation } from 'react-i18next';
import InfiniteScrollTable from '../common/InfiniteScrollTable';
import moment from 'moment';
import SupportHistoryView from '../supportHistory/SupportHistoryView';
import CustomSelect from '../common/CustomSelect2';
import CustomDate from '../common/CustomDate';
import RoutesConstants from '../../routes/RoutesConstant';
import { EXECUTE } from '../remote_drive_new/actions';
import { useHistory } from 'react-router-dom';
import usePaginate from '../../hooks/usePaginate';

interface TractorResponseType {
  label: string;
  value: number | string | boolean;
  disabled: boolean;
  index: number | string;
  isAvailable?: string;
}

const Copycat: React.FC = () => {
  const { APPReducer, userDetails } = useContext(ApplicationContext);
  // const [dataSource, setDataSource] = useState<ShadowView[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [loader, setLoader] = useState<boolean>(false);
  const [pageSize] = useState<any>(25);
  const { t } = useTranslation();
  const [tractors, setTractorsData] = useState<TractorResponseType[]>([]);
  const [tractorId, setTaractorId] = useState<string>();
  const [ControlName] = useState<string>('Copycat');
  const [fromtime, setfromTime] = useState<any>(
    moment().subtract(1, 'day').toDate(),
  );
  const [totime, settoTime] = useState<any>(moment().toDate());
  const [copycatModel, setCopycatModel] = useState<boolean>(false);
  const [driveControl, setDriveControl] = useState();
  const [totalcount, settotalcount] = useState<any>();
  const { push } = useHistory();
  const [state, dispatch] = APPReducer;
  const { filterData, setData, hasMore, checkActivity, activity } =
    usePaginate();

  useEffect(() => {
    if (
      userDetails &&
      userDetails.organization &&
      fromtime === null &&
      totime === null
    ) {
      loadData();
    }
  }, [userDetails, pageNumber, pageSize, tractorId, fromtime, totime]);

  useEffect(() => {
    if (fromtime && totime) {
      if (userDetails && userDetails.organization) loadData();
    }
  }, [tractorId, fromtime, totime, pageNumber]);

  const loadData = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      pageNumber !== 1 ? setPageNumber(1) : getShadowView();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getShadowView();
    }
  };

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getTractorsList();
    }
  }, [userDetails]);

  const getShadowView = async () => {
    if (checkActivity()) initScroller();
    const { organization } = userDetails;
    try {
      setLoader(true);
      const from = fromtime ? fromtime.getTime() : '';
      const to = totime ? totime.getTime() : '';
      const shadowview = await getAllControls(
        organization.api_url,
        tractorId || '',
        ControlName,
        from,
        to,
        pageNumber,
        pageSize,
      );
      settotalcount(shadowview._metadata.total_records_count);
      const { records } = shadowview;
      let data: ShadowView[] = Array.isArray(records) ? records : [];
      data = data.map((record: any) => ({
        id: record.id,
        name: record.tractor_id?.name,
        first_name:
          record.operator_id?.first_name + ' ' + record.operator_id?.last_name,
        drive_action_uuid: record.drive_action_uuid,
        duration_in_seconds: getTime(record.duration_in_seconds),
        acr_start_date_time: getDateTimes(record.acr_start_date_time),
        acr_finish_date_time: getDateTimes(record.acr_finish_date_time),
        tractorId: record.tractor_id?.id,
        acr_uuid: record.acr_uuid,
        acr_start_date_timeVideo: record.acr_start_date_time,
        acr_finish_date_timeVideo: record.acr_finish_date_time,
      }));
      setData(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
    }
  };
  const getTractorsList = async () => {
    try {
      const tractor: { records: TractorsList[] } = await getTractorsListData(
        userDetails.organization.api_url,
        userDetails.organization_id,
        pageNumber,
      );
      const data =
        tractor && tractor.records && tractor.records.length > 0
          ? tractor.records
          : [];
      const tractorData: TractorResponseType[] = data.map((d: TractorsList) => {
        return {
          label: d.name,
          value: d.id,
          disabled: false,
          index: d.id,
          id: d.id,
          connection: d.connection,
          isOnline: d.connection === 'online' ? true : false,
          isAvailable: getTractorAvailableState(d.connection, d?.heartbeat),
        };
      });
      // eslint-disable-next-line no-console
      const arr = tractorSort(tractorData, 'label');
      setTractorsData(arr);
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.log(error);
      notification.error({
        message: error.message | error.message,
      });
    }
  };

  const handleSelect = async (e: any) => {
    setTaractorId(e);
    setPageNumber(1);
  };

  const handleJumpAndGo = async (id: string) => {
    try {
      const data = await getJumpAndGoDetails(
        userDetails.organization.api_url,
        id,
      );
      if (data?.drive_action_uuid) {
        push(RoutesConstants.Analytic);
        dispatch({
          type: EXECUTE,
          payload: {
            routeRefresh: !state.routeRefresh,
            jAGDAction: { ...data, tractor_id: data?.tractor, tabId: '5' },
          },
        });
      }
    } catch (error: any) {
      notification.error({
        message: error?.message,
      });
    }
  };

  const handleCopyCat = (record: any) => {
    setDriveControl(record);
    setCopycatModel(true);
  };

  const columns = [
    {
      title: `${t(translate.copycat.tractorName)}`,
      dataIndex: 'name',
      key: 'name',
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      onCell: (record: any) => ({
        onClick: () => {
          handleCopyCat(record);
        },
      }),
    },
    {
      title: `${t(translate.copycat.operator)}`,
      dataIndex: 'first_name',
      key: 'first_name',
      sorter: (a: any, b: any) => a.first_name.localeCompare(b.first_name),
      onCell: (record: any) => ({
        onClick: () => {
          handleCopyCat(record);
        },
      }),
    },
    {
      title: `${t(translate.copycat.starttime)}`,
      dataIndex: 'acr_start_date_time',
      key: 'acr_start_date_time',
      // width: '240px',
      onCell: (record: any) => ({
        onClick: () => {
          handleCopyCat(record);
        },
      }),
    },
    {
      title: `${t(translate.copycat.endtime)}`,
      dataIndex: 'acr_finish_date_time',
      key: 'acr_finish_date_time',
      // width: '240px',
      onCell: (record: any) => ({
        onClick: () => {
          handleCopyCat(record);
        },
      }),
    },
    {
      title: `${t(translate.copycat.duration)}`,
      dataIndex: 'duration_in_seconds',
      key: 'duration_in_seconds',
      onCell: (record: any) => ({
        onClick: () => {
          handleCopyCat(record);
        },
      }),
    },
    {
      title: `${t(translate.dashboard.jumpandgo)}`,
      dataIndex: 'drive_action_uuid',
      render: (drive_action_uuid: any) => (
        <a onClick={() => handleJumpAndGo(drive_action_uuid)}>
          {drive_action_uuid}
        </a>
      ),
    },
  ];

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const onDateRangeChange = (dates: any) => {
    if (dates) {
      setPageNumber(1);
      const [from, to] = fromToDateWrapper(dates);
      setfromTime(from.toDate());
      settoTime(to.toDate());
    } else {
      setfromTime(null);
      settoTime(null);
    }
  };

  return (
    <Layout>
      <div className="mainContent">
        <div className="tblContainer viewportContainer">
          <Row>
            <div className="common_wrapper wWidget_wrapper">
              <div className="filters_card personnelCard">
                <CustomSelect
                  label="Tractor"
                  cssClass="min_width"
                  value={tractorId}
                  setValue={(id: any) => {
                    handleSelect(id);
                  }}
                  options={tractors.map((item) =>
                    Object.assign({
                      id: item.index,
                      name: item.label,
                      isAvailable: item.isAvailable,
                    }),
                  )}
                  optionKey="id"
                  optionDisplay="name"
                  isAll={true}
                />
                <CustomDate
                  label="Select Date"
                  cssClass="min_width"
                  value={[moment(fromtime), moment(totime)]}
                  onChange={(dates: any) => {
                    onDateRangeChange(dates);
                  }}
                  format="YYYY-MM-DD"
                />
              </div>

              <div className="common_table">
                <InfiniteScrollTable
                  columns={columns}
                  loading={loader}
                  hasMore={hasMore}
                  filterData={filterData}
                  totalcount={totalcount}
                  handleLoadMore={handleLoadMore}
                  filename="Copycat"
                />
              </div>
              <div>
                {copycatModel && (
                  <SupportHistoryView
                    title={t(translate.headers.copydetails)}
                    showModel={copycatModel}
                    closeModel={() => setCopycatModel(false)}
                    reportId={driveControl}
                  />
                )}
              </div>
            </div>
          </Row>
        </div>
      </div>
    </Layout>
  );
};

export default Copycat;
