import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import translate from '../../../../locale/en_translate.json';
import closeGreen from '../../../../assets/images/right_widget_close_icon.svg';
import ViewItem from '../../common/ViewItem';
import {
  getDateTime,
  secondsToHours,
  tractorCameras,
} from '../../../../constants/Common';
import { Button, Tooltip } from 'antd';
import { PubSub } from 'aws-amplify';
import { NetworkSpeed } from '../../../../constants/types';
import { checkNetworkSpeed } from '../../API';
import {
  getCameraViewAllow,
  getCameraViewStatus,
  getKeyValue,
  getValueByIndex,
} from '../../common/common';
import { mqttConstants } from '../../../../util/MqttConstants';
import { CloudUploadOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { getIndicatorImages } from '../../../basestation/Common';

interface Props {
  details: any;
  toggleWidget: boolean;
  onClose: (state?: boolean) => void;
}

const TractorHealthDetails: React.FC<Props> = ({
  details,
  toggleWidget,
  onClose,
}) => {
  const { t } = useTranslation();
  const [speed, setSpeed] = useState<any>();
  const [ipAddress, setIpAddress] = useState<string>('');
  const [n2IpAddress, setN2IpAddress] = useState<string>('');
  const [enableLoad, setEnableLoad] = useState<boolean>(false);

  const tooltip = (data: any) =>
    data?.length > 18 ? <Tooltip title={data}>{data}</Tooltip> : <>{data}</>;

  const reTestSpeed = () => {
    setEnableLoad(true);
    setSpeed(undefined);
    if (details) {
      checkNetworkSpeed(details.serial_number, details.organization?.id);
    }
  };

  useEffect(() => {
    PubSub.subscribe('tractor_network_check_report_to_nexus').subscribe({
      next: (data) => {
        const networkData: NetworkSpeed = data.value;
        setSpeed(networkData);
        setEnableLoad(false);
      },
    });
    if (details?.tractor?.tractor_model === 'PV-O') {
      const ip_address = details?.tractor?.ip_address
        ? `${details?.tractor?.ip_address} -p 5902`
        : null;
      const n2_ip_address = details?.tractor?.n2_ip_address
        ? `${details?.tractor?.n2_ip_address} -p 5903`
        : null;
      setIpAddress(ip_address ?? '-');
      setN2IpAddress(n2_ip_address ?? '-');
    } else {
      const ip_address = details?.tractor?.ip_address;
      const n2_ip_address = details?.tractor?.n2_ip_address;
      setIpAddress(ip_address ?? '-');
      setN2IpAddress(n2_ip_address ?? '-');
    }
  }, []);

  return (
    <div className={'rightSideWidegt ' + (toggleWidget ? 'expand' : 'hide')}>
      <div className="widgetCloseBtn flexBtn">
        <img
          src={closeGreen}
          alt="close icon"
          onClick={() => onClose()}
          data-testid="modelCloseIcon-TicketViewEdit"
        />
      </div>
      <div className="profileEdit widget_card">
        <div className="proRow">
          <h4 className="posRel  headline3">
            <span>Tractor #{details?.serial_number}</span>
          </h4>
        </div>
        <ViewItem
          title={t(translate.tractorHealthDetails.name)}
          content={details?.name}
        />
        <ViewItem title={t(translate.healthData.soc)} content={details?.soc} />
        <ViewItem
          title={t(translate.healthData.dealer)}
          content={details?.dealer?.name ?? '-'}
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.status)}
          content={details?.jng}
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.orgnazation)}
          content={details?.orgName}
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.lastOnlineTime)}
          content={details?.last_online_time}
        />
        <ViewItem
          title={t(translate.healthData.uploadSpeed)}
          content={details?.uploadSpeed}
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.remarks)}
          content={details?.remarks ?? '-'}
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.lockoutDetails)}
          content={details?.lockout_details}
        />
        <ViewItem
          title={t(translate.healthData.software)}
          content={details?.lockout_details}
        />
        <ViewItem
          title={t(translate.healthData.hours)}
          content={(Number(details?.hour_meter) / 3600).toFixed() || '-'}
        />
        <ViewItem
          title={t(translate.healthData.firmware)}
          content={details?.firmware_version ?? '-'}
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.dailyHourMeter)}
          content={secondsToHours(Number(details?.daily_hour_meter))}
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.updatedTime)}
          content={details?.date_time ?? '-'}
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.Operator)}
          content={details?.drive_action_details ?? '-'}
        />
        <ViewItem
          title={t(translate.healthData.downloadSpeed)}
          content={details?.downloadSpeed ?? '-'}
        />
        <ViewItem
          title={t(translate.tractors.serialNumber)}
          content={details?.serial_number ?? '-'}
        />
        <ViewItem
          title={t(translate.healthData.voucherUID)}
          content={details?.tractor?.voucher_uid ?? '-'}
        />
        <ViewItem
          title={t(translate.healthData.MountpointName)}
          content={details?.mountpoint_name ?? '-'}
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.radio_net_id)}
          content={details?.radio_details ? details?.radio_details[2] : '-'}
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.radio_channel_number)}
          content={details?.radio_details ? details?.radio_details[1] : '-'}
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.radio_address)}
          content={details?.radio_details ? details?.radio_details[0] : '-'}
        />
        <div className="proRowHealth">
          <h4 className="posRel  headline3">
            <span>{t(translate.tractorHealthDetails.xavierPowerStatus)}</span>
          </h4>
        </div>
        <ViewItem
          title={t(translate.tractorHealthDetails.x1)}
          content={getCameraViewStatus(
            details,
            mqttConstants.XAVIER1_POWER_STATUS,
          )}
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.x2)}
          content={getCameraViewStatus(
            details,
            mqttConstants.XAVIER2_POWER_STATUS,
          )}
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.x3)}
          content={getCameraViewStatus(
            details,
            mqttConstants.XAVIER3_POWER_STATUS,
          )}
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.x4)}
          content={getCameraViewStatus(
            details,
            mqttConstants.XAVIER4_POWER_STATUS,
          )}
        />
        <div className="proRowHealth">
          <h4 className="posRel  headline3">
            <span>{t(translate.tractorHealthDetails.xavierSystemStatus)}</span>
          </h4>
        </div>
        <ViewItem
          title={t(translate.tractorHealthDetails.x1)}
          content={getCameraViewStatus(
            details,
            mqttConstants.XAVIER1_SYSTEM_NODE,
          )}
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.x2)}
          content={getCameraViewStatus(
            details,
            mqttConstants.XAVIER2_SYSTEM_NODE,
          )}
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.x3)}
          content={getCameraViewStatus(
            details,
            mqttConstants.XAVIER3_SYSTEM_NODE,
          )}
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.x4)}
          content={getCameraViewStatus(
            details,
            mqttConstants.XAVIER4_SYSTEM_NODE,
          )}
        />
        <div className="proRowHealth">
          <h4 className="posRel  headline3">
            <span>{t(translate.tractorHealthDetails.nucStatus)}</span>
          </h4>
        </div>
        <ViewItem
          title={t(translate.tractorHealthDetails.nuc1PowerStatus)}
          content={getKeyValue(details, mqttConstants.nuc1_power_status)}
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.nuc2PowerStatus)}
          content={getKeyValue(details, mqttConstants.nuc2_power_status)}
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.nuc1CpuUsageStatus)}
          content={getKeyValue(details, mqttConstants.nuc1_cpu_usage)}
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.nuc2CpuUsageStatus)}
          content={getKeyValue(details, mqttConstants.nuc2_cpu_usage)}
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.nuc1CpuTemp)}
          content={getKeyValue(details, mqttConstants.nuc1_cpu_temp)}
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.nuc2CpuTemp)}
          content={getKeyValue(details, mqttConstants.nuc2_cpu_temp)}
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.nuc1DiskUsage)}
          content={getKeyValue(details, mqttConstants.nuc1_disk_usage)}
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.nuc2DiskUsage)}
          content={getKeyValue(details, mqttConstants.nuc2_disk_usage)}
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.nuc1CpuRam)}
          content={getKeyValue(details, mqttConstants.nuc1_cpu_ram)}
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.nuc2CpuRam)}
          content={getKeyValue(details, mqttConstants.nuc2_cpu_ram)}
        />
        <div className="proRowHealth">
          <h4 className="posRel  headline3">
            <span>{t(translate.tractorHealthDetails.Localization)}</span>
          </h4>
        </div>
        <ViewItem
          title={t(translate.tractorHealthDetails.latitude)}
          content={details?.localization?.latitude ?? '-'}
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.longitude)}
          content={details?.localization?.longitude ?? '-'}
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.headingAccuracy)}
          content={details?.localization?.xy_accuracy ?? '-'}
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.xyAccuracy)}
          content={details?.localization?.heading_accuracy ?? '-'}
        />
        <ViewItem
          title={t(translate.healthData.speed)}
          content={details?.velocity ?? '-'}
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.ptoState)}
          content={details?.localization?.pto_state ?? '-'}
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.goodLocalization)}
          content={
            details?.localization?.good_localization &&
            details?.localization?.good_localization === true
              ? `${t(translate.tractorHealthDetails.yes)}`
              : `${t(translate.tractorHealthDetails.no)}` || '-'
          }
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.odometerDistance)}
          content={details?.localization?.odometer_distance ?? '-'}
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.gpsStatus)}
          content={details?.localization?.gps_status ?? '-'}
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.pitch)}
          content={details?.localization?.pitch ?? '-'}
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.roll)}
          content={details?.localization?.roll ?? '-'}
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.direction)}
          content={details?.localization?.yaw ?? '-'}
        />
        <div className="proRowHealth">
          <h4 className="posRel  headline3">
            <span>{t(translate.tractorHealthDetails.cameraStatus)}</span>
          </h4>
        </div>
        <ViewItem
          title={t(translate.tractorHealthDetails.hitch)}
          content={
            getCameraViewAllow(details, tractorCameras.PTO)
              ? `${t(translate.tractorHealthDetails.available)}`
              : `${t(translate.tractorHealthDetails.notAvailable)}`
          }
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.driverCamera)}
          content={
            getCameraViewAllow(details, tractorCameras.DRIVER)
              ? `${t(translate.tractorHealthDetails.available)}`
              : `${t(translate.tractorHealthDetails.notAvailable)}`
          }
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.front)}
          content={
            getCameraViewAllow(details, tractorCameras.FRONT)
              ? `${t(translate.tractorHealthDetails.available)}`
              : `${t(translate.tractorHealthDetails.notAvailable)}`
          }
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.rear)}
          content={
            getCameraViewAllow(details, tractorCameras.REAR)
              ? `${t(translate.tractorHealthDetails.available)}`
              : `${t(translate.tractorHealthDetails.notAvailable)}`
          }
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.frontLeft)}
          content={
            getCameraViewAllow(details, tractorCameras.FRONT_LEFT)
              ? `${t(translate.tractorHealthDetails.available)}`
              : `${t(translate.tractorHealthDetails.notAvailable)}`
          }
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.rearLeft)}
          content={
            getCameraViewAllow(details, tractorCameras.REAR_LEFT)
              ? `${t(translate.tractorHealthDetails.available)}`
              : `${t(translate.tractorHealthDetails.notAvailable)}`
          }
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.frontRight)}
          content={
            getCameraViewAllow(details, tractorCameras.FRONT_RIGHT)
              ? `${t(translate.tractorHealthDetails.available)}`
              : `${t(translate.tractorHealthDetails.notAvailable)}`
          }
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.rearRight)}
          content={
            getCameraViewAllow(details, tractorCameras.REAR_RIGHT)
              ? `${t(translate.tractorHealthDetails.available)}`
              : `${t(translate.tractorHealthDetails.notAvailable)}`
          }
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.camera_calibration)}
          content={details?.camera_calibration ?? '-'}
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.camera_calibration_count)}
          content={details?.camera_calibration_count ?? '-'}
        />
        <div className="proRowHealth">
          <h4 className="posRel  headline3">
            <span>{t(translate.tractorHealthDetails.networkStatus)}</span>
          </h4>
        </div>
        <ViewItem
          title={t(translate.healthData.activeNetwork)}
          content={getKeyValue(details, mqttConstants.active_network, false)}
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.eth)}
          content={getKeyValue(details, mqttConstants.eth, false)}
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.wifiName)}
          content={getKeyValue(details, mqttConstants.wifi_ssid_name, false)}
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.routerName)}
          content={getKeyValue(details, mqttConstants.router_model, false)}
        />
        <ViewItem
          title={t(translate.healthData.downloadSpeed)}
          content={
            details?.network_speed?.network_health_report?.download_speed ?? '-'
          }
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.routerFirmwareVersion)}
          content={getKeyValue(
            details,
            mqttConstants.router_firmware_version,
            false,
          )}
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.lastFetchedTime)}
          content={
            details?.network_speed?.created_date_time
              ? getDateTime(details?.network_speed?.created_date_time)
              : '-'
          }
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.checkNetworkSpeed)}
          content={
            <div className="networkSpeedContainer">
              {speed ? (
                <p style={{ marginBottom: 0 }}>
                  <CloudUploadOutlined style={{ color: 'green' }} />{' '}
                  {speed?.network_health_report?.upload_speed
                    ? Number(
                        speed?.network_health_report?.upload_speed,
                      ).toFixed(1)
                    : 0}{' '}
                  {t(translate.tractorHealthDetails.mbps)} <br />
                  <CloudDownloadOutlined style={{ color: 'red' }} />{' '}
                  {speed?.network_health_report?.download_speed
                    ? Number(
                        speed?.network_health_report?.download_speed,
                      ).toFixed(1)
                    : 0}{' '}
                  {t(translate.tractorHealthDetails.mbps)}
                </p>
              ) : (
                '-'
              )}
              <Button
                className="defaultBtn checkSpeedBtn"
                onClick={() => reTestSpeed()}
                loading={enableLoad}
              >
                {t(translate.tractorHealthDetails.check)}
              </Button>
            </div>
          }
        />
        <div className="proRowHealth">
          <h4 className="posRel  headline3">
            <span>{t(translate.tractorHealthDetails.indicatorStatus)}</span>
          </h4>
        </div>
        <ViewItem
          title={t(translate.healthData.indicators)}
          content={
            details?.indicatorsData &&
            details?.indicatorsData.map((indicator: any, i: number) => (
              <span key={i}>
                <img
                  className="indicatorImg1"
                  width="30px"
                  height="30px"
                  alt=""
                  src={getIndicatorImages(
                    indicator.indicator_name,
                    indicator.indicator_level,
                  )}
                />
              </span>
            ))
          }
        />
        <div className="proRowHealth">
          <h4 className="posRel  headline3">
            <span>{t(translate.tractorHealthDetails.fileUploadStatus)}</span>
          </h4>
        </div>
        <ViewItem
          title={t(translate.tractorHealthDetails.logFileName)}
          content={tooltip(details?.latest_upload_log_file?.log_file_name)}
        />
        <ViewItem
          title={t(translate.healthData.dateAndtime)}
          content={
            details?.latest_upload_log_file?.date_time
              ? getDateTime(details?.latest_upload_log_file?.date_time)
              : ''
          }
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.pauseAll)}
          content={details?.tractor_files_upload_status?.pause_all.toString()}
        />
        <ViewItem
          title={t(translate.tractorHealthDetails.uploadAll)}
          content={details?.tractor_files_upload_status?.upload_all.toString()}
        />

        {details?.tractor_model !== null &&
          details?.tractor_model?.length >= 155 && (
            <>
              <div className="proRowHealth">
                <h4 className="posRel  headline3">
                  <span>{t(translate.tractorHealthDetails.sohSignals)}</span>
                </h4>
              </div>
              <ViewItem
                title={t(translate.tractorHealthDetails.bms2)}
                content={getValueByIndex(details?.tractor_model, 154)}
              />
              <ViewItem
                title={t(translate.tractorHealthDetails.bms2_2)}
                content={getValueByIndex(details?.tractor_model, 153)}
              />
            </>
          )}
      </div>
    </div>
  );
};

export default TractorHealthDetails;
