import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, notification, Tag } from 'antd';
import winterImage from './images/winterize.svg';
import xiconClose from '../../../assets/images/X_icon-01.svg';
import './styles.scss';
import { getBulletIn } from '../../../constants/Api';
import { ApplicationContext } from '../../../context/AppContext';
import { ComplainCode } from '../../../constants/types';
import useComplaince from './useComplaince';

const { Meta } = Card;

const WinterPopUp: React.FC = () => {
  const { userDetails } = useContext(ApplicationContext);
  const [complainCode, setComplainCode] = useState<ComplainCode | null>(null);
  const [toggle, setToggle] = useState(false);
  const { updateBulletin } = useComplaince();

  useEffect(() => {
    const getBulletInData = async () => {
      const {
        id,
        organization_id,
        organization: { api_url },
      } = userDetails;
      try {
        const result = await getBulletIn(api_url, organization_id, id);
        setComplainCode(result);
        setToggle(!result?.is_compliance_acknowledged);
      } catch (error: any) {
        notification.error(error.message);
      }
    };
    if (userDetails.organization) getBulletInData();
  }, [userDetails]);

  const acknowledge = async () => {
    const { compliance_id, compliance_code, compliance_version } =
      complainCode || {};
    const payload = {
      compliance_id,
      compliance_code,
      compliance_version,
      is_compliance_acknowledged: true,
      app_name: 'web',
    };
    await updateBulletin(payload);
  };

  const viewPdf = () => {
    acknowledge();
    setToggle(false);
    window.open(`${complainCode?.file_path}`, '_blank', 'noopener,noreferrer');
  };

  const handleClose = async () => {
    setToggle(false);
  };

  return toggle ? (
    <Card
      hoverable
      className="ADCCard"
      cover={
        <>
          <img
            src={xiconClose}
            alt=""
            className="ADCCloseIcon"
            onClick={() => handleClose()}
          />
          <img alt="example" src={winterImage} height="127" />
        </>
      }
    >
      <div className="ADCCardContent">
        <div className="an_n_heading">
          <div>
            <div className="newTagSec">
              <Tag className="newWinterTag">SERVICE TIP</Tag>
            </div>
            <Meta
              className="WinterCardMetaData"
              title="Winter Is Coming"
              description="Are you planning to shut down your fleet for the winter? Follow these instructions to safely store your MK-V, avoid potential service fees, and start the next season on the right footing."
            />
          </div>
          <div className="WinterCardFooter">
            <Button className="WinterFooterBtn" onClick={viewPdf}>
              View Instructions
            </Button>
          </div>
        </div>
      </div>
    </Card>
  ) : (
    <></>
  );
};

export default WinterPopUp;
