import React, { useState, memo } from 'react';
import { Select } from 'antd';

interface Props {
  label?: string;
  value?: string | number | undefined;
  cssClass?: string;
  setValue: (data: string | number | undefined) => void;
  options?: any[];
  optionKey?: string;
  optionDisplay?: string;
  required?: boolean;
  isAll?: boolean;
  testId?: any;
}

const CustomSelect: React.FC<Props> = ({
  label,
  value,
  setValue,
  cssClass,
  options,
  optionKey = 'id',
  optionDisplay = 'name',
  required,
  isAll,
  testId,
}: Props) => {
  const [focus, setFocus] = useState(false);

  const isEmpty = (value: any) => {
    if (typeof value === 'undefined') return true;
    if (typeof value === 'string' && value.trim() === '') return true;
    if (typeof value === 'number' && isNaN(value)) return true;
    return false;
  };

  const labelClass = focus || !isEmpty(value) ? 'label label-float' : 'label';
  return (
    <div
      className="float-label"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <Select
        data-testid={testId}
        style={{ width: '100%' }}
        value={value}
        className={cssClass}
        onSelect={(e: any) => {
          setValue(e);
        }}
        showSearch
        filterOption={(input, option) =>
          (option!.title as string)
            ?.toLowerCase()
            ?.includes(input?.toLowerCase())
        }
      >
        {isAll && <option value={undefined}>All {label}</option>}
        {options &&
          options?.length > 0 &&
          options?.map((data: any, key) => {
            return (
              <option
                title={optionDisplay ? data[optionDisplay] : data[optionKey]}
                value={data[optionKey]}
                key={key}
                data-testid={`${
                  optionDisplay ? data[optionDisplay] : data[optionKey]
                }-CustomSelect2`}
              >
                {optionDisplay ? data[optionDisplay] : data[optionKey]}
              </option>
            );
          })}
      </Select>
      <label className={labelClass}>
        {label} {required && <span className="float-span">*</span>}
      </label>
    </div>
  );
};

export default memo(CustomSelect);
