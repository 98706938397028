/* eslint-disable @typescript-eslint/no-explicit-any */
import { Card, notification } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import translate from '../../../../../locale/en_translate.json';
import { ApplicationContext } from '../../../../../context/AppContext';
import { migLogsList } from '../../../common/types';
import { getDateTime } from '../../../../../constants/Common';
import { pagenation } from '../../../../../constants/types';
import CustomTable from '../../../../common/CustomTable';
import { getBasestationMigrationLogs } from '../../../API';

interface Props {
  showModel: boolean;
  closeModel: () => void;
  logDetails: any;
}

const LogDetailsModal: React.FC<Props> = ({
  showModel,
  closeModel,
  logDetails,
}: Props) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [dataSource, setDataSource] = useState<migLogsList[]>([]);
  const [loader, setLoader] = useState<boolean>(false);

  const [pageSize, setPagesize] = useState<any>(25);
  const pagination = {
    current: 1,
    defaultPageSize: 25,
  };
  const [pageNumberData, setPageNumberData] = useState<any>(pagination);

  useEffect(() => {
    if (userDetails && userDetails.organization && logDetails) {
      getMigrationHistoryLogs();
    }
  }, [userDetails, pageNumber, pageSize, logDetails]);

  useEffect(
    () => () => {
      setDataSource([]);
    },
    [],
  );

  const getMigrationHistoryLogs = async () => {
    try {
      setLoader(true);
      const { records, _metadata } = await getBasestationMigrationLogs(
        logDetails.id,
        pageNumber,
        pageSize,
      );

      const data = records.map((record: any) => ({
        date: getDateTime(record.created_date_time),
        message: record.message,
      }));
      setDataSource(data);

      const page = {
        current: _metadata.page_number,
        pageSizeOptions: ['25'],
        total: _metadata.total_records_count,
      };
      setPageNumberData(page);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const handleChange = (page: pagenation) => {
    setPageNumber(page.current);
    setPagesize(page.pageSize);
  };

  const columns = [
    {
      title: `${t(translate.remarkshistory.date)}`,
      dataIndex: 'date',
      key: 'date',
      width: 190,
    },
    {
      title: `${t(translate.remarkshistory.message)}`,
      dataIndex: 'message',
      key: 'message',
    },
  ];

  const handleRefresh = () => {
    dataSource.length = 0;
    setPageNumber(1);
    getMigrationHistoryLogs();
  };

  return (
    <Modal
      title={t(translate.tractorMigration.logs)}
      visible={showModel}
      className="tm-modal customModal"
      onCancel={closeModel}
      onOk={handleRefresh}
      okText="Refresh"
      cancelText="Close"
      okButtonProps={{
        className: 'okBtn',
      }}
      cancelButtonProps={{
        className: 'canelBtn',
      }}
    >
      <div style={{ cursor: 'pointer' }}>
        <CustomTable
          columns={columns}
          dataSource={dataSource}
          pagination={pageNumberData}
          handleChange={(page: pagenation) => {
            handleChange(page);
          }}
          loader={loader}
          fileName="logDetails"
        />
      </div>
    </Modal>
  );
};
export default LogDetailsModal;
