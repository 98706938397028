import React, { useState } from 'react';
import { Tabs } from 'antd';
import '.././style.css';
import KnowledgeBase from '../../../knowledgeBase/KnowlegeBase';

const { TabPane } = Tabs;

const Index: React.FC = () => {
  const [tabid, setTabid] = useState<string>('Knowledge Base');

  return (
    <div className="rightSideContainer">
      <Tabs
        activeKey={tabid}
        centered
        id="org-tab-Organization"
        className="common_tabs implementTab"
        onChange={(key) => {
          setTabid(key);
        }}
      >
        <TabPane
          tab="Knowledge Base"
          key="Knowledge Base"
          className="attTableHeight mBottom0"
        >
          {tabid === 'Knowledge Base' && <KnowledgeBase />}
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Index;
