/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Modal, notification, Spin, Steps } from 'antd';
import { useTranslation } from 'react-i18next';
import { PubSub } from 'aws-amplify';
import { ApplicationContext } from '../../../../../context/AppContext';
import { NetworkSpeed } from '../../../../../constants/types';
import translate from '../../../../../locale/en_translate.json';
import Logs from './Logs';
import RestartTractor from './RestartTractor';
import Overview, { TractorReport } from './Overview';
import {
  checkBasestationNetworkSpeed,
  getBaseStationHealthReport,
  saveBasestationMigration,
} from '../../../API';

const { Step } = Steps;
interface Props {
  showModal: boolean;
  closeModel: () => void;
  selectedTractor: any;
  item: any;
  selectOrg: any;
}

export const MigrateModal: React.FC<Props> = ({
  showModal,
  closeModel,
  selectedTractor,
  item,
  selectOrg,
}: Props) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [current, setCurrent] = useState(0);
  const [migrationId, setMigrationId] = useState(0);
  const [tractorReport, setTractorReport] = useState<TractorReport>();
  const [loader, setLoader] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [iotInitialized, setIotInitialized] = useState(false);
  const [serviceCheckFailed, setServiceCheckFailed] = useState(true);
  const [checkSpeed, setServiceCheckSpeed] = useState<boolean>(true);
  const serviceCheckFailedRef = useRef(true);
  const [showServiceCheckFailure, setShowServiceCheckFailure] = useState(false);
  const [speed, setSpeed] = useState<NetworkSpeed>();

  useEffect(() => {
    if (item.migration?.status && item.migration?.status === 'Inprogress') {
      setMigrationId(item.migration?.id);
      setCurrent(current + 1);
    }
  }, [item]);

  useEffect(() => {
    if (speed) {
      setServiceCheckSpeed(false);
    }
  }, [speed]);

  useEffect(() => {
    setTimeout(() => {
      if (serviceCheckFailedRef.current) {
        setShowServiceCheckFailure(true);
      }
    }, 20000);
  }, []);

  useEffect(() => {
    PubSub.subscribe('nexus/bs/migration_service_check_response').subscribe({
      next: (data) => {
        setServiceCheckFailed(false);
        setShowServiceCheckFailure(false);
        serviceCheckFailedRef.current = false;
      },
    });

    PubSub.subscribe('tractor_network_check_report_to_nexus').subscribe({
      next: (data) => {
        const networkData: NetworkSpeed = data.value;
        setSpeed(networkData);
      },
    });
    setIotInitialized(true);
  }, []);

  const next = () => {
    if (current === 0) {
      migrateMethod();
    } else if (current === 2) {
      Modal.warning({
        title: `${t(translate.tractorMigration.warning)}`,
        content: `${t(translate.tractorMigration.warningmsg)}`,
        onOk: () => setCurrent(3),
      });
    } else {
      setCurrent(current + 1);
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const steps = [
    {
      title: `${t(translate.tractorMigration.overView)}`,
      content: (
        <div>
          <Overview
            selectedTractor={selectedTractor}
            tractorReport={tractorReport as TractorReport}
            item={item}
            reTestSpeed={() => reTestSpeed()}
            speed={speed as NetworkSpeed}
            showServiceCheckFailure={showServiceCheckFailure}
          />
        </div>
      ),
    },
    {
      title: `${t(translate.tractorMigration.migrate)}`,
      content: (
        <div style={{ textAlign: 'left' }}>
          <Logs
            migrationId={migrationId}
            handleDisable={(e: any) => {
              setIsDisabled(e);
              setServiceCheckFailed(e);
            }}
          />
        </div>
      ),
    },
    {
      title: `${t(translate.tractorMigration.restartBasestation)}`,
      content: (
        <RestartTractor
          tractroSerialNumber={selectedTractor?.serial_number}
          sourceOrgId={selectedTractor?.organization_id}
          next={() => next()}
        />
      ),
    },
  ];

  const migrateMethod = async () => {
    const { id } = JSON.parse(selectOrg);
    const payload = {
      target_organization_id: id,
      source_organization_id: selectedTractor.organization_id,
    };
    try {
      const response = await saveBasestationMigration(
        selectedTractor.serial_number,
        payload,
      );
      setMigrationId(response.id);
      setCurrent(current + 1);
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    }
  };

  useEffect(() => {
    if (userDetails && userDetails.organization && iotInitialized) {
      setTimeout(() => {
        getTractorHealthReports();
        reTestSpeed();
      }, 2000);
    }
  }, [userDetails, iotInitialized]);

  const getTractorHealthReports = async () => {
    try {
      setLoader(true);
      if (item.migration?.status !== 'Inprogress') {
        const targetOrg = JSON.parse(selectOrg);
        const records = await getBaseStationHealthReport(
          selectedTractor.serial_number,
          selectedTractor.organization?.id,
          targetOrg.id,
          selectedTractor.id,
        );
        setIsDisabled(records.connection_report.status_code !== 1000);
        setTractorReport(records);
      }
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const reTestSpeed = () => {
    setSpeed(undefined);
    checkBasestationNetworkSpeed(
      selectedTractor.serial_number,
      selectedTractor.id,
    );
  };

  return (
    <Modal
      className="tm-modal migrateContainer"
      title={selectedTractor?.name}
      centered
      confirmLoading
      visible={showModal}
      closable={false}
      onCancel={closeModel}
      footer={[
        <>
          {current < steps.length - 1 && (
            <Button
              type="primary"
              className="migrateModaltext"
              onClick={() => next()}
              disabled={isDisabled || serviceCheckFailed}
            >
              {current === 0
                ? `${t(translate.buttons.startmigration)}`
                : `${t(translate.buttons.next)}`}
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button
              type="primary"
              className="migrateModaltext"
              onClick={() => closeModel()}
            >
              {t(translate.buttons.done)}
            </Button>
          )}
          {current > 1 && (
            <Button
              className="btnCancel migrateModaltext"
              onClick={() => prev()}
            >
              {t(translate.buttons.previous)}
            </Button>
          )}
          {current !== 3 && (
            <Button
              className="btnCancel migrateModaltext"
              onClick={() => closeModel()}
            >
              {current === 0
                ? `${t(translate.buttons.close)}`
                : `${t(translate.buttons.minimize)}`}
            </Button>
          )}
        </>,
      ]}
    >
      <Steps current={current}>
        {steps.map((data) => (
          <Step key={data.title} title={data.title} />
        ))}
      </Steps>
      <div className="stepsContent">{steps[current].content}</div>

      <div className="edit-loader">
        {loader && (
          <div className="loader1">
            <Spin />
          </div>
        )}
      </div>
    </Modal>
  );
};
