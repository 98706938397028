import React, { useState } from 'react';
import { Tabs } from 'antd';
import '.././style.css';
import SupportTickets from './SupportTickets';
import Tickets from '../../../tickets/Tickets';
import SupportTicketsHotLine from '../supportTicketHotLine/SupportTicketHotLine';

const { TabPane } = Tabs;

const Index: React.FC = () => {
  const [tabid, setTabid] = useState<string>('Internal Tickets');

  return (
    <div className="rightSideContainer">
      <Tabs
        activeKey={tabid}
        centered
        id="org-tab-Organization"
        className="common_tabs implementTab"
        onChange={(key) => {
          setTabid(key);
        }}
      >
        <TabPane
          tab="Internal Tickets"
          key="Internal Tickets"
          className="attTableHeight mBottom0"
        >
          {tabid === 'Internal Tickets' && <Tickets />}
        </TabPane>
        <TabPane
          tab="Support Tickets"
          key="Support Tickets"
          className="attTableHeight mBottom0"
        >
          {tabid === 'Support Tickets' && <SupportTickets />}
        </TabPane>
        <TabPane
          tab="Support Ticket Escalation HotLine"
          key="Support Ticket Escalation HotLine"
          className="attTableHeight mBottom0"
        >
          {tabid === 'Support Ticket Escalation HotLine' && (
            <SupportTicketsHotLine />
          )}
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Index;
