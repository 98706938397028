/* eslint-disable no-console */
import React, { useContext, useEffect } from 'react';
import check_circle_select from '../../assets/images/check_circle_select.svg';
import dnd_equal_icon from '../../assets/images/dnd_equal_icon.svg';
import edit_round from '../../assets/images/edit_round.svg';
import heavy_feed_amount_icon from '../../assets/images/heavy.svg';
import light_feed_amount_icon from '../../assets/images/light.svg';
import medium_feed_amount_icon from '../../assets/images/medium.svg';

import { Checkbox, Tooltip, Switch } from 'antd';
import { getSelectedFeedLane, isFeedLaneChecked } from '../../constants/Common';
import {
  AutoDriveTabs,
  HEAVY_VALUE,
  LIGHT_VALUE,
  MEDIUM_VALUE,
  NewOperation,
  OldOperation,
  getFeedTypeImage,
} from '../../constants/constant';
import {
  DairyCommand,
  FeedLaneType,
  feedAmountValueType,
  operationType,
} from '../../lib/types';
import { RemoteDriveAppCtx } from '../remote_drive_new/RemoteDriveContext';
import {
  SET_ACTIVE_AUTODRIVE_TAB,
  SET_FEED_AMOUNT,
  SET_FEED_LANES,
  SET_SELECTED_FEED_LANES,
} from '../remote_drive_new/actions';

interface Props {
  setShowSaveOperation: (state: boolean) => void;
  setSaveConfirm: (state: boolean) => void;
  newOrEditOperation: operationType;
  setSelectedFeedLane: (feedLane: FeedLaneType) => void;
  setShowUpdateFeed: (state: boolean) => void;
  showMixed: boolean;
  setShowMixed: (state: boolean) => void;
  setSelectedCommand: (data: DairyCommand | null) => void;
  setOperationName: (name: string) => void;
  setGoToStart: (goToStart: boolean) => void;
  goToStart: boolean;
}

const FeedLaneSelection: React.FC<Props> = ({
  setShowSaveOperation,
  setSaveConfirm,
  newOrEditOperation,
  setSelectedFeedLane,
  setShowUpdateFeed,
  showMixed,
  setShowMixed,
  setSelectedCommand,
  setOperationName,
  setGoToStart,
  goToStart,
}: Props) => {
  const { RDReducer } = useContext(RemoteDriveAppCtx);
  const [state, dispatch] = RDReducer;
  const { feedLanes, selectedFeedLanes, feedAmount } = state;
  const handleSelectAll = () => {
    const list = feedLanes;
    list.map((listItem: FeedLaneType) => (listItem.feedAmount = feedAmount));
    dispatch({
      type: SET_SELECTED_FEED_LANES,
      payload: list,
    });
  };
  const handleUnSelectAll = () => {
    dispatch({
      type: SET_SELECTED_FEED_LANES,
      payload: [],
    });
  };
  const handleSelectionLanes = (feedLane: FeedLaneType) => {
    let list = selectedFeedLanes;
    if (isFeedLaneChecked(selectedFeedLanes, feedLane)) {
      list = selectedFeedLanes.filter(
        (feed: FeedLaneType) => feed.id !== feedLane.id,
      );
    } else {
      list = [...list, { ...feedLane, feedAmount }];
    }
    feedLaneOrder(feedLanes, list);

    // dispatch({
    //   type: SET_SELECTED_FEED_LANES,
    //   payload: list,
    // });
  };
  const handleFeedClick = (fAmount: feedAmountValueType) => {
    setShowMixed(false);
    dispatch({
      type: SET_FEED_AMOUNT,
      payload: fAmount,
    });
    const list = selectedFeedLanes;
    list.map((listItem: FeedLaneType) => (listItem.feedAmount = fAmount));
    dispatch({
      type: SET_SELECTED_FEED_LANES,
      payload: [...list],
    });
  };

  // drag and drop code start here
  const onDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    index: number,
  ) => {
    event.dataTransfer.setData('index', index.toString());
  };

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const onDrop = (event: React.DragEvent<HTMLDivElement>, newIndex: number) => {
    const oldIndex = parseInt(event.dataTransfer.getData('index'));
    const item = feedLanes[oldIndex];
    const newItems = feedLanes.filter(
      (feed: FeedLaneType, index: number) => index !== oldIndex,
    );
    newItems.splice(newIndex, 0, item);

    dispatch({
      type: SET_FEED_LANES,
      payload: [...newItems],
    });
  };
  const feedLaneOrder = (
    feedLanes: FeedLaneType[],
    sFeedLanes: FeedLaneType[],
  ) => {
    const orderMap = new Map();
    feedLanes.forEach((item: FeedLaneType, index: number) => {
      orderMap.set(item.id, index);
    });

    // Step 2: Sort the full array using the order from the partial array
    sFeedLanes.sort((a: FeedLaneType, b: FeedLaneType) => {
      // If both items are in the partial array, sort by their order in partialArray
      if (orderMap.has(a.id) && orderMap.has(b.id)) {
        return orderMap.get(a.id) - orderMap.get(b.id);
      }
      // If only a is in the partial array, a comes first
      if (orderMap.has(a.id)) {
        return -1;
      }
      // If only b is in the partial array, b comes first
      if (orderMap.has(b.id)) {
        return 1;
      }
      // If neither a nor b is in the partial array, keep their original order
      return 0;
    });
    dispatch({
      type: SET_SELECTED_FEED_LANES,
      payload: [...sFeedLanes],
    });
  };
  useEffect(() => {
    feedLaneOrder(feedLanes, selectedFeedLanes);
  }, [feedLanes]);
  return (
    <>
      <div className="select_lanes_section">
        <div className="select_lanes_head">
          <h2 className="lanes_head_text">Select Lanes and Feed Amount</h2>
          <p className="lanes_text">
            Set the distance from the fence by defining the amount of feed in
            all lanes
          </p>
        </div>
        <div className="lanes_tabs_section">
          <div
            className={`lanes_tab ${
              !showMixed && feedAmount === HEAVY_VALUE ? 'sel' : ''
            } `}
            onClick={() => handleFeedClick(HEAVY_VALUE)}
          >
            <img src={heavy_feed_amount_icon} className="tab_icon" />
            <p className="tab_name">Heavy</p>
          </div>
          <div
            className={`lanes_tab ${
              !showMixed && feedAmount === MEDIUM_VALUE ? 'sel' : ''
            } `}
            onClick={() => handleFeedClick(MEDIUM_VALUE)}
          >
            <img src={medium_feed_amount_icon} className="tab_icon" />
            <p className="tab_name">Medium</p>
          </div>
          <div
            className={`lanes_tab ${
              !showMixed && feedAmount === LIGHT_VALUE ? 'sel' : ''
            } `}
            onClick={() => handleFeedClick(LIGHT_VALUE)}
          >
            <img src={light_feed_amount_icon} className="tab_icon" />
            <p className="tab_name">Light</p>
          </div>
        </div>
        <div className="mixed-txt">{showMixed && 'Mixed'}</div>
        <div className="lanes_helptext_sec">
          <h2 className="lanes_order_helptext">
            Arrange lanes in order of <br /> operation
          </h2>
          {feedLanes.length != selectedFeedLanes.length && (
            <span className="edit_list" onClick={handleSelectAll}>
              <img src={check_circle_select} />
              Select All
            </span>
          )}
          {feedLanes.length === selectedFeedLanes.length && (
            <span className="edit_list" onClick={handleUnSelectAll}>
              <img src={check_circle_select} />
              Deselect All
            </span>
          )}
        </div>
        <div className="lanes_list-sec">
          {feedLanes.map((feedLane: FeedLaneType, index: number) => (
            <>
              <div
                className="lanes_list_item on-hover-hand-change"
                draggable
                onDragStart={(e) => onDragStart(e, index)}
                onDragOver={onDragOver}
                onDrop={(e) => onDrop(e, index)}
                key={feedLane.id}
              >
                <div className="list_number">
                  <span
                    className={`${
                      !isFeedLaneChecked(selectedFeedLanes, feedLane)
                        ? 'v_hidden'
                        : ''
                    }`}
                  >
                    {selectedFeedLanes.findIndex(
                      (selectedFeedLane: FeedLaneType) =>
                        feedLane.id == selectedFeedLane.id,
                    ) + 1}
                  </span>
                </div>
                <div
                  className={`lanes_list_item_card ${
                    isFeedLaneChecked(selectedFeedLanes, feedLane)
                      ? 'selBdr'
                      : ''
                  }`}
                >
                  <div
                    className={`equal ${
                      !isFeedLaneChecked(selectedFeedLanes, feedLane)
                        ? 'v_hidden'
                        : ''
                    }`}
                  >
                    <img src={dnd_equal_icon} />
                  </div>
                  <div className="lanes_list_op_name_sec">
                    <div
                      className={`lanes_list_op_name ${
                        feedLane?.name?.length > 18 ? 'marquee' : ''
                      } `}
                    >
                      <span>
                        <Tooltip title={feedLane?.name}>
                          {feedLane?.name}
                        </Tooltip>
                      </span>
                    </div>
                    <div className="lanes_list_op_time">
                      Last pass 9:30 am today
                    </div>
                  </div>
                  <div
                    className={`lanes_list_op_icon cursor_pointer ${
                      !isFeedLaneChecked(selectedFeedLanes, feedLane)
                        ? 'v_hidden'
                        : ''
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedFeedLane(
                        getSelectedFeedLane(selectedFeedLanes, feedLane),
                      );
                      setShowUpdateFeed(true);
                    }}
                  >
                    <span className="mr2">
                      <img
                        src={getFeedTypeImage(
                          getSelectedFeedLane(selectedFeedLanes, feedLane)
                            ?.feedAmount as number,
                        )}
                      />
                    </span>
                    <span>
                      <img src={edit_round} className="cursor_pointer" />
                    </span>
                  </div>
                  <div
                    className="op_checkbox_sec"
                    onClick={() => handleSelectionLanes(feedLane)}
                  >
                    <Checkbox
                      defaultChecked={false}
                      checked={isFeedLaneChecked(selectedFeedLanes, feedLane)}
                      className="op_checkbox"
                    />
                  </div>
                </div>
              </div>
            </>
          ))}
          {feedLanes.length === 0 && 'No Feed lanes Available'}
        </div>
        <div className="returnToStartSec">
          <div className="returnToTxt">
            <h3>Return to Start</h3>
            <p>
              This setting will return the tractor to it’s starting position{' '}
            </p>
          </div>
          <div className="returnToToggle">
            <Switch
              checkedChildren="ON"
              unCheckedChildren="OFF"
              defaultChecked={goToStart}
              checked={goToStart}
              onChange={(e) => {
                setGoToStart(e);
              }}
              className="returntoStart"
            />
          </div>
        </div>
        <div className="d_button_sec">
          <button
            className="button_default d_Button3"
            onClick={() => {
              setOperationName('');
              setSelectedCommand(null);
              dispatch({
                type: SET_ACTIVE_AUTODRIVE_TAB,
                payload: AutoDriveTabs.Parameter,
              });
            }}
          >
            Previous
          </button>
          <button
            className="button_green d_Button3"
            onClick={() => {
              newOrEditOperation === NewOperation && setShowSaveOperation(true);

              newOrEditOperation === OldOperation && setSaveConfirm(true);
            }}
            disabled={selectedFeedLanes.length === 0 ? true : false}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default FeedLaneSelection;
