import React, { useContext, useEffect, useState } from 'react';
import { Button, Layout, notification, Radio, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import closeGreen from '../../../../assets/images/right_widget_close_icon.svg';
import translate from '../../../../locale/en_translate.json';
import CustomTextArea from '../../../common/CustomTextArea';
import CustomSelect from '../../../common/CustomSelect';
import {
  createServiceTicket,
  getEquipments,
  getOrgsList,
  getTags,
} from '../../API';
import { ApplicationContext } from '../../../../context/AppContext';
import CustomSeverityLevel from '../../../common/CustomSeverityLevel';
import loading_logo from '../../../../assets/images/loading_logo.gif';

interface Props {
  toggleWidget: boolean;
  onClose: (state?: boolean) => void;
}

const { CheckableTag } = Tag;

const AddTicket: React.FC<Props> = ({ toggleWidget, onClose }) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [organizationsList, setOrganizationsList] = useState<any[]>([]);
  const [equipmentList, setEquipmentList] = useState<any[]>([]);
  const [selectedOrgId, setSelectedOrgId] = useState<number | string>();
  const [equipmentId, setEquipmentId] = useState<number>();
  const [equipmentType, setEquipmentType] = useState<string>('');
  const [equipmentName, setEquipmentName] = useState<string>('');
  const [severityLevel, setSeverityLevel] = useState<string>('');
  const [tagsList, setTagsList] = useState<any[]>([]);
  const [selectedTags, setSelectedTags] = useState<any[]>([]);
  const [toggleSwitch, setToggleSwitch] = useState<boolean>(true);
  const [descriptionCount, setDescriptionCount] = useState<number>(0);
  const [description, setDescription] = useState('');
  const [loader, setLoader] = useState<boolean>(false);
  const [validations, setValidations] = useState<any>({
    organization: true,
    equipment: true,
    level: true,
    description: true,
    tags: true,
    operational: true,
  });
  const [disable, setDisable] = useState<boolean>(true);
  useEffect(() => {
    if (userDetails && userDetails.organization && toggleWidget === true) {
      getAllOrganizationsList();
    }
  }, [userDetails]);

  useEffect(() => {
    if (
      description &&
      description.trim().length > 2 &&
      severityLevel &&
      selectedTags &&
      selectedTags.length > 0
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [description, severityLevel, selectedTags]);

  useEffect(() => {
    if (description !== undefined) setDescriptionCount(description.length);
  }, [description]);

  useEffect(() => {
    const isValid = Object.values(validations).every((item) => item);
    if (!disable && isValid && Object.keys(validations).length)
      saveServiceTicket();
  }, [validations]);

  const saveTicketData = () => {
    setValidations({
      ...validations,
      equipment: equipmentType ? true : false,
      description: description.length ? true : false,
      level: severityLevel ? true : false,
      tags: selectedTags.length ? true : false,
    });
  };

  const saveServiceTicket = async () => {
    const selTags: any[] = [];
    tagsList?.map((tag: any) => {
      if (tag.selected) {
        selTags.push(tag.name);
      }
    });
    const payload = {
      ticket_description: description,
      ticket_level: severityLevel,
      equipment_id: equipmentId,
      equipment_type: equipmentType,
      tags: selTags,
      customer_org_id: selectedOrgId,
      assignee_id: null,
      ticket_type: 'SUPPORT_TICKET',
      tractor_operational: toggleSwitch === true,
    };
    try {
      setLoader(true);
      const response: { msg: string } = await createServiceTicket(payload);
      onClose(true);
      notification.success({ message: response.msg });
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const getAllOrganizationsList = async () => {
    try {
      setLoader(true);
      const records = await getOrgsList();
      const arr = records;
      if (arr.length) {
        const index = arr.findIndex(
          (item: any) => item.id === userDetails.organization_id,
        );
        if (index !== -1) {
          const [rarr] = arr.splice(index, 1);
          arr.push(rarr);
        }
      }
      setOrganizationsList(arr);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const handleOrg = async (id: any) => {
    setSelectedOrgId(id);
    getEquipmentList(id);
    getTagsList(id);
  };

  const getTagsList = async (id: number) => {
    try {
      setLoader(true);
      const records = await getTags(userDetails.organization_id, id);
      setTagsList(records);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const getEquipmentList = async (id: number) => {
    try {
      setLoader(true);
      const records = await getEquipments(userDetails.organization_id, id);
      setEquipmentList(records);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const handleChangeEquipment = (value: any) => {
    const data = JSON.parse(value);
    const { equipment_id, equipment_type, name } = data;
    setEquipmentId(equipment_id);
    setEquipmentType(equipment_type);
    setEquipmentName(name);
  };

  const selectSeverity = (e: any) => {
    setSeverityLevel(e);
  };

  const handleChange = (tag: any, checked: any) => {
    tag.selected = checked;
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t: any) => t !== tag);
    setSelectedTags(nextSelectedTags);
  };

  //old one

  return (
    <Layout>
      <div className={'rightSideWidegt ' + (toggleWidget ? 'expand' : 'hide')}>
        <div className="widgetCloseBtn">
          <img src={closeGreen} alt="close icon" onClick={() => onClose()} />
        </div>

        <div className="profileEdit widget_card">
          <h3 className="headline3 rowGapBtm3" style={{ textAlign: 'center' }}>
            {t(translate.tickets.createTicketTitle)}
          </h3>
          <CustomSelect
            className="mb16"
            label="Organization"
            value={selectedOrgId}
            setValue={handleOrg}
            options={organizationsList}
            optionKey="id"
            optionDisplay="name"
            required={true}
            validations={validations.organization}
          />
          <CustomSelect
            className="mb16"
            label="Equipment"
            value={equipmentName}
            setValue={handleChangeEquipment}
            options={equipmentList}
            optionKey="equipment_id"
            optionDisplay="name"
            required={true}
            validations={validations.equipment}
            jsonFormat
          />
          <div className="mb20">
            <CustomSeverityLevel
              label="Severity Level"
              cssClass="min_width"
              value={severityLevel}
              onSelect={selectSeverity}
              required={true}
              testId="severityLevelDropdown-TicketsCreateEdit"
            />
          </div>
          <div className="mb20">
            <CustomTextArea
              label="Description"
              dataTestid="createTicketDescription-TicketsCreateEdit"
              value={description}
              setValue={setDescription}
              required={true}
              description={description}
              descriptionCount={descriptionCount}
            />
          </div>
          <div className="proRowType tagsSec rowGapBtm3">
            <h4 className="headline4 mb15">
              {t(translate.fleetticketsHistory.tags)}{' '}
              <span className="float-span">*</span>
            </h4>

            <div className="tagsBlk" data-testid="selectTag-TicketCreateEdit">
              {tagsList?.map((data: any) => {
                return (
                  <CheckableTag
                    className="checktag body3 fMed"
                    key={data.id}
                    checked={selectedTags.indexOf(data) > -1}
                    onChange={(checked: any) => handleChange(data, checked)}
                    data-testid={`${data.name}-Tag-TicketCreateEdit`}
                  >
                    {data.name}
                  </CheckableTag>
                );
              })}
            </div>
          </div>
          <div className="proRowType tagsSec rowGapBtm3">
            <h4 className="headline4 mb15">
              {t(translate.tickets.tractorOperational)}{' '}
              <span className="float-span">*</span>
            </h4>
            <div className="tagsBlk radioBtn">
              <Radio.Group
                onChange={(e) => setToggleSwitch(e.target.value)}
                defaultValue={toggleSwitch}
                value={toggleSwitch}
              >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </div>
          </div>
          <div>
            <div className="edit-loader">
              {loader && (
                <img
                  style={{ width: '36px', height: '36px' }}
                  src={loading_logo}
                />
              )}
            </div>
            <div className="create_ticket_btn_sec">
              <Button onClick={saveTicketData} disabled={disable}>
                {t(translate.buttons.createTicket)}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddTicket;
