/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
import React from 'react';
import approveBtn from '../../../../../assets/images/approveBtn.svg';
import fail_cross from '../../../../../assets/images/fail_cross.svg';
import { NetworkSpeed } from '../../../../../constants/types';

interface Props {
  selectedTractor: any;
  tractorReport: TractorReport;
  item: any;
  reTestSpeed: () => void;
  speed: NetworkSpeed;
  showServiceCheckFailure: boolean;
}

export interface TractorReport {
  connection_report: {
    connection: string;
    msg: string;
    status_code: number;
    drive_action_details: any;
  };
  mt_fleet_software_health_report: {
    msg: string;
    status_code: number;
    build_to_be_published: {
      software: [];
    };
  };
  ovpn_access_group: {
    source_org: string;
    target_org: string;
  };
}

const Overview: React.FC<Props> = ({
  selectedTractor,
  tractorReport,
  item,
  reTestSpeed,
  speed,
  showServiceCheckFailure,
}) => {
  // eslint-disable-next-line no-unused-vars
  const getVersionCheckText = (data: TractorReport) => {
    if (data.mt_fleet_software_health_report?.status_code === 2001) {
      return (
        <span style={{ color: '#788143' }}>
          Version Check Passed{' '}
          <img
            height="16px"
            src={approveBtn}
            alt=""
            style={{ marginTop: '-6px' }}
          />
        </span>
      );
    }

    return (
      <>
        <span style={{ color: 'red' }}>
          Version Check Failed, following Software Versions needs to be
          published in target Organization.{' '}
          <img
            height="18px"
            src={fail_cross}
            alt=""
            style={{ marginTop: '-6px' }}
          />
        </span>
        <div style={{ marginLeft: '20px' }}>
          Software:{' '}
          <ol>
            {data.mt_fleet_software_health_report?.build_to_be_published
              .software &&
              data.mt_fleet_software_health_report?.build_to_be_published.software.map(
                (version: string) => <ul>{version}</ul>,
              )}
          </ol>
        </div>
      </>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const getConnectionText = (tractorReport: TractorReport) => {
    // eslint-disable-next-line no-console
    console.log('getConnectionText   ', tractorReport);

    if (tractorReport) {
      if (
        tractorReport &&
        tractorReport.connection_report?.status_code === 1000
      ) {
        return (
          <span style={{ color: '#788143' }}>
            {' '}
            <>Online</>{' '}
            <img
              height="16px"
              src={tractorReport?.connection_report?.msg && approveBtn}
              alt=""
              style={{ marginTop: '-6px' }}
            />
          </span>
        );
      }

      return (
        <span style={{ color: 'red' }}>
          {' '}
          <>Offline.</>{' '}
          <img
            height="18px"
            src={tractorReport?.connection_report?.msg && fail_cross}
            alt=""
            style={{ marginTop: '-6px' }}
          />
        </span>
      );
    }
    return <></>;
  };

  return (
    <>
      {item.migration?.status !== 'Inprogress' && (
        <ul>
          <li data-testid={`${selectedTractor?.name}-Overview`}>
            BaseStation Name: {selectedTractor && selectedTractor?.name}
          </li>
          <li data-testid={`${selectedTractor?.organization?.name}-Overview`}>
            Source Org: {selectedTractor && selectedTractor?.organization?.name}
          </li>
          <li>
            Target Org:{' '}
            {selectedTractor &&
              selectedTractor.selectOrg &&
              JSON.parse(selectedTractor.selectOrg).name}
          </li>
          <li data-testid="baseStationStatus-Overview">
            Basestation Status:
            {getConnectionText(tractorReport)}
          </li>
          {/* <li>
            Version Check:  &nbsp;
            {tractorReport ? getVersionCheckText(tractorReport) : <Spin />}
          </li> */}
          {showServiceCheckFailure && (
            <>
              <li>
                Service Check: &nbsp;{' '}
                <span style={{ color: 'red' }}>
                  Failed.{' '}
                  <img
                    height="18px"
                    src={fail_cross}
                    alt=""
                    style={{ marginTop: '-6px' }}
                  />
                </span>
              </li>
              <span
                style={{
                  color: 'black',
                  fontWeight: 'bold',
                  paddingLeft: '120px',
                }}
              >
                Reboot the Basestation and Try again{' '}
              </span>
            </>
          )}
          {/* {speed
          && (
          <li>
            <CloudUploadOutlined style={{ color: 'green' }} />
            {' '}
            {speed?.network_health_report?.upload_speed ? Number(speed?.network_health_report?.upload_speed).toFixed(1) : 0}
            {' '}
            Mbps
            {' '}
                   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <CloudDownloadOutlined style={{ color: 'red' }} />
            {' '}
            {speed?.network_health_report?.download_speed ? Number(speed?.network_health_report?.download_speed).toFixed(1) : 0}
            {' '}
            Mbps
          </li>
          )} */}
        </ul>
      )}
      {/* <Button type="primary" onClick={() => reTestSpeed()} loading={speed === undefined}>
        Check Network Speed
      </Button> */}
    </>
  );
};

export default Overview;
