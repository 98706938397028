import React from 'react';
import { useTranslation } from 'react-i18next';
import translate from '../../../../locale/en_translate.json';
import { getIndicatorImages } from '../../../basestation/Common';
import ViewItem from '../../common/ViewItem';
import closeGreen from '../../../../assets/images/right_widget_close_icon.svg';

interface Props {
  showModal: boolean;
  handleCancel: () => void;
  indicatorDetails: any;
}
export const FleetIndicatorDetails: React.FC<Props> = ({
  showModal,
  handleCancel,
  indicatorDetails,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={'rightSideWidegt ' + (showModal ? 'expand' : 'hide')}>
        <div className="widgetCloseBtn flexBtn">
          <img
            src={closeGreen}
            alt="close icon"
            onClick={() => handleCancel()}
          />
        </div>
        <div className="profileEdit widget_card">
          <div className="proRow proMargin">
            <h4 className="posRel  headline3">
              <span>{translate.fleetIndicator.fleetIndicatorDetails}</span>
            </h4>
          </div>
          <ViewItem
            title={t(translate.baseStation.tractorName)}
            content={indicatorDetails?.name}
          />
          <ViewItem
            title={t(translate.taskmaster.starttime)}
            content={indicatorDetails?.start_time}
          />
          <ViewItem
            title={t(translate.tractorDetails.indicatorName)}
            content={indicatorDetails?.indicator_name}
          />
          <ViewItem
            title={t(translate.myTask.description)}
            content={
              <p className="descText">
                {' '}
                <img
                  width="25px"
                  height="25px"
                  className="fleetMargin"
                  src={getIndicatorImages(
                    indicatorDetails.indicator_name,
                    indicatorDetails.indicator_level,
                    true,
                  )}
                />
                {indicatorDetails?.description}
              </p>
            }
          />

          <ViewItem
            title={t(translate.myTask.counterMeasure)}
            content={indicatorDetails?.counterMeasure}
          />

          <ViewItem
            title={t(translate.indicatorDetails.errorCode)}
            content={indicatorDetails?.error_code}
          />

          <ViewItem
            title={t(translate.indicatorDetails.resolvedTime)}
            content={indicatorDetails?.resolved_date_time}
          />

          <ViewItem
            title={t(translate.MyJumpAndGo.duration)}
            content={indicatorDetails?.Duration}
          />

          <ViewItem
            title={t(translate.MyJumpAndGo.issue)}
            content={indicatorDetails?.Issue}
          />
        </div>
      </div>
    </>
  );
};

export default FleetIndicatorDetails;
FleetIndicatorDetails.displayName = 'fleetIndicatorDetails';
