import React, { useState, useEffect, useContext } from 'react';
import { Button, notification, Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import translate from '../../../../locale/en_translate.json';
import {
  editTeamAssignIds,
  getGroupMembers,
  fetchGroupsLookup,
} from '../../API';
import { ApplicationContext } from '../../../../context/AppContext';
import { groupList } from '../../../../constants/constant';
import closeGreen from '../../../../assets/images/right_widget_close_icon.svg';
import CustomSelect from '../../../common/CustomSelect';
import CustomSeverityLevel from '../../../common/CustomSeverityLevel';
import loading_logo from '../../../../assets/images/loading_logo.gif';
import CustomMultiSelectMode from '../../common/CustomMultiSelectMode';

interface Props {
  showModal: boolean;
  closeModal: (state?: boolean) => void;
  selectedTicketId: number;
  ticketDetails: any;
  isNotificationTicket?: boolean;
}

const EditTicket: React.FC<Props> = ({
  showModal,
  closeModal,
  selectedTicketId,
  ticketDetails,
  isNotificationTicket,
}: Props) => {
  const { t } = useTranslation();
  const { dealerDetails } = useContext(ApplicationContext);
  const [loader, setLoader] = useState<boolean>(false);
  const [validations, setValidations] = useState<any>({
    group: true,
    user: true,
    priority: true,
  });
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [userId, setUserId] = useState<any>();
  const [groupId, setGroupId] = useState<any>(ticketDetails?.group_id?.id);
  const [severityLevel, setSeverityLevel] = useState<any>(
    ticketDetails?.ticket_level,
  );
  const [usersList, setUsersList] = useState<any[]>([]);
  const [groupsList, setGroupsList] = useState<any[]>([]);
  const [ticketPriorityId, setTicketPriorityId] = useState<any>(
    ticketDetails?.ticket_priority,
  );
  const [nextUp, setNextUp] = useState<string[]>(
    ticketDetails?.ticket_next_up_information === null
      ? []
      : ticketDetails?.ticket_next_up_information?.split(','),
  );

  const ticketPriority = [
    { name: 'P0', value: 0 },
    { name: 'P1', value: 1 },
    { name: 'P2', value: 2 },
    { name: 'P3', value: 3 },
  ];

  useEffect(() => {
    if (isNotificationTicket) {
      if (groupId !== '' && userId !== '') {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    } else if (
      groupId !== '' &&
      userId !== '' &&
      nextUp?.length > 0 &&
      ticketPriorityId !== ('' || null)
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [groupId, userId, nextUp, ticketPriorityId]);

  useEffect(() => {
    const isValid = Object.values(validations).every((item) => item);
    if (!isDisabled && isValid && Object.keys(validations).length)
      editTicketIds();
  }, [validations]);

  useEffect(() => {
    if (dealerDetails && dealerDetails.organization) {
      getAllGroupList();
    }
  }, [dealerDetails]);

  useEffect(() => {
    if (dealerDetails && dealerDetails.organization && groupId) {
      getAllUsersList();
    }
  }, [dealerDetails, groupId]);

  const getAllGroupList = async () => {
    try {
      setLoader(true);
      const records = await fetchGroupsLookup(dealerDetails.organization_id);

      setGroupsList(records);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const getAllUsersList = async () => {
    try {
      setLoader(true);
      const records = await getGroupMembers(
        dealerDetails.organization_id,
        groupId,
      );
      const usersData = records.map((ele: any) => {
        ele.name = `${ele?.first_name} ${ele?.last_name}`;
        return ele;
      });
      setUsersList(usersData);
      const isPresent = records.some(
        (item: any) => item.id === ticketDetails?.assignee_id,
      );
      if (isPresent) {
        setUserId(ticketDetails?.assignee_id);
      }
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const editTicketData = () => {
    setValidations({
      ...validations,
      group: groupId ? true : false,
      user: userId ? true : false,
      priority: ticketPriorityId || ticketPriorityId === 0 ? true : false,
    });
  };

  const editTicketIds = async () => {
    try {
      setLoader(true);
      const payload = {
        assignee_id: userId,
        group_id: groupId,
        ticket_level: severityLevel,
        ticket_priority: ticketPriorityId,
        ticket_next_up_information: nextUp.join(','),
      };
      const response = await editTeamAssignIds(selectedTicketId, payload);
      notification.success({
        message: response.msg,
      });

      closeModal(true);
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const handleGroup = (e: any) => {
    setUserId('');
    setGroupId(e);
  };
  const handleUsers = (e: any) => {
    setUserId(e);
  };
  const selectSeverity = (e: any) => {
    setSeverityLevel(e);
  };
  const handlePriority = (e: any) => {
    setTicketPriorityId(e);
  };

  const handleGroupList = (e: any) => {
    setNextUp(e);
  };
  return (
    <Layout>
      <div className={'rightSideWidegt ' + (showModal ? 'expand' : 'hide')}>
        <div className="widgetCloseBtn">
          <img src={closeGreen} alt="close icon" onClick={() => closeModal()} />
        </div>

        <div className="profileEdit widget_card">
          <h3 className="headline3 rowGapBtm3" style={{ textAlign: 'center' }}>
            {t(translate.tickets.editTicketTitle)}
          </h3>
          <CustomSelect
            className="mb16"
            label="Select Group"
            value={groupsList && groupsList.length > 0 ? groupId : ''}
            setValue={handleGroup}
            options={groupsList}
            optionKey="id"
            optionDisplay="name"
            required={true}
            validations={validations.group}
          />
          <CustomSelect
            className="mb16"
            label="Select User"
            value={usersList && usersList.length > 0 ? userId : ''}
            setValue={handleUsers}
            options={usersList}
            optionKey="id"
            optionDisplay="name"
            required={true}
            validations={validations.user}
          />
          {!isNotificationTicket && (
            <>
              <CustomSeverityLevel
                label="Severity Level"
                cssClass="min_width mb16"
                value={severityLevel}
                onSelect={selectSeverity}
                required={true}
              />
              <CustomSelect
                className="mb16"
                label="Ticket Priority"
                value={
                  ticketPriority && ticketPriority.length > 0
                    ? ticketPriorityId
                    : ''
                }
                setValue={handlePriority}
                options={ticketPriority}
                optionKey="value"
                optionDisplay="name"
                required={true}
                validations={validations.priority}
              />
              <CustomMultiSelectMode
                cssClass="mb16"
                label="Ticket Next Up"
                value={nextUp}
                setValue={handleGroupList}
                options={groupList}
                optionKey="value"
                optionDisplay="name"
                required={true}
              />
            </>
          )}

          <div>
            <div className="edit-loader">
              {loader && (
                <img
                  style={{ width: '36px', height: '36px' }}
                  src={loading_logo}
                />
              )}
            </div>
            <div className="create_ticket_btn_sec">
              <Button onClick={editTicketData} disabled={isDisabled}>
                {t(translate.buttons.save)}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EditTicket;
