import React from 'react';
import Search from 'antd/lib/transfer/search';
import useAnalyticStore from '../../operationalAnalytics/store/operationAnalytics';
import useOperationalAnalyticDetailsStore from '../../operationalAnalytics/store/tractorAnalyticDetails';

interface HrsMinsProps {
  hours: string | number;
  minutes: string | number;
}
export const HrsMins: React.FC<HrsMinsProps> = ({ hours, minutes }) => {
  return (
    <>
      {Number(hours)
        ? hours + 'h' + ' ' + minutes + 'm'
        : (Number(minutes) ? minutes : '0') + 'm'}
    </>
  );
};

interface WeekendsFilterProps {
  includeWeekends: boolean;
  toggleWeekends: (state: boolean) => void;
}

export const WeekendsFilter: React.FC<WeekendsFilterProps> = ({
  includeWeekends,
  toggleWeekends,
}) => {
  const { setView } = useAnalyticStore();
  const { selectTractor } = useOperationalAnalyticDetailsStore();

  return (
    <div className="frame-wrapper2">
      <div className="top-settings">
        {/* <div className="stroke-styler"></div> */}
        <div
          className="op-search-bar"
          onClick={() => {
            setView('search');
            selectTractor(null);
          }}
        >
          <Search placeholder="Search Operations" />
        </div>
      </div>
    </div>
  );
};
