import moment from 'moment';
import FlagGrayIcon from '../../../assets/images/flag_gray.svg';
import FlagOrangeIcon from '../../../assets/images/flag_orange.svg';
import FlagRedIcon from '../../../assets/images/flag_red.svg';
import {
  getOperationNewStatus,
  tractorCameras,
  tractorConstantsNew,
} from '../../../constants/Common';
import { mqttConstants } from '../../../util/MqttConstants';
import { UserDetails } from '../../../constants/types';

export const getSeverityIcon = (level: number, orderNumber: number) => {
  if (level >= 3) {
    return FlagRedIcon;
  }
  if (orderNumber <= level) {
    return FlagOrangeIcon;
  }
  return FlagGrayIcon;
};

export const timeDuration = (
  current: any,
  previous: any,
  onlyHoursFormat = false,
) => {
  const x = moment(current);
  const y = moment(previous);
  const duration = moment.duration(x.diff(y));
  const hours = Math.round(duration.as('hours'));
  const milliSeconds = Math.round(duration.as('milliseconds'));
  const diffDays = Math.floor(milliSeconds / (1000 * 60 * 60 * 24));
  const diffHours = Math.floor(
    (milliSeconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const minutes = duration.minutes();

  const formatHours = (hours: number, minutes: number) => {
    if (hours > 0 && hours < 2) {
      return `${hours} hr ${minutes} mins`;
    }
    if (hours === 0) {
      return minutes !== 0 ? `${minutes} mins` : '';
    }
    return `${hours} hrs ${minutes} mins`;
  };

  const formatDiffHours = (diffHours: number) => {
    if (diffHours !== 0 && diffHours > 2) {
      return `${diffHours} hrs`;
    }
    if (diffHours < 2 && diffHours !== 0) {
      return `${diffHours} hr`;
    }
    return '';
  };

  const formatTimeDuration = (
    hours: number,
    diffDays: number,
    diffHours: string,
    newHour: string,
  ) => {
    if (hours < 24) {
      return newHour;
    }
    if (hours >= 24 && hours <= 48) {
      return `${diffDays} day ${diffHours}`;
    }
    return `${diffDays} days ${diffHours}`;
  };

  const newHour = formatHours(hours, minutes);
  const newHours = formatDiffHours(diffHours);

  return !onlyHoursFormat
    ? formatTimeDuration(hours, diffDays, newHours, newHour)
    : hours;
};

export const getColorBasedOnProgressHours = (obj: any) => {
  const hours =
    obj.status === 'completed'
      ? Number(
          timeDuration(new Date(obj.endTime), new Date(obj.startTime), true),
        )
      : Number(timeDuration(new Date(), new Date(obj?.startTime), true));

  const data =
    hours > 0 && hours < 24
      ? ''
      : hours >= 24 && hours <= 48
      ? 'orange'
      : hours > 48
      ? 'red'
      : '';
  return data;
};

export const getColorBasedOnInteraction = (obj: any) => {
  const hours = obj.ticket_last_modified_date_time
    ? Number(
        timeDuration(
          new Date(),
          new Date(obj.ticket_last_modified_date_time),
          true,
        ),
      )
    : 0;
  const data =
    hours > 0 && hours < 24
      ? ''
      : hours >= 24 && hours <= 48
      ? 'orange'
      : hours > 48
      ? 'red'
      : '';
  return data;
};

export const severityLevel = [
  { name: 'Low', value: 1 },
  { name: `Medium`, value: 2 },
  { name: `High`, value: 3 },
];

export const resolveFilterData = [
  { name: 'Open', value: 'open' },
  { name: 'In Progress', value: 'inprogress' },
  { name: 'Completed', value: 'completed' },
];

export const ticketPriority = [
  { name: 'P0', value: 0 },
  { name: 'P1', value: 1 },
  { name: 'P2', value: 2 },
  { name: 'P3', value: 3 },
];

export const stringColumnSort = (a: any, b: any, index: string) => {
  const valueA = a[index] || '';
  const valueB = b[index] || '';
  if (valueA === '-' && valueB !== '-') {
    return 1;
  }
  if (valueA !== '-' && valueB === '-') {
    return -1;
  }
  if (valueA === '' && valueB !== '') {
    return 1;
  }
  if (valueA !== '' && valueB === '') {
    return -1;
  }

  return valueA.localeCompare(valueB);
};
export const getCameraViewStatus = (tractor: any, status: number): string => {
  if (tractor && tractor?.system_model_data) {
    const data = tractor?.system_model_data
      .split(',')
      .map((item: any) => Number(item));
    if (data && data.length) {
      return data[status] ? 'Available' : 'Not Available';
    }
  }
  return 'Not Available';
};
export const getKeyValue = (
  tractor: any,
  status: number,
  isNumberType = true,
): string => {
  if (tractor && tractor?.system_model_data) {
    const data = tractor?.system_model_data
      .split(',')
      .map((item: any) => (isNumberType ? Number(item) : item));
    if (data && data.length) {
      return data[status];
    }
  }
  return '-';
};

export const getValueByIndex = (details: any, index: number) => {
  const arr = details?.split(',');
  if (arr?.length > index) {
    return arr[index] < 0 ? 'NA' : `${arr[index]} %`;
  }
};

export const getCameraViewAllow = (tractor: any, camera: string): boolean => {
  // Ignore Work Bench Tractors
  if (
    tractor?.tractor_serial_number &&
    tractor?.tractor_serial_number.length > 0 &&
    tractor?.tractor_serial_number.includes('MTBENCH')
  ) {
    return true;
  }
  // if offline dont allow to click
  const status2 =
    tractor?.connection?.status === 'online'
      ? getOperationNewStatus(tractor)
      : tractorConstantsNew.off.key;
  if (status2 === tractorConstantsNew.off.key) {
    return false;
  }
  if (tractor && tractor?.system_model_data) {
    const data = tractor?.system_model_data
      .split(',')
      .map((item: any) => Number(item));
    if (data && data.length) {
      if (camera === tractorCameras.FRONT) {
        return !!data[mqttConstants.FRONT_CAMERA_STATUS];
      }

      if (camera === tractorCameras.FRONT_RIGHT) {
        return !!data[mqttConstants.RIGHT_CAMERA_STATUS];
      }

      if (camera === tractorCameras.FRONT_LEFT)
        return !!data[mqttConstants.LEFT_CAMERA_STATUS];
      if (camera === tractorCameras.REAR)
        return !!data[mqttConstants.REAR_CAMERA_STATUS];
      if (camera === tractorCameras.PTO)
        return !!data[mqttConstants.PTO_CAMERA_STATUS];
      if (camera === tractorCameras.REAR_LEFT)
        return !!data[mqttConstants.REAR_LEFT_CAMERA_STATUS];
      if (camera === tractorCameras.REAR_RIGHT)
        return !!data[mqttConstants.REAR_RIGHT_CAMERA_STATUS];
      if (camera === tractorCameras.DRIVER)
        return !!data[mqttConstants.DRIVER_CAMERA_STATUS];
      return false;
    }
  }
  return false;
};

export const hourInfo1 = [
  { value: 1, text: '1' },
  { value: 2, text: '2' },
  { value: 3, text: '3' },
  { value: 4, text: '4' },
  { value: 5, text: '5' },
  { value: 6, text: '6' },
  { value: 7, text: '7' },
  { value: 8, text: '8' },
  { value: 9, text: '9' },
  { value: 10, text: '10' },
  { value: 11, text: '11' },
  { value: 12, text: '12' },
  { value: 13, text: '13' },
  { value: 14, text: '14' },
  { value: 15, text: '15' },
  { value: 16, text: '16' },
  { value: 17, text: '17' },
  { value: 18, text: '18' },
  { value: 19, text: '19' },
  { value: 20, text: '20' },
  { value: 21, text: '21' },
  { value: 22, text: '22' },
  { value: 23, text: '23' },
  { value: 24, text: '24' },
];

export const dealersData = [
  { title: 'Monarch Customers', value: 'monarch_customers' },
  { title: 'Monarch Dealers', value: 'monarch_dealers' },
  { title: 'Monarch OEMS', value: 'monarch_oems' },
  { title: 'Monarch Internal', value: 'monarch_internal' },
];

export const removeSpecialChars = (val: string) =>
  typeof val === 'string'
    ? val?.replace(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/g, '')
    : val;

export const checkCanresolveTickets = (userDetails: any) => {
  let canResolveTicket = false;
  if (userDetails && userDetails.group_list) {
    userDetails.group_list.forEach((group: any) => {
      if (group.is_resolve_ticket) canResolveTicket = true;
    });
  }
  return canResolveTicket;
};
export const dateColumnSort = (a: any, b: any, index: string) => {
  const dateA = a[index] || '';
  const dateB = b[index] || '';
  if (dateA === '-' && dateB !== '-') {
    return 1;
  }
  if (dateA !== '-' && dateB === '-') {
    return -1;
  }
  if (dateA === '' && dateB !== '') {
    return 1;
  }
  if (dateA !== '' && dateB === '') {
    return -1;
  }

  const timestampA = new Date(dateA).getTime();
  const timestampB = new Date(dateB).getTime();

  return timestampA - timestampB;
};
