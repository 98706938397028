/* eslint-disable react/jsx-key */
import { Button, Modal, Space, notification } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CloudDownloadOutlined, CloudUploadOutlined } from '@ant-design/icons';
import { PubSub } from 'aws-amplify';
import { getDateTime } from '../../../../constants/Common';
import '.././style.css';
import translate from '../../../../locale/en_translate.json';
import { checkBasestationNetworkSpeed } from '../../API';
import closeGreen from '../../../../assets/images/right_widget_close_icon.svg';
import ViewItem from '../../common/ViewItem';

interface Props {
  showModal: boolean;
  handleCancel: () => void;
  baseStationHealthDetails: any;
}
export const BaseStationHealthModal: React.FC<Props> = ({
  showModal,
  handleCancel,
  baseStationHealthDetails,
}: Props) => {
  const { t } = useTranslation();
  const [speed, setSpeed] = useState<any>();
  const [loading, setLoading] = useState(false);
  const speedCheckFailedRef = useRef(true);

  const reTestSpeed = () => {
    setLoading(true);
    setSpeed(undefined);
    if (baseStationHealthDetails) {
      checkBasestationNetworkSpeed(
        baseStationHealthDetails.serial_number,
        baseStationHealthDetails.basestation.id,
      );
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (speedCheckFailedRef.current) {
        setLoading(false);
        notification.error({
          message: 'Basestation is not able to respond',
        });
      }
    }, 120000);
  }, []);

  useEffect(() => {
    PubSub.subscribe('nexus/bs/network_report').subscribe({
      next: (data) => {
        const networkData: any = data.value;
        setSpeed(networkData);
        setLoading(false);
        speedCheckFailedRef.current = false;
      },
    });
  }, []);

  return (
    <>
      <div className={'rightSideWidegt ' + (showModal ? 'expand' : 'hide')}>
        <div className="widgetCloseBtn flexBtn">
          <img
            src={closeGreen}
            alt="close icon"
            onClick={() => handleCancel()}
          />
        </div>
        <div className="profileEdit widget_card">
          <div className="proRow">
            <h4 className="posRel  headline3">
              <span>BaseStation #{baseStationHealthDetails?.name}</span>
            </h4>
          </div>
          <ViewItem
            title={t(translate.healthData.name)}
            content={baseStationHealthDetails?.name}
          />
          <ViewItem
            title={t(translate.healthData.serialNumber)}
            content={baseStationHealthDetails?.serial_number}
          />
          <ViewItem
            title={t(translate.tractorHealthDetails.status)}
            content={baseStationHealthDetails?.jng}
          />
          <ViewItem
            title={t(translate.tractorHealthDetails.orgnazation)}
            content={baseStationHealthDetails?.orgName}
          />
          <ViewItem
            title={t(translate.basestationHealthModal.lastOnlineTime)}
            content={getDateTime(
              baseStationHealthDetails?.connection?.last_online_time,
            )}
          />
          <ViewItem
            title={t(translate.basestationHealthModal.lastOfflineTime)}
            content={getDateTime(
              baseStationHealthDetails?.connection?.last_offline_time,
            )}
          />
          <ViewItem
            title={t(translate.logViewer.modelName)}
            content={baseStationHealthDetails?.basestation_model ?? '-'}
          />
          <ViewItem
            title={t(
              translate.basestationHealthModal.basestationSoftwareVersion,
            )}
            content={
              baseStationHealthDetails?.heartbeat?.basestation_software_version
            }
          />
          <ViewItem
            title={t(translate.healthData.routerstatus)}
            content={baseStationHealthDetails?.router_status}
          />
          <ViewItem
            title={t(translate.healthData.sbcstatus)}
            content={baseStationHealthDetails?.sbc_status}
          />
          <ViewItem
            title={t(translate.healthData.tractorSoftwareVersion)}
            content={
              baseStationHealthDetails?.heartbeat?.tractor_software_version ||
              'NA'
            }
          />
          <ViewItem
            title={t(translate.healthData.ipAddress)}
            content={baseStationHealthDetails?.ip_address}
          />
          <div className="proRowHealth">
            <h4 className="posRel  headline3">
              <span>{t(translate.basestationHealthModal.ntripDetails)}</span>
            </h4>
          </div>

          <ViewItem
            title={t(translate.basestationHealthModal.casterIp)}
            content={baseStationHealthDetails?.heartbeat?.NTRIP_caster_IP}
          />
          <ViewItem
            title={t(translate.basestationHealthModal.casterPort)}
            content={baseStationHealthDetails?.heartbeat?.NTRIP_caster_PORT}
          />
          <ViewItem
            title={t(translate.basestationHealthModal.casterMountPoint)}
            content={
              baseStationHealthDetails?.heartbeat?.NTRIP_caster_mountpoint
            }
          />
          <ViewItem
            title={t(translate.basestationHealthModal.casterConnectivity)}
            content={
              baseStationHealthDetails?.heartbeat?.NTRIP_caster_connectivity
            }
          />

          <div className="proRowHealth">
            <h4 className="posRel  headline3">
              <span>{t(translate.basestationHealthModal.systemStatus)}</span>
            </h4>
          </div>

          <ViewItem
            title={t(translate.basestationHealthModal.cpuTemp)}
            content={
              baseStationHealthDetails?.heartbeat?.system_status?.sbc?.cpu_temp
            }
          />
          <ViewItem
            title={t(translate.basestationHealthModal.cpuUsage)}
            content={
              baseStationHealthDetails?.heartbeat?.system_status?.sbc?.cpu_usage
            }
          />
          <ViewItem
            title={t(translate.basestationHealthModal.ramUsage)}
            content={
              baseStationHealthDetails?.heartbeat?.system_status?.sbc?.ram_usage
            }
          />
          <ViewItem
            title={t(translate.basestationHealthModal.diskUsage)}
            content={
              baseStationHealthDetails?.heartbeat?.system_status?.sbc
                ?.disk_usage
            }
          />
          <ViewItem
            title={t(translate.healthData.activeNetwork)}
            content={
              baseStationHealthDetails?.heartbeat?.system_status?.active_network
            }
          />
          <ViewItem
            title={t(translate.basestationHealthModal.freeSpace)}
            content={
              baseStationHealthDetails?.heartbeat?.system_status?.sbc
                ?.free_space
            }
          />
          <ViewItem
            title={t(translate.basestationHealthModal.rtkConfigured)}
            content={
              baseStationHealthDetails?.heartbeat?.system_status
                ?.radio_rtk_status
            }
          />

          <div className="proRowHealth">
            <h4 className="posRel  headline3">
              <span>
                {t(translate.basestationHealthModal.currentGeoLocation)}
              </span>
            </h4>
          </div>

          <ViewItem
            title={t(translate.tractorHealthDetails.latitude)}
            content={
              baseStationHealthDetails?.heartbeat?.current_geo_location
                ?.latitude
            }
          />
          <ViewItem
            title={t(translate.tractorHealthDetails.longitude)}
            content={
              baseStationHealthDetails?.heartbeat?.current_geo_location
                ?.longitude
            }
          />
          <ViewItem
            title={t(translate.basestationHealthModal.gnssTime)}
            content={
              baseStationHealthDetails?.heartbeat?.current_geo_location
                ?.gnss_time
            }
          />
          <ViewItem
            title={t(translate.basestationHealthModal.gnssDate)}
            content={getDateTime(
              baseStationHealthDetails?.heartbeat?.current_geo_location
                ?.gnss_date,
            )}
          />
          <ViewItem
            title={t(translate.basestationHealthModal.latitudeDirection)}
            content={
              baseStationHealthDetails?.heartbeat?.current_geo_location
                ?.latitude_direction
            }
          />
          <ViewItem
            title={t(translate.basestationHealthModal.longitudeDirection)}
            content={
              baseStationHealthDetails?.heartbeat?.current_geo_location
                ?.longitude_direction
            }
          />
          <ViewItem
            title={t(translate.basestationHealthModal.gpsStatus)}
            content={
              baseStationHealthDetails?.heartbeat?.current_geo_location
                ?.gps_status
            }
          />

          <div className="proRowHealth">
            <h4 className="posRel  headline3">
              <span>
                {t(translate.basestationHealthModal.surveyedGeoLocation)}
              </span>
            </h4>
          </div>

          <ViewItem
            title={t(translate.tractorHealthDetails.latitude)}
            content={
              baseStationHealthDetails?.heartbeat?.bs_surveyed_geo_location
                ?.latitude
            }
          />
          <ViewItem
            title={t(translate.tractorHealthDetails.longitude)}
            content={
              baseStationHealthDetails?.heartbeat?.bs_surveyed_geo_location
                ?.longitude
            }
          />
          <ViewItem
            title={t(translate.tractorHealthDetails.altitude)}
            content={
              baseStationHealthDetails?.heartbeat?.bs_surveyed_geo_location
                ?.altitude
            }
          />

          <div className="proRowHealth">
            <h4 className="posRel  headline3">
              <span>{t(translate.basestationHealthModal.radioDetails)}</span>
            </h4>
          </div>

          <ViewItem
            title={t(translate.basestationHealthModal.radioPowerStatus)}
            content={
              baseStationHealthDetails?.heartbeat?.bs_model?.radio_power_status
            }
          />

          <ViewItem
            title={t(translate.basestationHealthModal.connectedTractors)}
            content={baseStationHealthDetails?.heartbeat?.tractors_connected?.List.map(
              (indicator: any, i: number) => (
                <span key={i}>{indicator}</span>
              ),
            )}
          />

          {baseStationHealthDetails.connection?.status === 'online' && (
            <ViewItem
              title={t(translate.tractorHealthDetails.checkNetworkSpeed)}
              content={
                <div className="networkSpeedContainer">
                  {speed ? (
                    <p style={{ marginBottom: 0 }}>
                      <CloudUploadOutlined style={{ color: 'green' }} />{' '}
                      {speed?.data?.upload_speed
                        ? Number(speed?.data?.upload_speed).toFixed(1)
                        : 0}{' '}
                      {t(translate.basestationHealthModal.mbps)} <br />
                      <CloudDownloadOutlined style={{ color: 'red' }} />{' '}
                      {speed?.data?.download_speed
                        ? Number(speed?.data?.download_speed).toFixed(1)
                        : 0}{' '}
                      {t(translate.basestationHealthModal.mbps)}
                    </p>
                  ) : (
                    '-'
                  )}
                  <Button
                    className="defaultBtn checkSpeedBtn"
                    onClick={() => reTestSpeed()}
                    loading={loading}
                  >
                    {t(translate.tractorHealthDetails.check)}
                  </Button>
                </div>
              }
            />
          )}
        </div>
      </div>
    </>
  );
};

export default BaseStationHealthModal;
