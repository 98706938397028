/* eslint-disable no-console */
import React, { useState, useEffect, useContext } from 'react';
import { Modal, Button, Input, notification, Select, Spin } from 'antd';
import { PubSub } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import { CheckCircleTwoTone } from '@ant-design/icons';
import translate from '../../../../locale/en_translate.json';
import { ApplicationContext } from '../../../../context/AppContext';
import {
  getBaseStation,
  getSerialnumberChecking,
  saveStartMethod,
} from '../../API';

interface Props {
  showBaseStation: boolean;
  closeBaseStation: () => void;
}

const BaseStationAddSurvey: React.FC<Props> = ({
  showBaseStation,
  closeBaseStation,
}) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [baseStationList, setBaseStationList] = useState<any[]>([]);
  const [baseObj, setBaseObj] = useState<any>(null);
  const [hours, setHours] = useState<any>(null);
  const [baseIotObj, setBaseIotObj] = useState<any>(null);
  const [baseFlag, setBaseFlag] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [loader, setLoader] = useState<boolean>(false);
  const [healthFlag, setHealthFlag] = useState<boolean>(false);
  const [showComplete, setShowComplete] = useState<boolean>(false);
  const [isHealthCheck, setIsHealthCheck] = useState<boolean>(false);

  useEffect(
    () => () => {
      setBaseStationList([]);
      setBaseIotObj(null);
      setBaseObj(null);
    },
    [],
  );

  useEffect(() => {
    setIsHealthCheck(baseObj && hours);
  }, [baseObj, hours]);

  useEffect(() => {
    if (baseIotObj && baseObj) {
      const { name, serial_number } = baseObj;
      const { basestation_serial_number } = baseIotObj;
      setIsDisabled(
        !(name && basestation_serial_number === serial_number && hours),
      );
    }
  }, [baseObj, hours, baseIotObj]);

  useEffect(() => {
    if (userDetails) {
      getBase();
    }
  }, [userDetails]);

  useEffect(() => {
    let sub: any;
    if (baseFlag) {
      sub = PubSub.subscribe('nexus/bs/survey_service_status').subscribe({
        next: (data) => {
          setHealthFlag(false);
          if (data) {
            setBaseIotObj(data?.value);
            if (
              data?.value?.basestation_serial_number === baseObj?.serial_number
            ) {
              setShowComplete(true);
            }
          }
        },
      });
    }
    return () => {
      sub?.unsubscribe();
    };
  }, [baseFlag]);

  const getBase = async () => {
    try {
      const data = await getBaseStation();
      setBaseStationList(data);
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    }
  };

  const getSerialNumChecking = async () => {
    try {
      const { serial_number } = baseObj;
      setHealthFlag(true);
      setShowComplete(false);
      const data = await getSerialnumberChecking(serial_number);
      if (data) {
        setBaseFlag(true);
      }
    } catch (err) {
      notification.error({
        message: `${t(translate.modal.healthCheckFaild)}`,
      });
    }
  };

  const SelectbaseMethod = (obj: any) => {
    setShowComplete(false);
    const basestationData = JSON.parse(obj);
    console.log(basestationData, 'sdvdfh');
    setBaseObj(basestationData);
    setBaseFlag(false);
  };

  const saveStart = async () => {
    const payload = {
      duration_in_hours: hours,
      basestation_id: baseObj?.id,
      organization_id: baseObj?.organization_id,
    };
    try {
      setLoader(true);
      const data = await saveStartMethod(
        baseIotObj?.basestation_serial_number,
        payload,
      );
      if (data) {
        notification.success({
          message: ' Survey process initiated',
        });
        closeBaseStation();
      }
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const methodHours = (e: any) => {
    const re = /^[0-9\b]+$/;
    if ((e === '' || re.test(e)) && e > 0 && e < 169) {
      setHours(e);
    } else {
      setHours('');
    }
  };

  return (
    <Modal
      className="basestationSurvey"
      width={1200}
      bodyStyle={{ height: 'auto' }}
      title={t(translate.baseStationSurvey.addSurvey)}
      centered
      visible={showBaseStation}
      closable={false}
      footer={[
        <Button
          key="back"
          onClick={closeBaseStation}
          className="btnCancel migrateModaltext"
        >
          {t(translate.buttons.canCel)}
        </Button>,
        <Button
          key="submit"
          onClick={saveStart}
          className={
            isDisabled
              ? 'btnDisable migrateModaltext'
              : 'ant-btn-primary migrateModaltext'
          }
          disabled={isDisabled}
        >
          {t(translate.buttons.start)}
        </Button>,
      ]}
    >
      <div className="formRow bshrs">
        <div className="formCol">
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label>{t(translate.modal.baseStation)}</label>
            </div>
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input">
                <div className="ant-form-item-control-input-content input-align">
                  <Select
                    onSelect={SelectbaseMethod}
                    showSearch
                    value={baseObj?.name}
                    style={{ width: '400px' }}
                    placeholder="Base Station"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option!.title as string)
                        ?.toLowerCase()
                        ?.includes(input?.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA!.title as string)
                        ?.toLowerCase()
                        ?.localeCompare(
                          (optionB!.title as unknown as string).toLowerCase(),
                        )
                    }
                  >
                    {baseStationList &&
                      baseStationList.map((ele: any) => (
                        <option
                          title={ele?.name}
                          value={JSON.stringify(ele)}
                          key={ele.id}
                        >
                          {ele?.name}
                        </option>
                      ))}
                  </Select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="formCol">
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label>{t(translate.modal.hours)}</label>
            </div>
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input">
                <div className="ant-form-item-control-input-content input-align">
                  <Input
                    value={hours}
                    onChange={(e) => methodHours(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Button
        className={!isHealthCheck ? 'btnDisable' : 'ant-btn-primary'}
        type="primary"
        onClick={getSerialNumChecking}
        loading={healthFlag}
        disabled={!isHealthCheck}
      >
        {t(translate.buttons.healthCheck)}
      </Button>
      {showComplete && (
        <CheckCircleTwoTone
          twoToneColor="#52c41a"
          size={20}
          style={{ fontSize: '16px', marginLeft: '10px' }}
        />
      )}
      <div className="edit-loader">
        {loader && (
          <div className="loader1">
            <Spin size="large" />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default BaseStationAddSurvey;
