import React, { useState } from 'react';
import { Tabs } from 'antd';
import '.././style.css';
import DiagnosticCodes from './DiagnosticCodes';
import FleetIndactor from './FleetIndactor';

const { TabPane } = Tabs;

const Index: React.FC = () => {
  const [tabid, setTabid] = useState<string>('Indicators');

  return (
    <div className="rightSideContainer">
      <Tabs
        activeKey={tabid}
        centered
        id="org-tab-Organization"
        className="common_tabs implementTab"
        onChange={(key) => {
          setTabid(key);
        }}
      >
        <TabPane
          tab="Indicators"
          key="Indicators"
          className="attTableHeight mBottom0"
        >
          {tabid === 'Indicators' && <FleetIndactor />}
        </TabPane>
        <TabPane
          tab="Error Codes"
          key="Error Codes"
          className="attTableHeight mBottom0"
        >
          {tabid === 'Error Codes' && <DiagnosticCodes />}
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Index;
