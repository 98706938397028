import { Button, Card, Modal, Tag } from 'antd';
import React, { useContext, useState } from 'react';
import tractorImage from '../../../assets/images/a_db_img_big.svg';
import appIcon from './images/AppIcon.svg';
import calendarIcon from './images/calendar_outline.svg';
import distanceIcon from './images/distance.svg';
import timeIcon from './images/time.svg';
import filterIcon from './images/filter.svg';
import './styles.scss';
import {
  deleteSession,
  SHOW_ANALYTIC_DASHBOARD,
} from '../../../constants/session';
import useComplaince from './useComplaince';
import { ApplicationContext } from '../../../context/AppContext';

interface Props {
  image: string;
  title: string;
  content: string;
}

const Item: React.FC<Props> = ({ image, title, content }) => (
  <div className="ADCGridItem">
    <div className="ADCGridItemLeft">
      <img
        className="ADCGridItemIcon"
        src={image}
        alt=""
        height="18"
        width="18"
      />
    </div>
    <div className="ADCGridItemRight">
      <div className="ADCGridItemHeading">{title}</div>
      <div className="ADCGridItemDescription">{content}</div>
    </div>
  </div>
);

const AnalyticDashboardCard: React.FC = () => {
  const [toggle, setToggle] = useState(true);
  const { checkShowAnalyticDashboard, analyticDashboardData } =
    useContext(ApplicationContext);
  const { updateCompliance } = useComplaince();

  const handleClose = async () => {
    const { compliance_id, compliance_code, compliance_version } =
      analyticDashboardData;
    const payload: any = {
      compliance_id,
      compliance_code,
      compliance_version,
      app_name: 'web',
      is_compliance_acknowledged: true,
    };
    await updateCompliance(payload);
    await checkShowAnalyticDashboard();
    deleteSession(SHOW_ANALYTIC_DASHBOARD);
    setToggle(false);
  };

  return (
    <Modal
      className="noPadding ADCpopup"
      visible={toggle}
      width={'auto'}
      closable={false}
      footer={false}
      // onCancel={() => setToggle(false)}
    >
      <Card
        className="ADCCantainer"
        title={
          <>
            <div className="ADCHeaderContainer">
              <div className="ADCHeaderLeft">
                <img className="ADCHeaderIcon" src={appIcon} alt="" />
              </div>
              <div className="ADCHeaderRight">
                <div className="ADCHeaderHeading">
                  <span className="ADCHeaderTxt">Analytics Dashboard </span>
                  <span className="newTag">New </span>
                </div>
                <div className="ADCHeaderDescription ADCmonthTxt">
                  Fall 2024
                </div>
              </div>
            </div>
          </>
        }
      >
        <div className="ADCContent">
          <img className="ADCBackgroundImage" src={tractorImage} />
          <div className="ADCGridSection">
            <Item
              image={filterIcon}
              title="Filter by Implement Type"
              content="Select an implement on the Smart Screen while operating to categorize work done"
            />
            <Item
              image={timeIcon}
              title="Track Operator Hours"
              content="Review your operators’ activity across different drive modes and tractors"
            />
            <Item
              image={distanceIcon}
              title="Coverage and Video Playback"
              content="Explore time-stamped block coverage with interactive maps and videos"
            />
            <Item
              image={calendarIcon}
              title="Analyze Savings Trends"
              content="Track the financial and environmental impact of your MK-V’s over time"
            />
          </div>
          <div className="ADCFooter">
            <div className="ADCFooterLeft">
              <Button
                className="ADCFooterBtn h36"
                onClick={() => handleClose()}
              >
                Start Exploring
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </Modal>
  );
};

export default AnalyticDashboardCard;
