import React, { useState } from 'react';
import { Tabs } from 'antd';
import '.././style.css';
import TractorHealth from './TractorHealth';
import BaseStationHealth from './BaseStationHealth';

const { TabPane } = Tabs;

const Index: React.FC = () => {
  const [tabid, setTabid] = useState<string>('Tractor Health');

  return (
    <div className="rightSideContainer">
      <Tabs
        activeKey={tabid}
        centered
        id="org-tab-Organization"
        className="common_tabs implementTab"
        onChange={(key) => {
          setTabid(key);
        }}
      >
        <TabPane
          tab="Tractor Health"
          key="Tractor Health"
          className="attTableHeight mBottom0"
        >
          {tabid === 'Tractor Health' && <TractorHealth></TractorHealth>}
        </TabPane>
        <TabPane
          tab="Basestation Health"
          key="Basestation Health"
          className="attTableHeight mBottom0"
        >
          {tabid === 'Basestation Health' && <BaseStationHealth />}
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Index;
