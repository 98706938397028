/* eslint-disable no-dupe-else-if */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import flag_red from '../../../assets/images/flag_red.svg';
import appIcon from '../../../assets/images/ic_apps.svg';
import nfIcon from '../../../assets/images/nfIcon.svg';
import nfTractor from '../../../assets/images/nfTractor.svg';
import nf_flag2 from '../../../assets/images/nf_flag2.svg';
import { checkAlphaFeature } from '../.././../constants/Common';
import translate from '../../../locale/en_translate.json';
import RoutesConstants from '../../../routes/RoutesConstant';
import { Avatar, Button, Menu, notification } from 'antd';
import {
  cancelAutomatedReminders,
  getTremsAndConditions,
  getUpdatesCount,
} from '../../../constants/Api';
import { ApplicationContext } from '../../../context/AppContext';
import { ApplicationThemeContext } from '../../../context/ThemeContext';
import { onMessageListener } from '../../../firebaseInit';
import TermsConditions from '../../auth/TermsConditions';
import VideoWidget from '../../common/VideoWidget';
import NotificationView from '../../notifications/NotificationView';
import monarch_technology from '../../../assets/images/monarch_technology.svg';
import { WINGSPANAI } from '../../../constants/constant';
import { AUTO_DRIVE_LOGOUT_CONFIRM } from '../../../context/actions';
import { AutoDriveApplicationContext } from '../../../context/AutoDriveContext';
import { AutoDriveLogoutConfirm } from '../../map/AutoDriveLogoutConfirm';
import './style.css';
import { BlockOutlined, UserOutlined } from '@ant-design/icons';
import SubMenu from 'antd/lib/menu/SubMenu';

interface Props {
  onLinkClick?: (link: string) => void;
}

const DealerSideMenu: React.FC<Props> = ({ onLinkClick }: Props) => {
  const { t } = useTranslation();
  const loc = useLocation();
  const { pathname } = useLocation();
  const [openKeys, setOpenKeys] = React.useState(['']);
  const rootSubmenuKeys = [
    'LiveOps',
    'FarmProfile',
    'Inventory',
    'RepairMaintenance',
    'Analytics',
    'HelpCenter',
  ];

  const onOpenChange = (keys: any) => {
    const latestOpenKey = keys.find((key: any) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const getOpenKeys = () => {
    if (pathname === RoutesConstants.DealerMaintenance) {
      setOpenKeys(['RepairMaintenance']);
    } else if (pathname === RoutesConstants.DealerAnalyticsTractor) {
      setOpenKeys(['Analytics']);
    } else if (pathname === RoutesConstants.LiveMap) {
      setOpenKeys(['LiveOps']);
    } else if (pathname === RoutesConstants.Organization) {
      setOpenKeys(['FarmProfile']);
    } else if (pathname === RoutesConstants.DealerInventory) {
      setOpenKeys(['Inventory']);
    } else if (pathname === RoutesConstants.HelpCenterSearch) {
      setOpenKeys(['HelpCenter']);
    }
  };

  // useEffect(() => {
  //   getOpenKeys();
  // }, [pathname]);

  // const { profile_url } = userProfile || {};
  const isActive = (name: string[] | string) => {
    if (Array.isArray(name)) {
      return loc && name.includes(loc.pathname)
        ? 'iconBox mItemSel'
        : 'iconBox';
    } else {
      return loc && name === loc.pathname ? 'iconBox mItemSel' : 'iconBox';
    }
  };

  const { handleLogout, handlePauseResumeStopLiveCom } = useContext(
    AutoDriveApplicationContext,
  );
  const {
    user,
    fullScreen,
    APPReducer,
    userDetails,
    isDealerAccount,
    analyticDashboardData,
  } = useContext(ApplicationContext);
  const { styles } = useContext(ApplicationThemeContext);
  const [termsData, setTermsData] = useState<any>();
  const [showModal, setshowModal] = useState<boolean>(false);
  const [notificationData, setNotificationData] = useState<any>();
  const [state] = APPReducer;
  const { AutoDriveReducer } = useContext(AutoDriveApplicationContext);
  const [autoDriveState, autoDriveDispatch] = AutoDriveReducer;
  // autoDriveLogoutConfirm key for display the popup
  const { autoDriveLogoutConfirm } = autoDriveState;
  const { videoWidgetData } = state;
  const [updateCount, setUpdateCount] = useState<number>(0);
  const userData = useRef<any>(null);
  userData.current = userDetails;

  useEffect(() => {
    if (userDetails && userDetails.organization) getCount();
  }, [userDetails]);

  const getCount = async () => {
    try {
      const { tractor_software_update_count } = await getUpdatesCount(
        userDetails.organization.api_url,
        userDetails.organization.id,
      );
      setUpdateCount(tractor_software_update_count);
    } catch (error: any) {
      notification.error({ message: error.message });
    }
  };

  const handleClick = (routeLnk: string) => {
    localStorage.removeItem('lastTabIndex');
    onLinkClick && onLinkClick(routeLnk);
  };

  const handleDismiss = async (data: any, key: string) => {
    if (!userData.current?.organization) return;
    const { ref_uuid } = data;
    try {
      const { api_url } = userData.current.organization;
      const payload = {
        ticket_id: ref_uuid,
        action: 'cancel_ticket_notification',
      };
      await cancelAutomatedReminders(api_url, payload);
      notification.close(key);
    } catch (error: any) {
      notification.error({ message: error.message });
    }
  };

  const profileImg = () => (
    <Avatar
      style={{
        width: '34px',
        height: '34px',
        backgroundColor: '#000',
        color: 'white',
        fontFamily: 'Montserrat-Bold',
      }}
      src={user?.profile_url}
    >
      {user?.first_name?.charAt(0)}
      {user?.last_name?.charAt(0)}
    </Avatar>
  );

  const handleMouseLeave = () => {
    setOpenKeys([]);
  };
  const openNotification = async (data: any = {}) => {
    const {
      message = '',
      event_category = 'Indicator',
      event_level = 1,
      user_id,
      body = '',
      camera = '',
    } = data;
    const key = `open${Date.now()}`;
    notification.open({
      message: '',
      duration: 2,
      key,
      description: (
        <div className="proRoleType subFlex flexBtn flexAlignStart mb0 notificationBlk">
          <div style={{ position: 'relative', width: '20%' }}>
            <div className="nfIcon posRel">
              <div className="nfTopIcon">
                <img
                  className=""
                  src={event_category !== 'Indicator' ? nfIcon : nfTractor}
                />
              </div>
              <div className="nfBottomIcon">
                {event_category === 'Indicator' ? (
                  <>
                    <img
                      className="flagIcon"
                      src={event_level === 3 ? flag_red : nf_flag2}
                    />
                    {event_level}
                  </>
                ) : (
                  profileImg()
                )}
              </div>
            </div>
          </div>
          <div className="notificationInrBlk ml20">
            <p className="notificationMsg body2">
              {message && message.trim() !== '' ? message : body}
              <br />
              {camera && camera.trim() !== '' ? `Camera : ${camera}` : ''}
            </p>
          </div>
          <div className="notificationbtn">
            {data?.event_category === 'guard_rails' && (
              <Button
                key="submit"
                onClick={() => {
                  setNotificationData(data);
                }}
                className="btnView"
              >
                View
              </Button>
            )}
            {Boolean(data?.notification_code === '100006') && (
              <Button
                key="submit"
                onClick={() => {
                  handleDismiss(data, key);
                }}
                className="btnView btnDismiss"
              >
                Dismiss
              </Button>
            )}
          </div>
        </div>
      ),
    });
  };

  useEffect(() => {
    getTermsAndConditionMethod();
  }, [user]);

  const getTermsAndConditionMethod = async () => {
    if (!user) return;
    const {
      id,
      organization_id,
      organization: { api_url },
    } = user;
    try {
      const data = await getTremsAndConditions(api_url, organization_id, id);
      if (data[0]) {
        setTermsData(data[0]);
        setshowModal(true);
      }
    } catch (error: any) {
      notification.error(error.message);
    }
  };

  const closeModel = () => {
    setshowModal(false);
  };

  onMessageListener()
    .then((payload: any) => {
      if (payload) {
        if (payload?.data) openNotification(payload?.data);
      }
    })
    // eslint-disable-next-line no-console
    .catch((err) => console.error('failed: ', err));

  return (
    <>
      <nav
        onMouseLeave={handleMouseLeave}
        className={`main-menu ${
          checkAlphaFeature(WINGSPANAI) ? 'wingSpanAI' : ''
        }`}
        style={{
          zIndex: fullScreen ? 99 : 1002,
          background: styles.menuColor,
        }}
        data-testid="sideMenu-AppSideMenu"
      >
        <div className="login_logo">
          <div className="login_logo_box">
            <div className="company_logo"></div>
          </div>
        </div>
        <div className="login_user_sec">
          <div className="login_user_dtl">
            <ul className="sMenuUL">
              <li className="listItemMain">
                <Link to={RoutesConstants.Profile}>
                  <div className="iconBoxProfile">
                    <Avatar
                      style={{
                        backgroundColor: '#67AFB7',
                        color: 'white',
                        fontFamily: 'Montserrat-Bold',
                      }}
                      data-testid="profileIcon-AppSideMenu"
                      src={user?.profile_url}
                    >
                      {user?.first_name?.charAt(0)}
                      {user?.last_name?.charAt(0)}
                    </Avatar>
                  </div>
                  <span
                    className="nav-text overline2"
                    data-testid={`${user?.getUserName()}-AppSideMenu`}
                  >
                    {user?.getUserName()}
                  </span>
                </Link>
              </li>
              {checkAlphaFeature('wingspanai') && (
                <li className="monarch_tech_img">
                  <img src={monarch_technology} alt="" />
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className="dlside_menu_sec">
          <Menu
            theme="dark"
            mode="inline"
            className="dealerMenuUL"
            openKeys={openKeys}
            selectedKeys={[pathname]}
            onOpenChange={onOpenChange}
          >
            <Menu.Item
              key="LiveOps"
              icon={
                <>
                  <Link to={RoutesConstants.LiveMap}>
                    <div className={isActive(RoutesConstants.LiveMap)}>
                      <div className="liveops_icon"></div>
                    </div>
                    <span
                      className="nav-text overline2"
                      data-testid="liveMapMenu-AppSideMenu"
                    >
                      Live Ops
                    </span>
                  </Link>
                </>
              }
              className="listItemMain"
            >
              <Link to={RoutesConstants.LiveMap}>
                {t(translate.dashboard.liveMap)}
              </Link>
            </Menu.Item>

            <Menu.Item
              key="FarmProfile"
              icon={
                <>
                  <Link to={RoutesConstants.Organization}>
                    <div className={isActive('/organization')}>
                      <div className="farmprofile_icon"></div>
                    </div>
                    <span
                      className="nav-text overline2"
                      data-testid="liveMapMenu-AppSideMenu"
                    >
                      Farm Profile
                    </span>
                  </Link>
                </>
              }
              className="listItemMain"
            >
              <Link to={RoutesConstants.Organization}>
                {t(translate.sideMenu.farmProfile)}
              </Link>
            </Menu.Item>

            <Menu.Item
              key="Inventory"
              icon={
                <>
                  <Link
                    className="listAnchor"
                    to={RoutesConstants.DealerInventory}
                  >
                    <div className={isActive(RoutesConstants.DealerInventory)}>
                      <div className="inventory_icon"></div>
                    </div>
                    <span
                      className="nav-text overline2"
                      data-testid="liveMapMenu-AppSideMenu"
                    >
                      Inventory
                    </span>
                  </Link>
                </>
              }
              className="listItemMain"
            >
              <Link to={RoutesConstants.DealerInventory}>
                {t(translate.sideMenu.Inventory)}
              </Link>
            </Menu.Item>

            <Menu.Item
              key="RepairMaintenance"
              icon={
                <>
                  <Link
                    className="listAnchor"
                    to={RoutesConstants.DealerMaintenance}
                  >
                    <div
                      className={isActive([
                        RoutesConstants.DealerMaintenance,
                        RoutesConstants.DealerTickets,
                        RoutesConstants.DealerMigrate,
                        RoutesConstants.DealerFleetHealth,
                        RoutesConstants.DealerUpdates,
                        RoutesConstants.DealerManuals,
                        RoutesConstants.DealerDiagnostics,
                        RoutesConstants.DealerSurvey,
                      ])}
                    >
                      <div className="maintanance_red"></div>
                    </div>
                    <span
                      className="nav-text overline2"
                      data-testid="liveMapMenu-AppSideMenu"
                    >
                      Maintenance
                    </span>
                  </Link>
                </>
              }
              className="listItemMain"
            >
              <Link to={RoutesConstants.DealerMaintenance}>
                {t(translate.sideMenu.maintenance)}
              </Link>
            </Menu.Item>

            <Menu.Item
              key="Analytics"
              icon={
                <>
                  <Link
                    className="listAnchor"
                    to={RoutesConstants.DealerAnalytic}
                  >
                    <div
                      className={isActive([
                        RoutesConstants.DealerAnalytic,
                        RoutesConstants.DealerAnalyticsTractor,
                        RoutesConstants.DealerReports,
                      ])}
                    >
                      <div className="analytics_icon"></div>
                    </div>
                    <span
                      className="nav-text overline2"
                      data-testid="liveMapMenu-AppSideMenu"
                    >
                      Analytics
                    </span>
                  </Link>
                </>
              }
              className="listItemMain"
            >
              <Link to={RoutesConstants.DealerAnalytic}>
                {t(translate.sideMenu.analytics)}
              </Link>
            </Menu.Item>

            {/* <SubMenu
              key="RepairMaintenance"
              className="listItemMain"
              icon={
                <Link className="listAnchor" to={RoutesConstants.DealerTickets}>
                  <div className={isActive('/dealer/tickets')}>
                    <div className="maintanance_red"></div>
                  </div>
                  <span
                    className="nav-text overline2"
                    data-testid="liveMapMenu-AppSideMenu"
                  >
                    Repair & <br />
                    Maintenance
                  </span>
                </Link>
              }
            >
              <Menu.Item key={RoutesConstants.DealerTickets}>
                {' '}
                <Link to={RoutesConstants.DealerTickets}>
                  {t(translate.sideMenu.ticket)}
                </Link>
              </Menu.Item>
              <Menu.Item key={RoutesConstants.DealerMigrate}>
                {' '}
                <Link to={RoutesConstants.DealerMigrate}>
                  {t(translate.sideMenu.migration)}
                </Link>
              </Menu.Item>
              <Menu.Item key={RoutesConstants.DealerFleetHealth}>
                {' '}
                <Link to={RoutesConstants.DealerFleetHealth}>
                  {t(translate.sideMenu.fleetHealths)}
                </Link>
              </Menu.Item>

              <Menu.Item key={RoutesConstants.DealerManuals}>
                {' '}
                <Link to={RoutesConstants.DealerManuals}>
                  {t(translate.sideMenu.manual)}
                </Link>
              </Menu.Item>
              <Menu.Item key={RoutesConstants.DealerUpdates}>
                {' '}
                <Link to={RoutesConstants.DealerUpdates}>
                  {t(translate.sideMenu.updates)}
                </Link>
              </Menu.Item>
              <Menu.Item key={RoutesConstants.DealerDiagnostics}>
                {' '}
                <Link to={RoutesConstants.DealerDiagnostics}>
                  {t(translate.sideMenu.diagnostics)}
                </Link>
              </Menu.Item>
            </SubMenu> */}
            {/* <SubMenu
              key="Analytics"
              className="listItemMain"
              icon={
                <Link
                  className="listAnchor"
                  to={RoutesConstants.DealerAnalyticsTractor}
                >
                  <div
                    className={isActive(RoutesConstants.DealerAnalyticsTractor)}
                  >
                    <div className="analytics_icon"></div>
                  </div>
                  <span
                    className="nav-text overline2"
                    data-testid="liveMapMenu-AppSideMenu"
                  >
                    Analytics
                  </span>
                </Link>
              }
            >
              <Menu.Item key={RoutesConstants.DealerAnalyticsTractor}>
                {' '}
                <Link to={RoutesConstants.DealerAnalyticsTractor}>
                  {t(translate.sideMenu.tractorUsageReport)}
                </Link>
              </Menu.Item>

              <Menu.Item key={RoutesConstants.DealerReports}>
                {' '}
                <Link to={RoutesConstants.DealerReports}>
                  {t(translate.sideMenu.reports)}
                </Link>
              </Menu.Item>
            </SubMenu> */}
            {/* <Menu.Item
              key={RoutesConstants.LiveMap}
              icon={
                <>
                  <Link to={RoutesConstants.LiveMap}>
                    <div className="iconBox">
                      <div className="dplanning_icon"></div>
                    </div>
                    <span
                      className="nav-text overline2"
                      data-testid="liveMapMenu-AppSideMenu"
                    >
                      Planning
                    </span>
                  </Link>
                </>
              }
              className="listItemMain"
            >
              <Link to={RoutesConstants.LiveMap}>
                {t(translate.sideMenu.planning)}
              </Link>
            </Menu.Item>
            <SubMenu
              key="Purchasing"
              className="listItemMain"
              icon={
                <Link className="listAnchor" to={''}>
                  <div className="iconBox">
                    <div className="purchase_icon"></div>
                  </div>
                  <span
                    className="nav-text overline2"
                    data-testid="liveMapMenu-AppSideMenu"
                  >
                    Purchasing
                  </span>
                </Link>
              }
              // title="Purchasing"
              // style={{ fontSize: '18px' }}
            >
              <Menu.Item key={RoutesConstants.Organization}>
                {' '}
                <Link to={RoutesConstants.Organization}>
                  {t(translate.sideMenu.tractorUsageReport)}
                </Link>
              </Menu.Item>
              <Menu.Item key={RoutesConstants.Organization}>
                {' '}
                <Link to={RoutesConstants.Organization}>
                  {t(translate.sideMenu.usageDashboard)}
                </Link>
              </Menu.Item>
              <Menu.Item key={RoutesConstants.Organization}>
                {' '}
                <Link to={RoutesConstants.Organization}>
                  {t(translate.sideMenu.reportsManager)}
                </Link>
              </Menu.Item>
              <Menu.Item key={RoutesConstants.Organization}>
                {' '}
                <Link to={RoutesConstants.Organization}>
                  {t(translate.sideMenu.reports)}
                </Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="SalesMaterials"
              className="listItemMain"
              icon={
                <Link className="listAnchor" to={''}>
                  <div className="iconBox">
                    <div className="material_icon"></div>
                  </div>
                  <span
                    className="nav-text overline2"
                    data-testid="liveMapMenu-AppSideMenu"
                  >
                    Sales <br /> Materials
                  </span>
                </Link>
              }
              // title="Sales Materials"
              // style={{ fontSize: '18px' }}
            >
              <Menu.Item key={RoutesConstants.Organization}>
                {' '}
                <Link to={RoutesConstants.Organization}>
                  {t(translate.sideMenu.tractorUsageReport)}
                </Link>
              </Menu.Item>
              <Menu.Item key={RoutesConstants.Organization}>
                {' '}
                <Link to={RoutesConstants.Organization}>
                  {t(translate.sideMenu.usageDashboard)}
                </Link>
              </Menu.Item>
              <Menu.Item key={RoutesConstants.Organization}>
                {' '}
                <Link to={RoutesConstants.Organization}>
                  {t(translate.sideMenu.reportsManager)}
                </Link>
              </Menu.Item>
              <Menu.Item key={RoutesConstants.Organization}>
                {' '}
                <Link to={RoutesConstants.Organization}>
                  {t(translate.sideMenu.reports)}
                </Link>
              </Menu.Item>
            </SubMenu> */}
            <Menu.Item
              key="HelpCenter"
              icon={
                <>
                  <Link to={RoutesConstants.HelpCenterSearch}>
                    <div className={isActive(RoutesConstants.HelpCenterSearch)}>
                      <div className="helpCenter_icon"></div>
                    </div>
                    <span
                      className="nav-text overline2"
                      data-testid="liveMapMenu-AppSideMenu"
                    >
                      Help Center
                    </span>
                  </Link>
                </>
              }
              className="listItemMain"
            >
              <Link to={RoutesConstants.HelpCenterSearch}>
                {t(translate.sideMenu.helpCenter)}
              </Link>
            </Menu.Item>
            <div className="bottomLine"></div>
          </Menu>
        </div>
        <ul className="logout">
          <li className="logoutLink" onClick={() => handleLogout()}>
            <a>
              <div className="iconBox">
                {/* <img src={logout} alt="logout" className="wv20" /> */}
                <div className="logout_icon"></div>
              </div>
              <span
                className="nav-text overline2"
                data-testid="logoutButton-AppSideMenu"
              >
                {t(translate.sideMenu.logout)}
              </span>
            </a>
          </li>
        </ul>
      </nav>
      <NotificationView data={notificationData} />
      {termsData && showModal && (
        <TermsConditions
          showModal={showModal}
          closeModel={closeModel}
          termsData={termsData}
        ></TermsConditions>
      )}
      {videoWidgetData?.toggle && <VideoWidget />}
      {/* Logout confirm dialog if auto_drive running */}
      {autoDriveLogoutConfirm && (
        <AutoDriveLogoutConfirm
          showModal={autoDriveLogoutConfirm}
          closeModel={(ok?: boolean) => {
            autoDriveDispatch({
              type: AUTO_DRIVE_LOGOUT_CONFIRM,
              payload: false,
            });
            if (ok) {
              handlePauseResumeStopLiveCom('stop');
            }
          }}
        />
      )}
      {/* {drivingTractors.length > 0 && <DeadManHandle />} */}
    </>
  );
};

export default DealerSideMenu;
