import React, { useContext, useEffect, useState } from 'react';
import { Tabs, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { track } from '../../../../util/logger';
import { ApplicationContext } from '../../../../context/AppContext';
import { getUpdatesCount } from '../../../../constants/Api';
import translate from '../../../../locale/en_translate.json';
import FleetHealthContext from '../../../fleethealth/FleetHealthContext';
import { SchUpdates } from '../../../scheduleUpdates/SchUpdates';

const { TabPane } = Tabs;

const Index: React.FC = () => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [tabid, setTabid] = useState<string | undefined>('5');
  const [updateCount, setUpdateCount] = useState<number>(0);

  useEffect(() => {
    track('Fleet Health', {
      event: 'Entered Fleet Health',
      start: true,
    });
    return () => {
      track('Fleet Health', {
        event: 'Exit from Fleet Health',
        start: false,
      });
      setUpdateCount(0);
    };
  }, []);

  useEffect(() => {
    if (userDetails && userDetails.organization) getCount();
  }, [userDetails]);

  const getCount = async () => {
    try {
      const { tractor_software_update_count } = await getUpdatesCount(
        userDetails.organization.api_url,
        userDetails.organization.id,
      );
      setUpdateCount(tractor_software_update_count);
    } catch (error: any) {
      notification.error({ message: error.message });
    }
  };

  return (
    <FleetHealthContext>
      <div className="rightSideContainer">
        <Tabs
          activeKey={tabid}
          centered
          id="fleet-tab-FleetHealthContainer"
          className="common_tabs"
          onChange={(key) => {
            setTabid(key);
          }}
        >
          <TabPane
            tab={
              <div className="updateCon">
                {updateCount > 0 && <div className="updateCount" />}{' '}
                {t(translate.headers.updates)}
              </div>
            }
            key="5"
            className="attTableHeight mBottom0"
          >
            <SchUpdates updateCount={updateCount} />
          </TabPane>
        </Tabs>
      </div>
    </FleetHealthContext>
  );
};

export default Index;
