import React, { useState } from 'react';
import { Tabs } from 'antd';
import OrgTractorUsageReport from './OrgTractorUsageReport';
import TractorUsageReport from './TractorUsageReport';

const { TabPane } = Tabs;

const Planning: React.FC = () => {
  const [tabid, setTabid] = useState<string>('organization');

  return (
    <div className="rightSideContainer">
      <Tabs
        activeKey={tabid}
        centered
        id="org-tab-Organization"
        className="common_tabs implementTab"
        onChange={(key) => {
          setTabid(key);
        }}
      >
        <TabPane
          tab="organization"
          key="organization"
          className="attTableHeight mBottom0"
        >
          {tabid === 'organization' && <OrgTractorUsageReport />}
        </TabPane>

        <TabPane
          tab="Tractors"
          key="Tractors"
          className="attTableHeight mBottom0"
        >
          {tabid === 'Tractors' && <TractorUsageReport />}
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Planning;
