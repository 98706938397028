/* eslint-disable no-console */
import { notification } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';

import './ADashboard.css';

import { Feature, Overlay, View } from 'ol';
import OLMap from 'ol/Map';
import { ScaleLine } from 'ol/control';
import Point from 'ol/geom/Point';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import * as proj from 'ol/proj';
import OSM from 'ol/source/OSM';
import VectorSource from 'ol/source/Vector';
import XYZ from 'ol/source/XYZ';
import mapNor from '../../../assets/images/mapNor.svg';
import mapSat from '../../../assets/images/mapSat.svg';
import MapCenterIcon from '../../../assets/images/map_recenter.svg';

import { Coordinate } from 'ol/coordinate';
import Geometry from 'ol/geom/Geometry';
import LineString from 'ol/geom/LineString';
import Cluster from 'ol/source/Cluster';
import { ApplicationContext } from '../../../context/AppContext';
import {
  Organization,
  Tractor,
  adminCalPtsDistanceActualAllow,
  adminPolygonHoverLabel,
  adminTractorConst,
  dairy,
  displayRecenterAllViewPortSetUpAdmin,
  drawAdminPoly,
  filterByOrgValue,
  getAdminOperationStatus,
  getAdminPathBorderColor,
  getAdminPathColor,
  getAdminPathFeatureStyle,
  isLowLocalizationAdmin,
  orgStyleFun,
  orgTractorFeatId,
  orgTractorStyleFun,
  recenterTheItem,
  setTractorSelected,
} from '../Common';
import { getAdminPolygonsData, getOrgTractor } from '../api';
import { AdminHeartBeat, OrgTractor } from '../types';
import MapLayers from './MapLayers';
import OrgTractorList from './OrgTractorList';
import { randomNum } from '../../../constants/Common';
import { SATILITE_LAYER_URL } from '../../../constants/constant';

const AdminDashboard: React.FC = () => {
  const { userDetails } = useContext(ApplicationContext);
  const [mapInitialized, setMapInitialized] = useState(false);
  const [orgTractorList, setOrgTractorList] = useState<OrgTractor[]>([]);
  const [orgList, setOrgList] = useState<OrgTractor[]>([]);
  const [tractorList, setTractorList] = useState<OrgTractor[]>([]);
  const [toggleSatellite, setToggleSatellite] = useState(true);
  const [selectedOrg, setSelectedOrg] = useState<number>(0);
  const [recenterTrigger, setRecenterTrigger] = useState<boolean>(false);
  const [recenterOrgId, setRecenterOrgId] = useState<number>(0);
  const [recenterTractorId, setRecenterTractorId] = useState<number>(0);
  const [selectedTractorId, setSelectedTractorId] = useState<number>(0);
  const mapElement: any = useRef();
  const container: any = useRef();
  const content: any = useRef();
  const mapRef = useRef<OLMap | null>(null);
  const vectorTileLayerRef = useRef<TileLayer<any>>();
  const satelliteTileLayerRef = useRef<TileLayer<any>>();
  const tractorFeatureLayerRef = useRef<VectorLayer<any>>();
  const vineYardOrgFeatureLayerRef = useRef<VectorLayer<any>>();
  const dairyOrgFeatureLayerRef = useRef<VectorLayer<any>>();
  const whitePolygonsFeaturesLayerRef = useRef<VectorLayer<any>>();
  const brownPolygonsFeaturesLayerRef = useRef<VectorLayer<any>>();

  const pathLayerRef = useRef<VectorLayer<any>>();
  const [loader, setLoader] = useState(false);
  const [showError, setShowError] = useState(false);
  const plannerTractorPaths = useRef<Map<string, any>>();
  const [heartbeat, setHeartbeat] = useState<AdminHeartBeat>();
  const [mapLayersToggle, setMapLayersToggle] = useState<boolean>(false);

  const [showBrownPolygons, setShowBrownPolygons] = useState<boolean>(true);
  const [showWhitePolygons, setShowWhitePolygons] = useState<boolean>(true);
  const [showTractors, setShowTractors] = useState<boolean>(true);
  const [showVineYardOrgs, setVineYardShowOrgs] = useState<boolean>(true);
  const [showDairyOrgs, setShowDairyOrgs] = useState<boolean>(true);
  const [farm, setFarm] = useState<boolean>(true);
  useEffect(() => {
    initializeMap();
  }, []);
  //  clear tractor if org change
  useEffect(() => {
    selectedOrg === 0 && setSelectedTractorId(0);
  }, [selectedOrg]);

  //  mapInitialization start here
  const initializeMap = async () => {
    try {
      const vectorLayer = new TileLayer({
        source: new OSM(),
        visible: false,
      });

      const satelliteLayer = new TileLayer({
        source: new XYZ({
          url: SATILITE_LAYER_URL,
          cacheSize: 1000,
        }),
        visible: true,
      });

      const orgTractorSource = new VectorSource({
        features: [],
      });
      const tractorClusterSource = new Cluster({
        distance: 0,
        source: orgTractorSource,
      });
      const TractorLayer = new VectorLayer({
        source: tractorClusterSource,
        style: function (feature: any) {
          return orgTractorStyleFun(feature);
        },
        zIndex: 2,
      });
      const vineYardOrgLayer = new VectorLayer({
        source: new Cluster({
          distance: 0,
          source: new VectorSource({
            features: [],
          }),
        }),
        style: function (feature: any) {
          return orgStyleFun(feature);
        },
        zIndex: 1,
      });
      const dairyOrgLayer = new VectorLayer({
        source: new Cluster({
          distance: 0,
          source: new VectorSource({
            features: [],
          }),
        }),
        style: function (feature: any) {
          return orgStyleFun(feature);
        },
        zIndex: 1,
      });
      const initialPathLayer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        visible: true,
        zIndex: 0,
      });

      const brownPolygonsFeaturesLayer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        visible: true,
      });
      const whitePolygonsFeaturesLayer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        visible: true,
      });

      // create map
      const initialMap = new OLMap({
        target: mapElement.current,
        layers: [
          satelliteLayer,
          vectorLayer,
          TractorLayer,
          vineYardOrgLayer,
          dairyOrgLayer,
          initialPathLayer,
          whitePolygonsFeaturesLayer,
          brownPolygonsFeaturesLayer,
        ],
        controls: [scaleControl()],
        view: new View({
          projection: 'EPSG:3857',
          center: proj.transform([0, 0], 'EPSG:4326', 'EPSG:3857'),
          // zoom: 24,
          zoom: 2,
          maxZoom: 25,
        }),
      });

      // set flag for map initialization
      setMapInitialized(true);
      mapRef.current = initialMap;
      // initialize satelight layer
      satelliteTileLayerRef.current = satelliteLayer;
      // initialize vector layer
      vectorTileLayerRef.current = vectorLayer;
      tractorFeatureLayerRef.current = TractorLayer;
      vineYardOrgFeatureLayerRef.current = vineYardOrgLayer;
      dairyOrgFeatureLayerRef.current = dairyOrgLayer;
      pathLayerRef.current = initialPathLayer;
      whitePolygonsFeaturesLayerRef.current = whitePolygonsFeaturesLayer;
      brownPolygonsFeaturesLayerRef.current = brownPolygonsFeaturesLayer;
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    }
  };
  //  mapInitialization ends here

  const scaleControl = () => {
    const control = new ScaleLine({
      units: 'metric',
      className: 'positionMap',
    });
    return control;
  };
  // to change road map and satelight map
  useEffect(() => {
    vectorTileLayerRef.current?.setVisible(!toggleSatellite);
    satelliteTileLayerRef.current?.setVisible(toggleSatellite);
  }, [toggleSatellite]);
  /**
   * Zoom In Zoom Out and Recenter
   */
  useEffect(() => {
    const init = async () => {
      try {
        setShowError(false);
        setLoader(true);
        const { organization } = userDetails;
        const records = await getOrgTractor(organization.api_url);
        const list =
          records && records.length && records.length > 0 ? records : [];
        setOrgTractorList(list);
        const orgs = filterByOrgValue(list, Organization);
        const tractors = filterByOrgValue(list, Tractor);
        setOrgList(orgs);
        setTractorList(tractors);
      } catch (error: any) {
        notification.error({
          message: error.message,
          duration: 1,
        });
      } finally {
        setShowError(true);
        setLoader(false);
      }
    };
    if (userDetails && userDetails.organization && mapInitialized) {
      init();
    }
  }, [userDetails, mapInitialized]);
  useEffect(() => {
    if (
      userDetails &&
      userDetails.organization &&
      mapInitialized &&
      orgTractorList &&
      orgTractorList.length > 0
    ) {
      drawOrgTractor();
    }
  }, [userDetails, mapInitialized, orgTractorList]);
  const drawOrgTractor = async () => {
    try {
      tractorFeatureLayerRef.current?.getSource().clear();

      const records =
        orgTractorList && orgTractorList.length > 0 ? orgTractorList : [];
      const orgTractorFeatures: Feature<any>[] = [];

      records.map((orgTractor: any) => {
        const {
          category,
          name,
          latitude,
          longitude,
          known_latitude,
          known_longitude,
          organization_id,
          crop_type,
        } = orgTractor;
        const cropType = crop_type?.toLowerCase();
        const lat = category === Tractor ? known_latitude : latitude;
        const long = category === Tractor ? known_longitude : longitude;

        const id = orgTractorFeatId(orgTractor.id);
        const klong =
          long * Number(`1.000000${Math.floor(randomNum() * 9 + 1)}`);

        const kLat = lat * Number(`1.000000${Math.floor(randomNum() * 9 + 1)}`);

        const tractorLocation = new Feature({
          geometry: new Point(proj.fromLonLat([klong, kLat])),
          pointType: category,
          pointId: orgTractor.id,
          orgId: category === Tractor ? organization_id : id,
          cropType,
        });
        tractorLocation.setId(id);
        tractorLocation.set('name', name);
        orgTractorFeatures.push(tractorLocation);
        category === Tractor &&
          tractorFeatureLayerRef.current
            ?.getSource()
            ?.getSource()
            .addFeature(tractorLocation);
        if (category === Organization) {
          if (cropType === dairy) {
            dairyOrgFeatureLayerRef.current
              ?.getSource()
              ?.getSource()
              .addFeature(tractorLocation);
          } else {
            vineYardOrgFeatureLayerRef.current
              ?.getSource()
              ?.getSource()
              .addFeature(tractorLocation);
          }
        }
      });
      displayRecenterAllViewPortSetUpAdmin(orgTractorFeatures, mapRef.current);
    } catch (error: any) {
      notification.error({
        message: error.message,
        duration: 1,
      });
    }
  };
  useEffect(() => {
    if (mapInitialized) {
      const overlay = new Overlay({
        element: container.current,
        autoPan: false,
        autoPanAnimation: {
          duration: 10,
        },
      });
      mapRef.current?.on('pointermove', function (evt) {
        const hit = mapRef.current?.forEachFeatureAtPixel(
          evt.pixel,
          function (feature: any) {
            const coordinate = evt.coordinate;
            const name = feature.get('name');
            if (name) {
              content.current.innerHTML = '<p>' + name + '</p>';
              overlay.setPosition(coordinate);
              mapRef.current?.addOverlay(overlay);
              return true;
            }
            return false;
          },
        );
        if (hit) {
          evt.map.getTargetElement().style.cursor = 'pointer';
        } else {
          overlay.setPosition(undefined);
          mapRef.current?.addOverlay(overlay);
          evt.map.getTargetElement().style.cursor = '';
        }
      });
    }
  }, [userDetails, mapInitialized]);

  useEffect(() => {
    recenterOrgHandle(recenterOrgId);
  }, [recenterOrgId, recenterTrigger]);
  const recenterOrgHandle = (orgId: number) => {
    const id = orgTractorFeatId(orgId);
    const view = mapRef.current?.getView();
    if (view && orgId) {
      const zoom = view.getZoom();
      zoom &&
        recenterTheItem(
          vineYardOrgFeatureLayerRef.current,
          mapRef.current,
          id,
          // zoom + 2,
        );
      zoom &&
        recenterTheItem(
          dairyOrgFeatureLayerRef.current,
          mapRef.current,
          id,
          // zoom + 2,
        );
    }
  };

  const recenterTractorHandle = (tractorId: number) => {
    const id = orgTractorFeatId(tractorId);
    const view = mapRef.current?.getView();
    if (view && tractorId) {
      const zoom = view.getZoom();
      zoom &&
        recenterTheItem(
          tractorFeatureLayerRef.current,
          mapRef.current,
          id,
          // zoom + 2,
        );
    }
  };

  useEffect(() => {
    recenterTractorHandle(recenterTractorId);
  }, [recenterTractorId]);

  const handleTractorClick = (tractorId: number) => {
    setSelectedTractorId(0);
    setRecenterTractorId(0);
    setTimeout(() => {
      setSelectedTractorId(tractorId);
      setRecenterTractorId(tractorId);
    }, 100);
  };
  const handleOrgClick = (orgId: number) => {
    setSelectedOrg(0);
    setTimeout(() => {
      setSelectedOrg(orgId);
    }, 100);
  };

  useEffect(() => {
    mapInitialized &&
      mapRef.current?.on('click', function (evt) {
        mapRef.current?.forEachFeatureAtPixel(evt.pixel, (feature) => {
          const feat = feature.get('features');
          if (
            feat &&
            feat.length > 0 &&
            feat[0] &&
            feat[0].get('pointType') &&
            (feat[0].get('pointType') === Tractor ||
              feat[0].get('pointType') === Organization)
          ) {
            const pointType = feat[0].get('pointType');
            const pointId = feat[0].get('pointId');
            const orgId = feat[0].get('orgId');
            if (pointType === Organization) {
              setSelectedOrg(0);
              setRecenterOrgId(0);
              setTimeout(() => {
                setSelectedOrg(pointId);
                setRecenterOrgId(pointId);
              }, 100);
            }
            if (pointType === Tractor) {
              handleTractorClick(pointId);
              handleOrgClick(orgId);
            }
          }
        });
      });
  }, [mapInitialized]);

  const handleZoom = (zoomType: string) => {
    if (mapRef.current) {
      const view = mapRef.current.getView();
      const zoom = view.getZoom();
      if (zoom) {
        if (zoomType === 'zoomIn') {
          view.setZoom(zoom + 1);
        } else {
          view.setZoom(zoom - 1);
        }
      }
    }
  };

  const toggleMapView = () => setToggleSatellite(!toggleSatellite);

  const handleRecenter = () => {
    if (selectedTractorId) {
      recenterTractorHandle(selectedTractorId);
    } else {
      recenterOrgHandle(selectedOrg);
    }
  };
  const clearPreviewPath = (id: number, rAvTractor: AdminHeartBeat) => {
    // const rAvTractor = tractorsMap.get(id);
    // dont clear preview path when tractor in remote av (auto drive)
    if (
      rAvTractor &&
      rAvTractor.planning_manager &&
      rAvTractor.planning_manager.planner_name &&
      rAvTractor.planning_manager.planner_name ===
        adminTractorConst.remote_av.key
    ) {
      return;
    }
    const feature =
      id && pathLayerRef?.current?.getSource()?.getFeatureById(id)
        ? pathLayerRef?.current?.getSource()?.getFeatureById(id)
        : null;
    id &&
      feature &&
      pathLayerRef &&
      pathLayerRef?.current?.getSource()?.removeFeature(feature);
  };

  useEffect(() => {
    heartbeat &&
      heartbeat.tractor_id &&
      selectedOrg &&
      heartbeat.tractor_id === selectedTractorId &&
      selectedOrg === heartbeat.organization_id &&
      heartBeatHandler(heartbeat);
  }, [heartbeat, selectedOrg, selectedTractorId]);
  const updateTractorLocation = (feature: any, latLang: any) => {
    feature.setGeometry(new Point(proj.fromLonLat(latLang)));
    feature.set('latLang', latLang);
  };
  const heartBeatHandler = (heartBeat: AdminHeartBeat) => {
    // const heartBeat: AdminHeartBeat = JSON.parse(message.toString());

    if (!heartBeat?.localization) {
      return;
    }

    const id = orgTractorFeatId(heartBeat.tractor_id);

    const tractorLayer = tractorFeatureLayerRef.current;
    if (tractorLayer?.getVisible()) {
      const feat: any = tractorFeatureLayerRef.current?.getSource();
      const feature: any = feat.getSource().getFeatureById(id);
      if (!feature) {
        return;
      }
      const pointTypeLowLocalization = isLowLocalizationAdmin(
        heartBeat?.localization,
      );
      const isFaulty =
        getAdminOperationStatus(heartBeat) === adminTractorConst.faulty.key
          ? true
          : false;
      // don't draw tractor path if tractor in low localization or faulty
      if (!pointTypeLowLocalization && !isFaulty) {
        // in path key we have the tractor moved path
        if (heartBeat.path && heartBeat.path.length > 0) {
          let listForPath: any = heartBeat.path.split('|');
          listForPath = listForPath.map((listItem: any) => listItem.split(','));
          listForPath = listForPath.map((listItem: any[]) =>
            listItem.map(Number),
          );
          let latLangs;
          if (
            listForPath &&
            listForPath.length > 0 &&
            listForPath[0].length > 4
          ) {
            // taking lat langs instead of x and y
            latLangs = listForPath.map((item: any) => [item[4], item[3]]);
          }
          if (latLangs) {
            let operatorName = '-';
            if (heartBeat?.drive_action_details) {
              const { operator_firstname, operator_lastname } =
                heartBeat.drive_action_details;
              if (operator_firstname || operator_lastname)
                operatorName = operator_firstname + ' ' + operator_lastname;
            }
            const avg_speed =
              heartBeat &&
              heartBeat?.localization &&
              heartBeat?.localization?.velocity;
            setPlannerTractorPaths(
              id,
              heartBeat?.planning_manager?.planner_name,
              latLangs,
              heartBeat.tractor_name,
              heartBeat.date_time,
              operatorName,
              avg_speed,
            );
            const latLang = latLangs[latLangs.length - 1];
            updateTractorLocation(feature, latLang);
          }
        }
      }
    }
  };
  const getZoomValue = (): number =>
    mapRef?.current?.getView().getZoom() as number;

  /**
   * Draw  planner Tractor Path starts Here
   */
  const drawPlannerTractorPath = () => {
    const defaultLayer = pathLayerRef.current;
    defaultLayer?.getSource().clear();
    const existingPath = plannerTractorPaths.current;
    if (existingPath) {
      const id = orgTractorFeatId(selectedTractorId);

      const value = existingPath.get(id);
      // const id = orgTractorFeatId(heartBeat.tractor_id);

      // existingPath.forEach((value, key) => {
      let lastElement: any;
      value.forEach((element: any, index: number) => {
        const color = getAdminPathColor(element.planner);
        const borderColor = getAdminPathBorderColor(element.planner);
        const style = getAdminPathFeatureStyle(element.planner, getZoomValue());
        const coordinates: Coordinate[] = [];
        // if previous and present planner same add last planner point to latest planner
        if (
          lastElement &&
          lastElement.points &&
          lastElement.points.length > 0 &&
          element &&
          element.points &&
          element.points.length > 0
        ) {
          const allowToCombinePath = adminCalPtsDistanceActualAllow([
            {
              latitude: lastElement.points[lastElement.points.length - 1][1],
              longitude: lastElement.points[lastElement.points.length - 1][0],
            },
            {
              latitude: element.points[0][1],
              longitude: element.points[0][0],
            },
          ]);
          const point = lastElement.points[lastElement.points.length - 1];
          if (allowToCombinePath) {
            coordinates.push(proj.fromLonLat([point[0], point[1]]));
          } else {
            // const coordinates = proj.fromLonLat([point[0], point[1]]);
            // const startingPathPoint = setBadLocalization(
            //   coordinates,
            //   globalShowBadLocalization,
            //   {
            //     ...lastElement,
            //     next_created_date_time: element.created_date_time,
            //   },
            // );
            // defaultLayer?.getSource().addFeature(startingPathPoint);
          }
        }
        element &&
          element.points &&
          element.points.forEach((item: any) => {
            coordinates.push(proj.fromLonLat([item[0], item[1]]));
          });
        let tractorLocation: Feature<Geometry> = new Feature();
        if (defaultLayer) {
          tractorLocation = defaultLayer
            ?.getSource()
            .getFeatureById(id + index);
        }
        if (!tractorLocation) {
          tractorLocation = new Feature();
        }
        tractorLocation.setGeometry(new LineString(coordinates));
        tractorLocation.setId(id + index);
        // tractorLocation.set('color', color);
        // tractorLocation.set('pointType', 'LIVEPATH');
        // tractorLocation.set('borderColor', borderColor);
        // tractorLocation.set('drawPointsText', element);
        tractorLocation.setStyle(style);
        // tractorLocation.set('style', style);
        // tractorLocation.set('hoverStyle', style);
        defaultLayer?.getSource().addFeature(tractorLocation);
        // added last planner data
        lastElement = element;
      });
      // });
    }
  };

  const setPlannerTractorPaths = async (
    id: string,
    planner: string,
    passedPoints: [number, number][],
    tractorName: string,
    created_date_time: number,
    operatorName?: string,
    avg_speed?: number,
  ) => {
    const existingPath = plannerTractorPaths.current;
    let arrData: any = [];
    const mainArrData: any = [];
    let pushIndex = 0;

    if (passedPoints && passedPoints.length > 0) {
      passedPoints.slice(1).forEach((v: [number, number], i) => {
        const allowToCombinePath = adminCalPtsDistanceActualAllow([
          { latitude: passedPoints[i][1], longitude: passedPoints[i][0] },
          {
            latitude: v[1],
            longitude: v[0],
          },
        ]);
        if (allowToCombinePath) {
          arrData.push(v);
          mainArrData[pushIndex] = arrData;
          return v;
        } else {
          if (arrData && arrData.length > 0) {
            arrData[arrData.length - 1] = {
              ...arrData[arrData.length - 1],
            };
            mainArrData[pushIndex] = arrData;
            pushIndex = pushIndex + 1;
          }
          arrData = [];
        }
      });
    }
    mainArrData.map((points: any) => {
      if (existingPath) {
        if (existingPath.get(id)) {
          const arr = existingPath.get(id);
          arr.push({
            planner,
            planner_name: planner,
            tractorName,
            operatorName,
            avg_speed,
            created_date_time,
            points,
          });
          existingPath.set(id, arr);
          plannerTractorPaths.current = existingPath;
        } else {
          existingPath.set(id, [
            {
              planner,
              planner_name: planner,
              tractorName,
              operatorName,
              avg_speed,
              created_date_time,
              points,
            },
          ]);
          plannerTractorPaths.current = existingPath;
        }
      } else {
        const existingPath = new Map<string, any>();
        const arr = [
          {
            planner,
            planner_name: planner,
            tractorName,
            operatorName,
            avg_speed,
            created_date_time,
            points,
          },
        ];
        existingPath.set(id, arr);
        plannerTractorPaths.current = existingPath;
      }
    });
    drawPlannerTractorPath();
  };
  /**
   * Draw  planner Tractor Path ends Here
   */

  useEffect(() => {
    // selectedTractorId
    // clear the planner layers points
    const clear1 = plannerTractorPaths.current;
    plannerTractorPaths.current?.clear();
    plannerTractorPaths.current = clear1;
    const id = orgTractorFeatId(selectedTractorId);
    setTractorSelected(tractorFeatureLayerRef.current, id);
  }, [selectedTractorId]);

  useEffect(() => {
    const init = async () => {
      try {
        const { organization } = userDetails;
        const list = await getAdminPolygonsData(organization.api_url);
        list && list.length > 0 && drawPolyAndPrepareLayers(list);
      } catch (error: any) {
        notification.error({
          message: error.message,
          duration: 1,
        });
      }
    };
    if (userDetails && userDetails.organization && mapInitialized) {
      init();
    }
  }, [userDetails, mapInitialized]);
  const drawPolyAndPrepareLayers = async (polyList: any) => {
    polyList.map(async (record: any) => {
      await drawAdminPoly(
        userDetails,
        record.color === 'brown'
          ? brownPolygonsFeaturesLayerRef.current
          : whitePolygonsFeaturesLayerRef.current,
        1,
        1,
        record,
      );
    });
    adminPolygonHoverLabel(mapRef.current);
  };
  useEffect(() => {
    vineYardOrgFeatureLayerRef.current?.setVisible(showVineYardOrgs);
  }, [showVineYardOrgs]);
  useEffect(() => {
    dairyOrgFeatureLayerRef.current?.setVisible(showDairyOrgs);
  }, [showDairyOrgs]);
  useEffect(() => {
    tractorFeatureLayerRef.current?.setVisible(showTractors);
  }, [showTractors]);
  useEffect(() => {
    whitePolygonsFeaturesLayerRef.current?.setVisible(showWhitePolygons);
  }, [showWhitePolygons]);
  useEffect(() => {
    brownPolygonsFeaturesLayerRef.current?.setVisible(showBrownPolygons);
  }, [showBrownPolygons]);
  useEffect(() => {
    if (
      showVineYardOrgs &&
      showDairyOrgs &&
      showTractors &&
      showWhitePolygons &&
      showBrownPolygons
    ) {
      setFarm(true);
    } else {
      if (
        !showDairyOrgs ||
        !showVineYardOrgs ||
        !showTractors ||
        !showWhitePolygons ||
        !showBrownPolygons
      ) {
        setFarm(false);
      }
    }
  }, [
    showDairyOrgs,
    showVineYardOrgs,
    showTractors,
    showWhitePolygons,
    showBrownPolygons,
  ]);

  return (
    <>
      <div className="">
        <OrgTractorList
          showError={showError}
          loader={loader}
          orgList={orgList}
          selectedOrg={selectedOrg}
          setSelectedOrg={setSelectedOrg}
          setSelectedTractorId={setSelectedTractorId}
          setRecenterOrgId={setRecenterOrgId}
          setRecenterTractorId={setRecenterTractorId}
          // setRecenterTrigger={setRecenterTrigger}2
          // recenterTrigger={recenterTrigger}
          tractorList={tractorList}
          selectedTractorId={selectedTractorId}
          setHeartbeat={setHeartbeat}
        />

        <div
          ref={mapElement}
          style={{
            height: '100vh',
          }}
          //to align right bottom of map distance
          className="posHd"
        ></div>
        <div
          className={`ad_mapIconSec ${
            selectedOrg !== 0 || selectedTractorId !== 0 ? 'ad_map_expand' : ''
          }`}
        >
          <div className="ad_mapIcons ">
            <div className="ad_mapRecenter">
              <img src={MapCenterIcon} onClick={handleRecenter} />
            </div>

            <div className="ad_zoom_in_out">
              <button
                className="ad_liveZoomIn"
                data-testid="zoomIn-MapIcons"
                onClick={() => handleZoom('zoomIn')}
              />
              <button
                className="ad_liveZoomOut"
                data-testid="zoomOut-MapIcons"
                onClick={() => handleZoom('zoomOut')}
              />
            </div>
            <div className="ad_mapView" data-testid="mapViewButton-MapIcons">
              <img
                src={toggleSatellite ? mapNor : mapSat}
                alt=""
                onClick={toggleMapView}
              />
            </div>
          </div>
        </div>
        {mapInitialized && (
          <div
            ref={container}
            className="ol-popup"
            style={{ display: 'block' }}
          >
            <div ref={content}></div>
          </div>
        )}
        <div
          className="mapLayerIcon adminMapLayerIcon"
          data-testid="mapLayer-LiveMap"
          onClick={(e) => {
            e.stopPropagation();
            setMapLayersToggle(!mapLayersToggle);
          }}
        ></div>
        <MapLayers
          mapLayersToggle={mapLayersToggle}
          setMapLayersToggle={setMapLayersToggle}
          showVineYardOrgs={showVineYardOrgs}
          setVineYardShowOrgs={setVineYardShowOrgs}
          showDairyOrgs={showDairyOrgs}
          setShowDairyOrgs={setShowDairyOrgs}
          setShowTractors={setShowTractors}
          setShowWhitePolygons={setShowWhitePolygons}
          setShowBrownPolygons={setShowBrownPolygons}
          showBrownPolygons={showBrownPolygons}
          showWhitePolygons={showWhitePolygons}
          showTractors={showTractors}
          farm={farm}
          setFarm={setFarm}
        />
        {/* <AdminLoader loader={loader} /> */}
      </div>
    </>
  );
};

export default AdminDashboard;
