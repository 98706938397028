import React, { useContext, useEffect, useState } from 'react';
import Employees from './Employees';
import OrganizationDetails from './OrganizationDetails';
import { ApplicationContext } from '../../context/AppContext';
import TeamsAppContext from '../../context/TeamContext';
import { Tabs } from 'antd';
import { track } from '../../util/logger';
import { useLocation } from 'react-router-dom';
import Fleet from '../fleet/Fleet';
import { SET_LAST_TAB_INDEX } from '../../context/actions';
import Implements from '../Implements/Implements';
import ChargePoints from '../chargePoints/ChargePoints';
import ImplementsList from '../Implements/ImplementsList';
import Customers from '../dealer/customers/Customers';

const { TabPane } = Tabs;
const Organization: React.FC = () => {
  const location = useLocation();
  const state: any = location && location.state ? location.state : null;
  const { APPReducer, user } = useContext(ApplicationContext);
  const [, dispatch] = APPReducer;
  const [tabid, setTabid] = useState<string>('');
  const [addNewImplement, setAddNewImplement] = useState<boolean>(false);

  useEffect(() => {
    if (state?.reset) {
      setTabid('Farm');
    }
  }, [state?.reset]);

  useEffect(() => {
    if (state && state.state === 'Fleet') {
      setTabid(state.state);
    }
  }, [state]);

  useEffect(() => {
    const lastTabIndex: string | null = localStorage.getItem('lastTabIndex');
    const tlastTabIndex: any = lastTabIndex && JSON.parse(lastTabIndex);
    if (tlastTabIndex?.catlog === 'Organization') {
      setTabid(tlastTabIndex?.tab);
    } else {
      setTabid('Farm');
    }
  }, [localStorage.getItem('lastTabIndex')]);

  useEffect(() => {
    track('Farm Profile', {
      event: 'Entered Farm Profile',
      start: true,
    });
    return () => {
      track('Farm Profile', {
        event: 'Exit from Farm Profile',
        start: false,
      });
    };
  }, []);

  return (
    <div className="rightSideContainer">
      <Tabs
        activeKey={tabid}
        centered
        id="org-tab-Organization"
        className="common_tabs implementTab"
        onChange={(key) => {
          setTabid(key);
          dispatch({
            type: SET_LAST_TAB_INDEX,
            payload: {
              catlog: 'Organization',
              tab: key,
            },
          });
          track(key, {
            event: `${key} Tab`,
          });
        }}
      >
        <TabPane tab="Farm" key="Farm" className="attTableHeight mBottom0">
          {tabid === 'Farm' && <OrganizationDetails />}
        </TabPane>
        <TabPane
          tab="Personnel"
          key="Personnel"
          className="attTableHeight mBottom0"
        >
          {tabid === 'Personnel' && (
            <TeamsAppContext>
              <Employees />
            </TeamsAppContext>
          )}
        </TabPane>
        <TabPane tab="Fleet" key="Fleet" className="attTableHeight mBottom0">
          {tabid === 'Fleet' && <Fleet />}
        </TabPane>
        <TabPane
          tab="Implements"
          key="Implements"
          className="attTableHeight mBottom0"
        >
          {tabid === 'Implements' && (
            <>
              {addNewImplement ? (
                <ImplementsList setAddNewImplement={setAddNewImplement} />
              ) : (
                <Implements setAddNewImplement={setAddNewImplement} />
              )}
            </>
          )}
        </TabPane>
        {user?.isInternalUser() && (
          <TabPane
            tab="Charge Points"
            key="Charge Points"
            className="attTableHeight mBottom0"
          >
            {tabid === 'Charge Points' && <ChargePoints />}
          </TabPane>
        )}
        {/* 
        <TabPane
          tab="Customers"
          key="Customers"
          className="attTableHeight mBottom0"
        >
          {tabid === 'Customers' && <Customers />}
        </TabPane> */}
      </Tabs>
    </div>
  );
};

export default Organization;
