import React, { useContext, useEffect, useState } from 'react';
import { Modal, Space, Button, notification, Select, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import './style.css';
import Interweave from 'interweave';
import verHisIcon from '../../assets/images/Group 1296.svg';
import verM_logo from '../../assets/images/verM_logo.svg';
import {
  Software,
  TractorHealth,
  updateVersionData,
} from '../../constants/types';
import { FIRMAWARE, SOFTWARE } from './SoftwareUpdate';
import { ApplicationContext } from '../../context/AppContext';
import {
  getAllVersionList,
  getTractorHealth,
  updateSoftwareAPI,
} from '../../constants/Api';
import { CheckOutlined } from '@ant-design/icons';
import { getReleaseDate } from '../../constants/Common';
import translate from '../../locale/en_translate.json';

interface Props {
  isModalVisible: boolean;
  setIsModalVisible: (state: boolean) => void;
  data: Software;
}
const validationsText = {
  tractorBattery: 'Tractor battery must be above 20% or currently charging.',
  tractorErrorCode:
    'Tractor has no relevant error codes with a severity level of 2 or 3.',
  tractorMotorOff: 'Tractor motor must be off.',
  internetConnection:
    'Tractor needs strong internet connection with a bandwidth greater than 10mbps.',
};
export const SoftwareModal: React.FC<Props> = ({
  isModalVisible,
  setIsModalVisible,
  data,
}: Props) => {
  const { userDetails, user } = useContext(ApplicationContext);
  const { t } = useTranslation();
  const [tractorHealth, setTractorHealth] = useState<TractorHealth>();
  const [isInstall, setIsInstall] = useState<boolean>(false);
  const [updatedVersion, setUpdatedVersion] = useState<updateVersionData[]>([]);
  const [selectVersion, setSelectVersion] = useState<any>();
  const [softwareVesrion, setSoftwareVesrion] = useState<any>();

  const [shwoReleaseNotesFlag, setshwoReleaseNotesFlag] =
    useState<boolean>(false);

  useEffect(() => {
    // allow to install if all health checks are good
    const status =
      tractorHealth &&
      tractorHealth.soc &&
      tractorHealth.soc.status &&
      tractorHealth.network &&
      tractorHealth.network.status &&
      tractorHealth.autonomy &&
      tractorHealth.autonomy.status &&
      tractorHealth.motor &&
      tractorHealth.motor.status;
    status ? setIsInstall(true) : setIsInstall(false);
  }, [tractorHealth]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  useEffect(() => {
    if (data && data.id) {
      getSoftwares(data.id);
    }
  }, [data]);

  useEffect(() => {
    if (user?.organization && data) {
      getVersionLists(data.tractor_model, data.type || 'software');
    }
  }, [user, data]);

  const getSoftwares = async (tractorId: number) => {
    if (!user) return;
    const {
      organization: { api_url },
    } = user;
    try {
      const health: TractorHealth = await getTractorHealth(api_url, tractorId);
      setTractorHealth(health);
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    }
  };
  const updateSoftware = async () => {
    if (!user) return;
    const {
      organization: { api_url, fleet },
    } = user;
    try {
      await updateSoftwareAPI(api_url, data.id, fleet.id, {
        current_software_version_id:
          data && data?.type === SOFTWARE
            ? data.software_version_id
            : data.firmware_version_id,
        organization_id: userDetails.organization.id,
        desired_software_version_id:
          data && data?.type === SOFTWARE
            ? data.latest_software_version_id
            : data.latest_firmware_version_id,
      });
      getVersionLists(data.tractor_model, data.type || 'software');

      notification.success({
        message: 'Update process initiated',
      });
      handleCancel();
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };
  const getVersionLists = async (tractorModel: string, type: string) => {
    if (!user) return;
    const {
      organization: { api_url, id },
    } = user;
    try {
      const records = await getAllVersionList(api_url, id, type, tractorModel);
      const results = records.map((record: updateVersionData) => {
        return {
          versionid: record.software_version_id,
          newVersion: record.software_version,
          releaseNotes: record.release_notes,
          isGoldBuild:
            record.build_info && record.build_info?.build_tag === 'gold'
              ? true
              : false,
        };
      });

      const softwareVersionData =
        type === 'software' ? data.latest_software : data.latest_firmware;
      const software_version_id = softwareVersionData?.software_version_id;
      let latest;
      if (software_version_id) {
        [latest] = results.filter(
          ({ versionid }: any) => versionid === software_version_id,
        );
      }
      if (latest) {
        setSoftwareVesrion(latest?.newVersion || '');
        setSelectVersion(latest);
      }
      setUpdatedVersion(results || []);
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    }
  };

  const handleSelectVersion = (version: any) => {
    const versionData = JSON.parse(version);
    if (data.type === SOFTWARE) {
      data.latest_software_version_id = versionData.versionid;
    } else {
      data.latest_firmware_version_id = versionData.versionid;
    }
    setSoftwareVesrion(versionData.newVersion);
    setSelectVersion(versionData);
  };

  return (
    <div>
      <Modal
        closable={false}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={false}
        className="softwareInstall"
      >
        <div className="verInstallSec">
          <h4 className="verHistTxt mb12 posRel">
            {data && data?.type && data?.type === SOFTWARE
              ? 'Software'
              : data.type === FIRMAWARE
              ? 'Firmware'
              : ''}{' '}
            {t(translate.updates.update)}
            <img src={verM_logo} alt="mlogo" className="mLogo" height="33" />
          </h4>
          <div className="verDtl mb32">
            <span className="verNo">
              {data && data?.type && data?.type === SOFTWARE
                ? data?.software_version
                : data?.firmware_version}
            </span>
            <span className="verDate">
              {data && data?.type && data?.type === SOFTWARE
                ? data.data && data.data.software
                  ? getReleaseDate(data.data.software.release_date)
                  : ''
                : data.type === FIRMAWARE
                ? data.data && data.data.firmware
                  ? getReleaseDate(data.data.firmware.release_date)
                  : ''
                : ''}
            </span>
          </div>

          <div className="software-update-items-list mb24">
            <div className="installIns mb20">
              {tractorHealth &&
              tractorHealth?.soc &&
              tractorHealth.soc.status &&
              tractorHealth?.autonomy &&
              tractorHealth.autonomy.status &&
              tractorHealth?.motor &&
              tractorHealth?.motor?.status &&
              tractorHealth?.network &&
              tractorHealth?.network?.status ? (
                <>{t(translate.updates.conditionsMet)}</>
              ) : (
                <>
                  <img src={verHisIcon} height="18" className="mr16" />{' '}
                  {t(translate.updates.issuesAlert)}
                </>
              )}
            </div>
            <ul className="installInsUL">
              {tractorHealth &&
              tractorHealth?.soc &&
              tractorHealth.soc.status ? (
                <span className="listClass listClassColor">
                  <CheckOutlined style={{ marginRight: 20 }} />{' '}
                  {validationsText.tractorBattery}
                </span>
              ) : (
                <li>
                  <span>{validationsText.tractorBattery}</span>
                </li>
              )}
              {tractorHealth &&
              tractorHealth?.autonomy &&
              tractorHealth.autonomy.status ? (
                <span className="listClass listClassColor">
                  <CheckOutlined style={{ marginRight: 20 }} />{' '}
                  {validationsText.tractorErrorCode}
                </span>
              ) : (
                <li>
                  <span>{validationsText.tractorErrorCode}</span>
                </li>
              )}
              {tractorHealth &&
              tractorHealth?.motor &&
              tractorHealth?.motor?.status ? (
                <span className="listClass listClassColor">
                  <CheckOutlined style={{ marginRight: 20 }} />{' '}
                  {validationsText.tractorMotorOff}
                </span>
              ) : (
                <li>
                  <span>{validationsText.tractorMotorOff}</span>
                </li>
              )}
              {tractorHealth &&
              tractorHealth?.network &&
              tractorHealth?.network?.status ? (
                <span className="listClass listClassColor">
                  <CheckOutlined style={{ marginRight: 20 }} />{' '}
                  {validationsText.internetConnection}
                </span>
              ) : (
                <li>
                  <span>{validationsText.internetConnection}</span>
                </li>
              )}
            </ul>
          </div>
          <div className="installDec">
            {t(translate.updates.updateMsg1)} <b>{data?.name}</b>{' '}
            {t(translate.updates.updateMsg2)}
          </div>

          <div className="instBtnSec">
            <Select
              style={{ width: 214, marginBottom: 20 }}
              className="dropdownStyle dW214"
              value={softwareVesrion}
              onSelect={(e: string) => handleSelectVersion(e)}
              disabled={!user?.isSupportUser()}
            >
              {updatedVersion.map((data: any, index: number) => {
                return (
                  <option key={index} value={JSON.stringify(data)}>
                    <div className="swOption">
                      {data.newVersion}{' '}
                      {data.isGoldBuild && (
                        <Tag color="gold" className="gold-tag">
                          {t(translate.updates.gold)}
                        </Tag>
                      )}
                    </div>
                  </option>
                );
              })}
            </Select>
          </div>

          <div className="instBtnSec instBtn">
            <Space>
              <Button
                type="primary"
                className="installBtn"
                onClick={() => setshwoReleaseNotesFlag(!shwoReleaseNotesFlag)}
              >
                {t(translate.buttons.releaseNotes)}
              </Button>
              <Button
                className="verCancelBtn mr0"
                onClick={() => handleCancel()}
              >
                {t(translate.buttons.cancel)}
              </Button>
              {isInstall || user?.isSupportUser() ? (
                <Button
                  type="primary"
                  className="installBtn"
                  onClick={() => updateSoftware()}
                >
                  {t(translate.buttons.install)}
                </Button>
              ) : (
                <Button type="primary" className="installBtn dblBtn">
                  {t(translate.buttons.install)}
                </Button>
              )}
            </Space>
          </div>
          {shwoReleaseNotesFlag && (
            <div className="releaseNotes mb24">
              <div className="verNoteHead">
                {t(translate.updates.releaseNotes)}:
              </div>
              <div className="verDec2">
                <Interweave
                  content={
                    selectVersion
                      ? selectVersion?.releaseNotes
                      : data?.data?.software?.latest_software?.release_notes ||
                        '-'
                  }
                />
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};
