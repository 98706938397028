/* eslint-disable no-console */
import moment from 'moment';
import React, { memo, useEffect, useRef, useState } from 'react';
import { getDateStamp, getTimeFromTimestamp } from '../../constants/Common';
import {
  differenceInMinutes,
  fetchName,
  getTimeFromTimestampAmPm,
  selectedPath,
} from '../operationalAnalytics/common';
import useAnalyticsStore from './../operationalAnalytics/store/operationAnalytics';
import { SelectedChunk } from '../../lib/types';
let gDriveActionAndTime: selectedPath | null = null;
let gChuncks: any[] = [];
let gCount = 0;
let gSelectedChunk: any = null;
let gPlayVideo = false;
let gEndDateTime = Infinity;
let gOnclickForMapAndChart = false;
interface Props {
  playVideo: boolean;
  setHasZoomed: (zoom: boolean) => void;
  setDriveActionAndTime: (driveActionAndTime: selectedPath) => void;
  driveActionAndTime: selectedPath;
  setPlayVideo: (state: boolean) => void;
  selectedChunk: SelectedChunk;
  setSelectedChunk: (state: SelectedChunk) => void;
  chunks: SelectedChunk[];
  setChunks: (state: SelectedChunk[]) => void;
  seekTime: number;
  setSeekTime: (seekTime: number) => void;
  setSelectedCamera: (camera: string) => void;
  setOnclickForMapAndChart: (onClickForMapAndChart: boolean) => void;
  onClickForMapAndChart: boolean;
}

const CustomBarHandler: React.FC<Props> = ({
  setHasZoomed,
  setDriveActionAndTime,
  driveActionAndTime,
  playVideo,
  setPlayVideo,
  selectedChunk,
  setSelectedChunk,
  chunks,
  setChunks,
  seekTime,
  setSeekTime,
  setSelectedCamera,
  setOnclickForMapAndChart,
  onClickForMapAndChart,
}) => {
  const { selectedDate } = useAnalyticsStore();

  const [startTime, setStartTime] = useState<string>('');
  const [endTime, setEndTime] = useState<string>('');
  // const [seekTime, setSeekTime] = useState<number>(0);
  const [duration, setDuration] = useState(0);
  const cleatTimer: any = useRef();
  const [showTool, setShowTool] = useState<number>(-1);
  const timerState = useRef<boolean>(true);

  // useEffect(() => {
  //   gDriveActionAndTime = driveActionAndTime;
  //   if (driveActionAndTime.created_date_time) {
  //     const startTime = getTimeFromTimestamp(driveActionAndTime.from);
  //     const [fHrs, fMins] = startTime.split(':');
  //     setAdjustedMins(Number(fMins) % 5);
  //     const newStartTime = !adjustedMins
  //       ? `${fHrs}:${Number(fMins) - adjustedMins} `
  //       : startTime;
  //     setStartTime(newStartTime);
  //     setEndTime(getTimeFromTimestamp(driveActionAndTime.to));
  //     setDriveActionAndTime({
  //       ...driveActionAndTime,
  //       from: driveActionAndTime.from - adjustedMins * 60 * 1000,
  //     });
  //   }
  // }, [driveActionAndTime]);
  useEffect(() => {
    gOnclickForMapAndChart = onClickForMapAndChart;
  }, [onClickForMapAndChart]);

  useEffect(() => {
    gDriveActionAndTime = driveActionAndTime;
    if (driveActionAndTime && driveActionAndTime.created_date_time) {
      const startTime = getTimeFromTimestamp(driveActionAndTime.from);
      const [fHrs, fMins] = startTime.split(':');
      const adjustedMins = Number(fMins) % 5;
      const newStartTime = adjustedMins
        ? `${fHrs}:${Number(fMins) - adjustedMins} `
        : startTime;
      setStartTime(newStartTime);
      if (adjustedMins) {
        const fromTime = driveActionAndTime.from - adjustedMins * 60 * 1000;
        setDriveActionAndTime({
          ...driveActionAndTime,
          from: fromTime,
          created_date_time: fromTime,
        });
      }
      setEndTime(getTimeFromTimestamp(driveActionAndTime.to));
    }
  }, [driveActionAndTime]);

  useEffect(() => {
    if (chunks.length) {
      const lastChunk = chunks[chunks.length - 1];
      gEndDateTime = lastChunk.endDateTime;
      setEndTime(getTimeFromTimestamp(gEndDateTime));
    }
  }, [chunks]);

  const findDiff = () => {
    const [fHrs, fMins] = startTime.split(':');
    const [tHrs, tMins] = endTime.split(':');
    const diffHours = Math.round(Number(tHrs) - Number(fHrs));
    const diffMins =
      fHrs < tHrs
        ? 60 - Number(fMins) + Number(tMins)
        : Number(tMins) - Number(fMins);
    const minutes = diffHours
      ? (diffHours - 1) * 60 + Number(diffMins)
      : Number(diffMins);
    const min = Math.ceil(minutes / 5) * 5;
    setDuration(min);
  };

  useEffect(() => {
    findDiff();
  }, [startTime, endTime]);

  useEffect(() => {
    cleatTimer.current = setInterval(() => {
      if (
        gDriveActionAndTime &&
        gSelectedChunk &&
        gSelectedChunk?.startDateTime &&
        gPlayVideo &&
        timerState.current
      ) {
        gCount += 1;
        if (gDriveActionAndTime.created_date_time < gEndDateTime) {
          setDriveActionAndTime({
            ...gDriveActionAndTime,
            created_date_time: gSelectedChunk.startDateTime + gCount * 1000,
            // created_date_time: gDriveActionAndTime.from + gCount * 1000,
          });
          const diff = differenceInMinutes(
            gDriveActionAndTime.from,
            gDriveActionAndTime.created_date_time,
          );
          setSeekTime(diff);
        }
      }
    }, 1000);
    return () => {
      if (cleatTimer.current) clearInterval(cleatTimer.current);
    };
  }, []);

  useEffect(() => {
    if (duration) {
      const tchunks = duration < 5 ? 1 : Math.round(duration / 5);

      const start = driveActionAndTime.from;
      const data = Array.from({ length: tchunks }).map((_, index) => {
        const startTime = index * 5;
        const endTime = (index + 1) * 5;
        const startDateTime = start + index * 5 * 60 * 1000;
        const endDateTime = startDateTime + 5 * 60 * 1000;
        return {
          id: index,
          lastIndex: index + 1 === tchunks ? true : false,
          startTime,
          endTime,
          startDateTime,
          endDateTime,
          active: false,
        };
      });
      setChunks([...data]);
      setSelectedChunk(data[0]);
    }
  }, [duration]);

  useEffect(() => {
    gChuncks = chunks;
  }, [chunks]);

  useEffect(() => {
    gPlayVideo = playVideo;
  }, [playVideo]);

  useEffect(() => {
    gCount = 0;
    gSelectedChunk = selectedChunk;
  }, [selectedChunk]);

  useEffect(() => {
    if (gChuncks) {
      const tchunks = gChuncks.map((chunk: any) => {
        chunk.active =
          chunk.startTime <= Math.floor(seekTime) &&
          chunk.endTime > Math.floor(seekTime);
        return chunk;
      });
      const [selected] = tchunks.filter((chunk: any) => chunk.active);
      setSelectedChunk(selected);
      setChunks([...tchunks]);
    }
  }, [seekTime]);

  const seek1 = (value: string) => {
    const momentObj = moment(selectedDate).startOf('day');
    const hour = Number(value?.split(':')[0]);
    const minAndAmPm = value?.split(':')[1];
    const minutes = Number(minAndAmPm?.split(' ')[0]);
    momentObj.hours(hour);
    momentObj.minutes(minutes);
    const epochTimeInMillis = momentObj.valueOf();
    if (driveActionAndTime && driveActionAndTime.created_date_time) {
      if (driveActionAndTime.created_date_time !== epochTimeInMillis) {
        setDriveActionAndTime({
          fromClick: 'chart',
          created_date_time: epochTimeInMillis,
          drive_action_uuid: driveActionAndTime?.drive_action_uuid,
          drive_action_uuid_id: driveActionAndTime?.drive_action_uuid_id,
          from: driveActionAndTime.from,
          to: driveActionAndTime.to,
          operator: driveActionAndTime.operator,
        });
      }
    }
  };

  return (
    <div className="barDisplay">
      <div className="adUsrDtl">
        <div className="adUsrIcon">
          {fetchName(
            driveActionAndTime?.operator?.first_name,
            driveActionAndTime?.operator?.last_name,
          )}
        </div>
        <div className="adUsrName">
          {`${driveActionAndTime?.operator?.first_name}
                ${driveActionAndTime?.operator?.last_name}`}
        </div>
      </div>
      <>
        <div className="timeLine">
          {driveActionAndTime?.created_date_time &&
          getTimeFromTimestampAmPm(driveActionAndTime?.from)
            ? getTimeFromTimestampAmPm(driveActionAndTime?.from)
            : '00:00'}
        </div>
        <div className="video_progress">
          <div className="dynamic_progress__filled"></div>
          <div className="video_progress__chunks">
            {chunks.map((chunk: any, index: number) => {
              return (
                <div
                  style={{ width: `${100 / chunks.length}%` }}
                  key={index}
                  data-key={index}
                  data-start={chunk.startTime}
                  data-end={chunk.endTime}
                  className={`video_progress__chunk ${
                    selectedChunk &&
                    (index < selectedChunk.id ||
                      (gDriveActionAndTime &&
                        gDriveActionAndTime.created_date_time === gEndDateTime))
                      ? 'fillled '
                      : ' '
                  } ${
                    selectedChunk && selectedChunk.id === index ? 'active' : ''
                  }`}
                  onMouseEnter={() => setShowTool(index)}
                  onMouseLeave={() => setShowTool(-1)}
                  onClick={() => {
                    setOnclickForMapAndChart(!gOnclickForMapAndChart);
                    setPlayVideo(false);
                    timerState.current = false;
                    setSelectedChunk(chunk);
                    setSeekTime(chunk.startTime);
                    setDriveActionAndTime({
                      fromClick: 'chart',
                      created_date_time: chunk.startDateTime,
                      drive_action_uuid: driveActionAndTime?.drive_action_uuid,
                      drive_action_uuid_id:
                        driveActionAndTime?.drive_action_uuid_id,
                      from: driveActionAndTime.from,
                      to: driveActionAndTime.to,
                      operator: driveActionAndTime.operator,
                    });
                    setTimeout(() => {
                      timerState.current = true;
                      setPlayVideo(true);
                    }, 2000);
                  }}
                >
                  {selectedChunk &&
                    selectedChunk.id !== index &&
                    index === showTool && (
                      <div
                        className={`chunk_progress_tooltip ${
                          chunks.length - 1 === index ? 'lastTtipHover' : ''
                        }`}
                      >
                        {chunk
                          ? `${getTimeFromTimestampAmPm(
                              chunk.startDateTime,
                            )} - ${getTimeFromTimestampAmPm(chunk.endDateTime)}`
                          : '00:00 - 05:00'}
                      </div>
                    )}
                </div>
              );
            })}
          </div>
          <div
            className={`video_progress_tooltip ${
              selectedChunk?.lastIndex ? 'lastTtipSel' : ''
            }`}
            id="video_progress_tooltip1"
          >
            {selectedChunk
              ? `${getTimeFromTimestampAmPm(
                  selectedChunk.startDateTime,
                )} - ${getTimeFromTimestampAmPm(selectedChunk.endDateTime)}`
              : '00:00 - 05:00'}
          </div>
        </div>

        <div className="timeLine">
          {!!document.getElementsByClassName('video_progress').length && (
            <CBarHandler
              seek={seek1}
              vLength={duration}
              startTime={startTime}
              currentTimeStamp={driveActionAndTime?.created_date_time}
              seekTime={seekTime}
              endTimeStamp={gEndDateTime}
              progressSelector="video_progress"
              progressBarSelector="dynamic_progress__filled"
              tooltipSelector="video_progress_tooltip"
              endTime={getTimeFromTimestampAmPm(gEndDateTime, true)}
            />
          )}
        </div>
        <div
          className="collapsedBtn"
          onClick={() => {
            setHasZoomed(false);
            setSelectedCamera('map');
          }}
        >
          Exit Drive Session
        </div>
      </>
    </div>
  );
};

interface Props1 {
  seek: (sec: string) => void;
  vLength: number;
  startTime: string;
  endTime: string;
  currentTimeStamp: number;
  endTimeStamp: number;
  progressSelector?: string;
  progressBarSelector?: string;
  tooltipSelector?: string;
  seekTime: number;
}

const BarHandler: React.FC<Props1> = ({
  // seek,
  vLength,
  startTime,
  currentTimeStamp,
  endTimeStamp,
  // progressSelector = 'video_progress',
  progressBarSelector = 'dynamic_progress__filled',
  tooltipSelector = 'video_progress_tooltip',
  seekTime,
  endTime,
}) => {
  const curTime: any = useRef();
  const fixTime: any = useRef();
  // const [barValue, setBarValue] = useState<number>(0);
  const [duration, setDuration] = useState(vLength);
  const durationRef = useRef(duration);

  const progress: any = document.querySelector(`.video_progress__chunks`);
  // const progress: any = document.querySelector(`.${progressSelector}`);
  const progressBar: any = document.querySelector(`.${progressBarSelector}`);
  const tooltipDiv: any = document.querySelector(`.${tooltipSelector}`);

  // console.table([seekTime, vLength, startTime, currentTimeStamp, endTimeStamp]);

  // const barHandling = (e: any) => {
  //   try {
  //     const currentChunk = e.target.getAttribute('data-key');
  //     const duration = durationRef.current;
  //     let offsetX = currentChunk * e.target.offsetWidth;
  //     offsetX += e.offsetX;
  //     const seekTime = (offsetX / progress.offsetWidth) * duration;
  //     const percent = (seekTime / duration) * 100;
  //     progressBar.style.flexBasis = `${percent}%`;
  //     tooltipDiv.style.left = `${percent - 2}%`;
  //     if (percent > 92) {
  //       progressBar.classList.add('tWidth');
  //     } else {
  //       progressBar.classList.remove('tWidth');
  //     }
  //     currentTime1(seekTime, true);
  //   } catch (error) {
  //     // eslint-disable-next-line no-console
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    const percent = (seekTime / duration) * 100;
    progressBar.style.flexBasis = `${percent}%`;
    if (percent - 2 >= 95) {
      progressBar.classList.add('tWidth');
    } else {
      progressBar.classList.remove('tWidth');
    }
    tooltipDiv.style.left = `${percent - 2}%`;
    handleToolTip(seekTime);
  }, [seekTime]);

  // useEffect(() => {
  //   progress?.addEventListener('click', barHandling);
  //   return () => progress?.removeEventListener('click', barHandling);
  // }, [progress]);

  // const endTime = () => {
  //   const [fHrs, fMins] = startTime.split(':');
  //   const fmins = Number(fHrs) * 60 + Number(fMins);
  //   const tmins = fmins + duration;
  //   return `${Math.floor(tmins / 60)}:${tmins % 60}`;
  // };

  // useEffect(() => {
  //   const durationMinutes = Math.floor(duration / 60);
  //   const durationSeconds = Math.floor(duration - durationMinutes * 60);
  //   if (durationMinutes || durationSeconds) {
  //     fixTime.current.innerHTML = getTimeFromTimestampAmPm(endTimeStamp);
  //   }
  // }, [duration]);

  // useEffect(() => {
  //   fixTime.current.innerHTML = getTimeFromTimestampAmPm(endTimeStamp);
  // }, [endTime]);

  const handleToolTip = (currentTime: number) => {
    const currentMinutes = Math.floor(currentTime / 60);
    const currentSeconds = Math.floor(currentTime - currentMinutes * 60);
    const renderText = () => {
      const [fHrs, fMins] = startTime.split(':');
      const fmins = Number(fHrs) * 60 + Number(fMins);
      const cminutes = currentMinutes * 60 + currentSeconds;
      const tmins = fmins + cminutes;
      return `${Math.floor(tmins / 60)}:${tmins % 60}`;
    };
    const doc: any = document;
    if (curTime?.current) {
      const cValue = renderText();
      curTime.current.innerHTML = cValue;
    }
    if (doc?.getElementById(tooltipSelector))
      doc.getElementById(tooltipSelector).innerHTML =
        getTimeFromTimestampAmPm(currentTimeStamp);
  };

  useEffect(() => {
    setDuration(vLength);
    durationRef.current = vLength;
  }, [vLength]);

  // useEffect(() => {
  //   if (progressBar) progressBar.style.flexBasis = `${barValue}%`;
  // }, [barValue]);

  return (
    <>
      <div
        style={{
          fontFamily: 'Montserrat-SemiBold',
        }}
      >
        <span
          style={{
            display: 'none',
          }}
          ref={curTime}
        >
          00:00
        </span>{' '}
        <span ref={fixTime}>{endTime || '00:00'}</span>
      </div>
    </>
  );
};

const CBarHandler = memo(BarHandler);

export default memo(CustomBarHandler);
