import axios from '../../constants/ApiInterceptor';
import {
  getDealerHeaders as getHeaders,
  getHeaders as getWsaiHeaders,
  getAtomHeaders,
} from '../../constants/Common';
import { appendSort } from '../../lib/utilities';
import { DistributionRequest } from './common/types';
import {
  BASESTATION_API,
  DEALER_TOKEN_API,
  LOOKUP_API,
  TRACTORS_API,
  TRACTORS_EDIT_API,
  CANCEL_EMAIL_REQUEST_API,
  CUSTOMERS_API,
  SEND_NEW_ORG_EMAIL,
  ORG_LIST_API,
  TRACTOR_MIGRATION_API,
  ORGANIZATION_API,
  ORG_USAGE_REPORT_API,
  TRACTOR_USAGE_REPORT,
  TICKET_SUMMARY_API,
  GROUPS_LOOKUP_API,
  CATEGORY_LOOKUP_API,
  GROUP_MEMBER_API,
  TICKETS_API,
  ORG_LOOKUP_API,
  TRACTOR_HEALTH_SUMMARY_API,
  TRACTOR_HEALTH_API,
  BASESTATION_HEALTH_API,
  BASESTATION_AUDIT_API,
  TRACTOR_HEALTH_REPORT_API,
  TRACTOR_NETWORK_CHECK_API,
  SAVE_TRACTOR_MIGRATION_API,
  TRACTOR_MIGRATION_LOG_DETAILS_API,
  RESOLVE_TICKET_API,
  TRACTOR_LIST_API,
  LOGVIEWER_LIST_API,
  LOGEXPORT_API,
  DOWNLOAD_LOGVIEWER_API,
  CSV_LOGVIEWER_API,
  FAVMODAL_API,
  MODALATTRIBUTE_API,
  MODALTYPETTRIBUTE_API,
  CONVERSION_LOGVIEWER_API,
  CANLOG_API,
  CANLOGSIGNAL_API,
  BASESTATION_LOGVIEWER_API,
  BSDETAILS_LOGVIEWER_API,
  BSLOGEXPORT_API,
  CSAT_LIST_API,
  CSAT_DOWNLOAD_API,
  AUTODRIVE_DOWNLOAD_API,
  AUTODRIVE_LIST_API,
  USER_MANUAL_API,
  USER_MANUAL_DELETE_API,
  EDIT_TOPIC_API,
  FILEPATH_TOPIC_API,
  TOPIC_LIST_API,
  BUILD_VERSION_LIST_API,
  PUBLISH_API,
  ERRORCODES_API,
  ERRORCODE_DROPDOWN_API,
  GET_ERRORCODE_API,
  GET_REPORTMANAGER_API,
  ADD_DISTRIBUTION_API,
  GET_GENERATED_API,
  GET_TRACTORMIGRATION_API,
  GET_SERVICE_WORKORDER_API,
  GET_USERS_API,
  GET_PARTNERS_API,
  GET_SERVICE_WORKORDER_DETAILS_API,
  BASESTATION_MIGRATION_API,
  BASESTATIONHISTORY_MIGRATION_API,
  BASESTATIONHISTORYAUDIT_MIGRATION_API,
  BASESTATIONHISTORYLOGS_MIGRATION_API,
  SAVEBASESTATIONHISTORY_MIGRATION_API,
  BASESTATIONHEALTHREPORT_MIGRATION_API,
  FLEETINDICATORREPORT_MIGRATION_API,
  TRACTOR_API,
  BASESTATION_SURVEY_API,
  CANCELBASESTATION_SURVEY_API,
  GETBASESTATION_SURVEY_API,
  BASESTATION_SURVEY_HEALTH_API,
  SAVESTARTBASESTATION_SURVEY_API,
} from './endpoints';

export const BASE_URL = process.env.REACT_APP_BASE_URL;

// const { REACT_APP_COGNIITO_ACCESS_TOKEN_URL } = process.env;

const isEmpty = (value: any) => {
  if (typeof value === 'undefined') return true;
  if (typeof value === 'string' && value.trim() === '') return true;
  if (typeof value === 'object' && value === null) return true;
  if (typeof value === 'number' && value === 0) return true;
  return false;
};

const emptyFilter = (data: any) => (key: string) => !isEmpty(data[key]);

const getNexusURL = (): string => {
  const id = localStorage.getItem('dealer_org_id');
  return `${BASE_URL}/nexus/organization${id ? `/${id}` : ''}`;
};

const getNexusUserURL = (userid: any): string => {
  return `${BASE_URL}/nexus/user${userid ? `/${userid}` : ''}`;
};

const getBaseURL = (url: string, id?: number): string =>
  `${url}organization${id ? `/${id}` : ''}`;

const getURL = (url: string): string => `${url.slice(0, -1)}`;

// const getFarmURL = (url: string, id?: number): string =>
//   `${url}farm${id ? `/${id}` : ''}`;

// const getFleetURL = (url: string, id?: number): string =>
//   `${url}fleet${id ? `/${id}` : ''}`;

// const getConverseURL = (url: string, id: number): string =>
//   `${url}converse/organization/${id}`;

export const fetchCall =
  (ENDPOINT: string) =>
  async (orgURL: string, orgId: number): Promise<any> => {
    const url = getBaseURL(orgURL, orgId) + ENDPOINT;
    const response = await getAPI(url);
    return response.data;
  };

export const fetchPaginateCall =
  (ENDPOINT: string) =>
  async (
    orgURL: string,
    orgId: number,
    page_number: number,
    page_size: number,
    search?: string,
  ): Promise<any> => {
    let url = getBaseURL(orgURL, orgId) + ENDPOINT;
    url += prepareQuery({
      page_number,
      page_size,
      q: search && encodeURI(search),
    });
    const response = await getAPI(url);
    return response.data;
  };

export const postCall =
  (ENDPOINT: string) =>
  async (orgURL: string, orgId: number, data: any): Promise<any> => {
    const url = getBaseURL(orgURL, orgId) + ENDPOINT;
    const response = await postAPI(url, data);
    return response.data;
  };

const prepareURL = (url: string, data: any = {}) =>
  Object.keys(data).length
    ? Object.keys(data).reduce(
        (a, c) => (a = a.replace(`:${c}`, data[c])) && a,
        url,
      )
    : url;

const prepareQuery = (data: any) =>
  Object.keys(data)
    .filter(emptyFilter(data))
    .reduce(
      (a, c, i, arr) =>
        (a += `${i === 0 ? '?' : ''}${c}=${data[c]}${
          arr.length - 1 !== i ? '&' : ''
        }`) && a,
      '',
    );

const postAPI = async (url: string, data: any) =>
  axios.post(url, data, {
    headers: await getHeaders(),
  });

const putAPI = async (url: string, data: any) =>
  axios.put(url, data, {
    headers: await getHeaders(),
  });

const getAPI = async (url: string) =>
  axios.get(url, {
    headers: await getHeaders(),
  });

const getAPIDownload = async (url: string) =>
  axios.get(url, {
    headers: await getHeaders(),
    responseType: 'blob',
  });

const deleteAPI = async (url: string) =>
  axios.delete(url, {
    headers: await getHeaders(),
  });

export const getTractors = async (
  page_number: number,
  page_size: number,
  searchKey: string,
  sortInfo?: any,
): Promise<any> => {
  let url = BASE_URL + TRACTORS_API;
  url += prepareQuery({
    page_number,
    page_size,
    q: searchKey,
  });
  if (sortInfo) url = appendSort(url, sortInfo);
  const response = await getAPI(url);
  return response.data;
};

export const getModelsListData = async (category: any) => {
  let url = BASE_URL + LOOKUP_API;
  url += prepareQuery({
    category,
  });
  const response = await getAPI(url);
  return response.data;
};

export const updateTractor = async (tractorId: number, payload: any) => {
  const url = prepareURL(BASE_URL + TRACTORS_EDIT_API, { tractorId });
  const response = await putAPI(url, payload);
  return response.data;
};

export const getBaseStations = async (
  page_number: number,
  page_size: number,
  searchKey?: string,
  sortInfo?: any,
) => {
  let url = BASE_URL + BASESTATION_API;
  url += prepareQuery({
    page_number,
    page_size,
    q: searchKey,
  });
  if (sortInfo) url = appendSort(url, sortInfo);
  const response = await getAPI(url);
  return response.data;
};

export const getCustomers = async (
  page_number: number,
  page_size: number,
  searchKey: string,
  sortInfo?: any,
): Promise<any> => {
  let url = BASE_URL + CUSTOMERS_API;
  url += prepareQuery({
    page_number,
    page_size,
    q: searchKey,
  });
  if (sortInfo) url = appendSort(url, sortInfo);
  const response = await getAPI(url);
  return response.data;
};

export const sendEmailResponse = async (payload: any): Promise<any> => {
  const url = BASE_URL + SEND_NEW_ORG_EMAIL;
  const response = await postAPI(url, payload);
  return response.data;
};

export const cancelEmailResponse = async (payload: any): Promise<any> => {
  const url = BASE_URL + CANCEL_EMAIL_REQUEST_API;
  const response = await putAPI(url, payload);
  return response.data;
};

export const getTractorMigrationData = async (
  page_number: number,
  page_size: number,
  searchKey: string,
  selectedOrgs?: any,
  sortInfo?: any,
): Promise<any> => {
  let url = BASE_URL + TRACTOR_MIGRATION_API;
  url += prepareQuery({
    page_number,
    page_size,
    q: searchKey,
    customer_org_id: selectedOrgs,
  });
  if (sortInfo) url = appendSort(url, sortInfo);
  const response = await getAPI(url);
  return response.data;
};

export const getOrgsList = async (): Promise<any> => {
  const url = BASE_URL + ORG_LIST_API;
  const response = await getAPI(url);
  return response.data;
};

export const addOrganization = async (payload: any) => {
  const URL = BASE_URL + ORGANIZATION_API;
  const response = await postAPI(URL, payload);
  return response.data;
};

export const getOrgTractorUsageReport = async (
  from_date: string,
  to_date: string,
  view: string,
  customer_org_id: string[],
) => {
  let URL = getNexusURL() + ORG_USAGE_REPORT_API;
  URL += prepareQuery({
    from_date,
    to_date,
    view,
    customer_organization_id: customer_org_id,
  });
  const response = await getAPI(URL);
  return response.data;
};

export const getTractorUsageReport = async (
  from_date: any,
  to_date: any,
  view: string,
  tractor_id: any,
  customer_org_id: any,
) => {
  let URL = getNexusURL() + TRACTOR_USAGE_REPORT;
  URL += prepareQuery({
    from_date,
    to_date,
    view,
    customer_organization_id: customer_org_id,
    tractor_id,
  });
  const response = await getAPI(URL);
  return response.data;
};

export const getOrgTractorsListData = async (
  orgIds: any,
  in_active_tractors?: boolean,
): Promise<{ records: any[] }> => {
  let URL = BASE_URL + TRACTORS_API;
  URL += prepareQuery({
    organization_id: orgIds,
    fetch_all: true,
    in_active_tractors: in_active_tractors,
  });
  const response = await getAPI(URL);
  return response.data;
};

export const getTicketSummary = async (
  customer_org_ids: number[] | string[], // 1,2,3,4
  group_ids?: number[] | string[], // 1,2,3,4
): Promise<any> => {
  let url = BASE_URL + TICKET_SUMMARY_API;
  url += prepareQuery({
    customer_org_id: customer_org_ids,
    group_id: group_ids,
  });
  const response = await getAPI(url);
  return response.data;
};

export const getGroups = async (orgId: number): Promise<any> => {
  let url = BASE_URL + GROUPS_LOOKUP_API;
  url += `/${orgId}/group/lookup`;
  const response = await getAPI(url);
  return response.data;
};

export const getTicketCategory = async (category: string) => {
  let url = BASE_URL + CATEGORY_LOOKUP_API;
  url += prepareQuery({
    category: category,
  });
  const response = await getAPI(url);
  return response.data;
};

export const getGroupMembers = async (
  orgId: number,
  groupId: string[] | number[],
) => {
  let url = BASE_URL + GROUP_MEMBER_API;
  url += `/${orgId}/user/search?belongs_to_group=true&group_id=${groupId}`;
  const Response = await getAPI(url);
  return Response.data;
};

export const getMaintainenceTickets = async (
  page_number: number,
  page_size: number,
  searchKey: string,
  from_date_time: any,
  to_date_time: any,
  status: any,
  severityLevel: any,
  customer_org_id: any,
  sort_column?: string,
  sort_order?: string,
  group_id?: string[] | number[],
  assignee_id?: number,
  ticket_priority?: any,
  selectedCategory?: string,
) => {
  let url = BASE_URL + TICKETS_API;
  url += `?page_number=${page_number}&page_size=${page_size}&q=${searchKey}`;
  if (status) {
    url += `&ticket_status=${status}`;
  }
  if (severityLevel.length > 0) {
    url += `&severity_level=${severityLevel}`;
  }
  if (from_date_time && to_date_time) {
    url += `&from_date_time=${from_date_time}&to_date_time=${to_date_time}`;
  }
  if (customer_org_id) {
    url += `&customer_org_id=${customer_org_id}`;
  }
  if (sort_column) {
    url += `&sort_column=${sort_column}&sort_order=${sort_order}`;
  }
  if (group_id) {
    url += `&group_id=${group_id}`;
  }
  if (selectedCategory) {
    url += `&sla_category=${selectedCategory}`;
  }
  if (assignee_id) {
    url += `&assignee_id=${assignee_id}`;
  }
  if (ticket_priority || ticket_priority === 0) {
    url += `&ticket_priority=${ticket_priority}`;
  }
  const response = await getAPI(url);
  return response.data;
};

export const getEquipments = async (org_id: number, customerOrgId: number) => {
  const url =
    BASE_URL +
    `/nexus/organization/${org_id}/customer/${customerOrgId}/lookup/equipment`;
  const response = await getAPI(url);
  return response.data;
};

export const getTags = async (org_id: number, customerOrgId: number) => {
  const url =
    BASE_URL +
    `/nexus/organization/${org_id}/customer/${customerOrgId}/lookup/tags?category=TAGS`;
  const response = await getAPI(url);
  return response.data;
};

export const createServiceTicket = async (payload: any) => {
  const url = BASE_URL + TICKETS_API;
  const response = await postAPI(url, payload);
  return response.data;
};

export const getTicketInfo = async (id: string) => {
  let url = BASE_URL + TICKETS_API;
  url += `/${id}`;
  const response = await getAPI(url);
  return response.data;
};

// export const getNexusDealerOrgs = async (cropType: string) => {
//   let url = BASE_URL + ORG_LOOKUP_API;
//   url += prepareQuery({
//     crop_type: cropType && encodeURI(cropType),
//   });
//   const response = await getAPI(url);
//   return response.data;
// };

export const getNexusDealerOrgs = async (cropType: string) => {
  let url = BASE_URL + ORG_LOOKUP_API;
  url += prepareQuery({
    crop_type: cropType && encodeURI(cropType),
  });
  const response = await axios.get(url, {
    headers: await getWsaiHeaders(),
  });
  return response.data;
};

export const updateBasestation = async (
  baseStation_id: number,
  payload: any,
): Promise<{ msg: string }> => {
  const url = BASE_URL + BASESTATION_API + baseStation_id;
  const response = await axios.put(url, payload, {
    headers: getHeaders(),
  });
  return response.data;
};

export const getTractorHealthSummary = async (
  customer_org_ids: number[] | string[], // 1,2,3,4
): Promise<any> => {
  let url = getNexusURL() + TRACTOR_HEALTH_SUMMARY_API;
  url += prepareQuery({
    customer_org_id: customer_org_ids,
  });
  const response = await getAPI(url);
  return response.data;
};

export const getTractorHealth = async (
  customer_org_id: any,
  page_number: number,
  page_size: number,
  connection?: string,
  searchKey?: string,
  tractor_health_state?: number,
  sortInfo?: any,
): Promise<any> => {
  let url = getNexusURL() + TRACTOR_HEALTH_API;
  url += prepareQuery({
    page_number,
    page_size,
    q: searchKey,
    customer_org_id,
    connection,
    tractor_health_state,
  });
  if (sortInfo) url = appendSort(url, sortInfo);
  const response = await getAPI(url);
  return response.data;
};

export const checkNetworkSpeed = async (
  tractor_serial_number: any,
  organization_id: number,
) => {
  const url = `${BASE_URL}/nexus/tractor/${tractor_serial_number}/tractor-network-check?organization_id=${organization_id}`;
  const response = await getAPI(url);
  return response.data;
};

export const getBaseStationHealth = async (
  customer_org_id: any,
  page_number: number,
  page_size: number,
  connection?: string,
  searchKey?: string,
  sbc_status?: string,
  router_status?: string,
  bs_model?: string,
  sortInfo?: any,
): Promise<any> => {
  let url = getNexusURL() + BASESTATION_HEALTH_API;
  url += prepareQuery({
    page_number,
    page_size,
    q: searchKey,
    customer_org_id,
    connection,
    sbc_status,
    router_status,
    bs_model,
  });
  if (sortInfo) url = appendSort(url, sortInfo);
  const response = await getAPI(url);
  return response.data;
};

export const getAudit = async (data: any): Promise<any> => {
  let url = BASE_URL + BASESTATION_AUDIT_API;
  url += prepareQuery({
    bs_serial_number: data.bs_serial_number,
    start_date: data?.start_date,
    end_date: data.end_date,
  });
  const response = await getAPI(url);
  return response.data;
};

export const checkBasestationNetworkSpeed = async (
  basestation_serial_number: any,
  basestation_id: number,
) => {
  let URL = prepareURL(getNexusURL() + TRACTOR_NETWORK_CHECK_API, {
    basestation_serial_number,
  });
  URL += prepareQuery({
    basestation_id,
  });
  const response = await getAPI(URL);
  return response.data;
};

export const getTractorHealthReport = async (
  tractor_serial_number: any,
  source_organization_id: number,
  target_organization_id: number,
  tractor_id: number,
) => {
  let URL = prepareURL(BASE_URL + TRACTOR_HEALTH_REPORT_API, {
    tractor_serial_number,
  });
  URL += prepareQuery({
    source_organization_id,
    target_organization_id,
    tractor_id,
    get_from_tractor: true,
  });
  const response = await getAPI(URL);
  return response.data;
};

export const saveTractorMigration = async (
  tractor_serial_number: string,
  payload: any,
) => {
  const URL = prepareURL(BASE_URL + SAVE_TRACTOR_MIGRATION_API, {
    tractor_serial_number,
  });
  const response = await postAPI(URL, payload);
  return response.data;
};

export const getMigrationLogs = async (
  tractor_org_assignment_id: number,
  page_number: number,
  page_size: number,
) => {
  let URL = prepareURL(BASE_URL + TRACTOR_MIGRATION_LOG_DETAILS_API, {
    tractor_org_assignment_id,
  });
  URL += prepareQuery({
    page_number,
    page_size,
  });
  const response = await getAPI(URL);
  return response.data;
};

export const resolveTicketSync = async (ticketId: number, payload: any) => {
  const URL = prepareURL(BASE_URL + RESOLVE_TICKET_API, {
    ticketId,
  });
  const response = await putAPI(URL, payload);
  return response.data;
};

export const editTeamAssignIds = async (ticketId: number, payload: any) => {
  const URL = `${BASE_URL}/nexus/fleet/maintenance-tickets/${ticketId}`;

  const Response = await putAPI(URL, payload);
  return Response.data;
};

export const fetchGroupsLookup = async (orgId: number) => {
  const url = `${BASE_URL}/nexus/organization/${orgId}/group/lookup`;
  const response = await getAPI(url);
  return response.data;
};

export const addComments = async (ticketId: number, payload: any) => {
  const url = `${BASE_URL}/nexus/fleet/maintenance-tickets/${ticketId}/comments`;
  const response = await postAPI(url, payload);
  return response.data;
};

export const deleteComment = async (
  maintenance_ticket_id: number,
  comment_id: number,
) => {
  const URL = `${BASE_URL}/nexus/fleet/maintenance-tickets/${maintenance_ticket_id}/comments/${comment_id}`;
  const Response = await deleteAPI(URL);
  return Response.data;
};

export const editComment = async (ticketId: number, payload: any) => {
  const url = `${BASE_URL}/nexus/fleet/maintenance-tickets/${ticketId}/comments`;
  const response = await putAPI(url, payload);
  return response.data;
};

export const getLogViewerContent = async (
  orgURL: string,
  page_number: number,
  page_size: number,
  sortInfo?: any,
): Promise<any> => {
  let url = BASE_URL + LOGVIEWER_LIST_API;
  url += prepareQuery({
    page_number,
    page_size,
  });
  const response = await getAPI(url);
  return response.data;
};

export const getOrgTractorsList = async (): Promise<any> => {
  const url = BASE_URL + TRACTOR_LIST_API;
  const response = await getAPI(url);
  return response.data;
};

export const generateLog = async (
  orgURL: string,
  tractor_serial_number?: string,
  payload?: any,
): Promise<{ msg: string }> => {
  const URL = prepareURL(BASE_URL + LOGEXPORT_API, {
    tractor_serial_number,
  });
  const response = await postAPI(URL, payload);
  return response.data;
};

export const downloadLogPdf = async (
  orgURL: string,
  transaction_id: string,
) => {
  const URL = prepareURL(BASE_URL + DOWNLOAD_LOGVIEWER_API, {
    transaction_id,
  });
  const response = await getAPIDownload(URL);
  return response.data;
};

export const getCsvfile = async (transaction_id: string) => {
  const URL = prepareURL(BASE_URL + CSV_LOGVIEWER_API, {
    transaction_id,
  });
  const response = await getAPI(URL);
  return response.data;
};

export const addLogViewerFavourites = async (userId: any, payload: any) => {
  const URL = getNexusUserURL(userId) + FAVMODAL_API;
  const response = await postAPI(URL, payload);
  return response.data;
};

export const getLogViewerFavouritesData = async (
  preference_user_id: any,
  page_number: number,
  page_size: number,
  searhKey = '',
) => {
  let URL = getNexusUserURL(preference_user_id) + FAVMODAL_API;
  URL += prepareQuery({
    page_number,
    page_size,
    q: searhKey,
    preference_user_id,
  });
  const response = await getAPI(URL);
  return response.data;
};

export const getModels = async (
  orgURL: string,
  model_name: string,
  category?: string,
) => {
  let URL = BASE_URL + MODALATTRIBUTE_API;
  URL += prepareQuery({
    model_name,
    category,
  });
  const response = await getAPI(URL);
  return response.data;
};

export const getLogModelTypes = async (orgURL: string, category?: string) => {
  let URL = BASE_URL + MODALTYPETTRIBUTE_API;
  URL += prepareQuery({
    category,
  });
  const response = await getAPI(URL);
  return response.data;
};

export const getLogConvertStatus = async (transaction_id: any) => {
  const URL = prepareURL(BASE_URL + CONVERSION_LOGVIEWER_API, {
    transaction_id,
  });
  const response = await getAPI(URL);
  return response.data;
};

export const getCanLogSignals = async (search: any) => {
  let URL = BASE_URL + CANLOG_API;
  URL += prepareQuery({
    page_number: 1,
    page_size: 1000,
    q: search,
  });
  const response = await getAPI(URL);
  return response.data;
};

export const getCanLogSignalsGraphList = async (
  signals: any,
  start_date_time?: any,
  end_date_time?: any,
) => {
  let URL = BASE_URL + CANLOGSIGNAL_API;
  URL += prepareQuery({
    signals,
    start_date_time,
    end_date_time,
  });
  const response = await getAPI(URL);
  return response.data;
};

export const getBasestationLogViewer = async (
  orgURL: string,
  orgId: number,
  page_number: number,
  page_size: number,
  sortInfo?: any,
): Promise<any> => {
  let url = getNexusURL() + BASESTATION_LOGVIEWER_API;
  url += prepareQuery({
    page_number,
    page_size,
  });
  if (sortInfo) url = appendSort(url, sortInfo);
  const response = await getAPI(url);
  return response.data;
};

export const getBaseStationDetails = async (
  page_number: number,
  page_size: number,
  searhKey?: string,
  sortInfo?: any,
): Promise<any> => {
  let url = BASE_URL + BSDETAILS_LOGVIEWER_API;
  url += prepareQuery({
    page_number,
    page_size,
    q: searhKey,
  });
  if (sortInfo) url = appendSort(url, sortInfo);
  const response = await getAPI(url);
  return response.data;
};

export const generateBaseLog = async (
  orgURL: string,
  orgId: number,
  serialNumber?: string,
  payload?: any,
): Promise<any> => {
  const URL = prepareURL(getNexusURL() + BSLOGEXPORT_API, {
    serialNumber,
  });
  const response = await postAPI(URL, payload);
  return response.data;
};

export const getBaseLogViewerFavouritesData = async (
  preference_user_id: any,
  page_number: number,
  page_size: number,
  feature?: string,
) => {
  let URL = getNexusUserURL(preference_user_id) + FAVMODAL_API;
  URL += prepareQuery({
    page_number,
    page_size,
    feature,
    preference_user_id,
  });
  const response = await getAPI(URL);
  return response.data;
};

export const getcsatReport = async (
  from_date: string,
  to_date: string,
  page_number: number,
  page_size: number,
  customer_org_id: string[],
) => {
  let url = getNexusURL() + CSAT_LIST_API;
  url += prepareQuery({
    page_number,
    page_size,
    customer_org_id,
    from_date,
    to_date,
  });
  const response = await getAPI(url);
  return response.data;
};

export const downloadCSATReport = async (
  customer_org_id: any,
  from_date: any,
  to_date: any,
  organization_type: any,
) => {
  let url = getNexusURL() + CSAT_DOWNLOAD_API;
  url += prepareQuery({
    customer_org_id,
    from_date,
    to_date,
    organization_type,
  });
  const response = await getAPI(url);
  return response.data;
};

export const getAutodriveCsvReport = async (
  month: any,
  year: any,
  customer_org_id: any,
) => {
  let url = BASE_URL + AUTODRIVE_DOWNLOAD_API;
  url += prepareQuery({
    month,
    year,
    customer_org_id,
  });
  const response = await getAPI(url);
  return response.data;
};
export const getAutodriveReport = async (
  month: any,
  year: any,
  customer_org_id: any,
  select_all: boolean,
) => {
  let url = BASE_URL + AUTODRIVE_LIST_API;
  url += prepareQuery({
    month,
    year,
    customer_org_id,
    select_all,
  });
  const response = await getAPI(url);
  return response.data;
};

export const getChaptersData = async (
  page_number?: number,
  page_size?: number,
  fetch_all?: boolean,
  is_published?: boolean,
  sortInfo?: any,
) => {
  let url = BASE_URL + USER_MANUAL_API;
  url += prepareQuery({
    page_number,
    page_size,
    fetch_all,
    is_published,
  });
  if (sortInfo) url = appendSort(url, sortInfo);
  const response = await getAPI(url);
  return response.data;
};

export const getIndicatorsDropdown = async () => {
  const url = BASE_URL + ERRORCODE_DROPDOWN_API;
  const response = await getAPI(url);
  return response.data;
};

export const deleteChapter = async (id: number): Promise<any> => {
  const URL = prepareURL(BASE_URL + USER_MANUAL_DELETE_API, {
    id,
  });
  const response = await deleteAPI(URL);
  return response.data;
};

export const addChapter = async (payload: any) => {
  const url = BASE_URL + USER_MANUAL_API;
  const response = await postAPI(url, payload);
  return response.data;
};

export const updateChapter = async (id: number, payload: any) => {
  const URL = prepareURL(BASE_URL + USER_MANUAL_DELETE_API, {
    id,
  });
  const response = await putAPI(URL, payload);
  return response.data;
};

export const editTopic = async (
  chapterId: number,
  formData: FormData,
  topicId: number,
) => {
  const URL = prepareURL(BASE_URL + EDIT_TOPIC_API, {
    chapterId,
    topicId,
  });
  const response = await putAPI(URL, formData);
  return response.data;
};

export const UploadToS3 = async (URL: string, payload: any) => {
  const Response = await fetch(URL, {
    method: 'post',
    body: payload,
  });
  return Response;
};

export const UploadToS3Status = async (
  chapterId: number,
  topicId: number,
  payload: any,
) => {
  const URL = prepareURL(BASE_URL + FILEPATH_TOPIC_API, {
    chapterId,
    topicId,
  });
  const response = await putAPI(URL, payload);
  return response.data;
};

export const getTopicData = async (chapterId: number, topicId?: number) => {
  let URL = prepareURL(BASE_URL + TOPIC_LIST_API, {
    chapterId,
  });
  if (topicId) {
    URL += `/${topicId}/sub-topic`;
  }
  const response = await getAPI(URL);
  return response.data;
};

export const DeleteTopic = async (chapterId: number, topicId: number) => {
  const URL = prepareURL(BASE_URL + EDIT_TOPIC_API, {
    chapterId,
    topicId,
  });
  const response = await deleteAPI(URL);
  return response.data;
};

export const editHTMTopic = async (
  chapterId: number,
  formData: FormData,
  topicId: number,
) => {
  const URL = prepareURL(BASE_URL + EDIT_TOPIC_API, {
    chapterId,
    topicId,
  });
  const response = await putAPI(URL, formData);
  return response.data;
};

export const addTopicinTopic = async (
  chapterId: number,
  formData: FormData,
) => {
  const URL = prepareURL(BASE_URL + TOPIC_LIST_API, {
    chapterId,
  });
  const response = await postAPI(URL, formData);
  return response.data;
};

export const getBuildVersionsListData = async (
  page_number: number,
  page_size: number,
  searchKey?: string,
  model?: string,
  sortInfo?: any,
) => {
  let url = BASE_URL + BUILD_VERSION_LIST_API;
  url += prepareQuery({
    page_number,
    page_size,
    build_status: 'pending,inprogress,completed',
    q: searchKey,
    model,
  });
  if (sortInfo) url = appendSort(url, sortInfo);
  const response = await getAPI(url);
  return response.data;
};

export const getReportsData = async (
  org_id: number,
  page_number: number,
  page_size: number,
  report: string,
  sortInfo?: any,
) => {
  let url = getNexusURL() + GET_REPORTMANAGER_API;
  url += prepareQuery({
    page_number,
    page_size,
    report_environment: report,
  });
  if (sortInfo) url = appendSort(url, sortInfo);
  const response = await getAPI(url);
  return response.data;
};

export const publishChapter = async (id: number, payload: any) => {
  const URL = prepareURL(BASE_URL + PUBLISH_API, {
    id,
  });
  const response = await putAPI(URL, payload);
  return response.data;
};

export const getErrorCodesData = async (indicator_key = '', q = '') => {
  let url = BASE_URL + ERRORCODES_API;
  url += prepareQuery({
    indicator_key,
    q,
  });
  const response = await getAPI(url);
  return response.data;
};

export const getReleaseNotes = async (
  page_number?: number,
  page_size?: number,
  fetch_all?: boolean,
  sortInfo?: any,
) => {
  let url = BASE_URL + USER_MANUAL_API;
  url += prepareQuery({
    category: 'RELEASE_NOTES',
    fetch_all: true,
    page_number,
    page_size,
  });
  if (sortInfo) url = appendSort(url, sortInfo);
  const response = await getAPI(url);
  return response.data;
};
export const getOnDemandReportsList = async (
  org_id: number,
  report_id: number,
  page_number: number,
  page_size: number,
): Promise<any> => {
  let URL = prepareURL(getNexusURL() + GET_GENERATED_API, {
    report_id,
  });
  URL += prepareQuery({
    page_number,
    page_size,
  });
  const response = await getAPI(URL);
  return response.data;
};

export const addDistribution = async (
  org_id: number,
  report_id: number,
  requestBody: DistributionRequest,
): Promise<any> => {
  const URL = prepareURL(getNexusURL() + ADD_DISTRIBUTION_API, {
    report_id,
  });
  const response = await putAPI(URL, requestBody);
  return response.data;
};

export const getMigrationDataHistory = async (
  page_number: number,
  page_size: number,
  tractor_serial_number: any,
) => {
  let url = BASE_URL + GET_TRACTORMIGRATION_API;
  url += prepareQuery({
    page_number,
    page_size,
    tractor_serial_number,
  });
  const response = await getAPI(url);
  return response.data;
};

export const getWorkOrderList = async (
  page_number: number,
  page_size: number,
  searchKey: string,
  from_date_time: any,
  to_date_time: any,
  customer_org_id: any,
  work_order_status: string,
  sortInfo?: any,
) => {
  let url = BASE_URL + GET_SERVICE_WORKORDER_API;
  url += prepareQuery({
    page_number,
    page_size,
    q: searchKey,
    from_date_time,
    to_date_time,
    customer_org_id,
    work_order_status,
  });
  if (sortInfo) url = appendSort(url, sortInfo);
  const response = await getAPI(url);
  return response.data;
};

export const getLaborList = async (
  org_id: number,
  name: string,
): Promise<any> => {
  let URL = getNexusURL() + GET_USERS_API;
  URL += prepareQuery({
    q: name,
  });
  const response = await getAPI(URL);
  return response.data;
};

export const getPartList = async (): Promise<any> => {
  const URL = BASE_URL + GET_PARTNERS_API;
  const response = await getAPI(URL);
  return response.data;
};

export const editWorkOrder = async (
  workOrderId: number,
  payload: any,
): Promise<any> => {
  const URL = prepareURL(BASE_URL + GET_SERVICE_WORKORDER_DETAILS_API, {
    workOrderId,
  });
  const response = await putAPI(URL, payload);
  return response.data;
};

export const getWorkOrderDetails = async (
  workOrderId: number,
): Promise<any> => {
  const URL = prepareURL(BASE_URL + GET_SERVICE_WORKORDER_DETAILS_API, {
    workOrderId,
  });
  const response = await getAPI(URL);
  return response.data;
};

export const getBasestationMigData = async (
  page_number: number,
  page_size: number,
  searchKey: string,
  sortInfo?: any,
): Promise<any> => {
  let url = BASE_URL + BASESTATION_MIGRATION_API;
  url += prepareQuery({
    page_number,
    page_size,
    q: searchKey,
  });
  if (sortInfo) url = appendSort(url, sortInfo);
  const response = await getAPI(url);
  return response.data;
};

export const getBasestationMigrationDataHistory = async (
  page_number: number,
  page_size: number,
  basestation_serial_number: any,
): Promise<any> => {
  let url = BASE_URL + BASESTATIONHISTORY_MIGRATION_API;
  url += prepareQuery({
    page_number,
    page_size,
    basestation_serial_number,
  });
  const response = await getAPI(url);
  return response.data;
};

export const getBasestationMigrationLogs = async (
  id: any,
  page_number: number,
  page_size: number,
): Promise<any> => {
  let URL = prepareURL(BASE_URL + BASESTATIONHISTORYLOGS_MIGRATION_API, {
    id,
  });
  URL += prepareQuery({
    page_number,
    page_size,
  });
  const response = await getAPI(URL);
  return response.data;
};

export const saveBasestationMigration = async (
  tractor_serial_number: any,
  payload: any,
): Promise<any> => {
  const URL = prepareURL(BASE_URL + SAVEBASESTATIONHISTORY_MIGRATION_API, {
    tractor_serial_number,
  });
  await postAPI(URL, payload);
  const response = await getAPI(URL);
  return response.data;
};

export const getBaseStationHealthReport = async (
  tractor_serial_number: any,
  source_organization_id: number,
  target_organization_id: number,
  tractor_id: number,
): Promise<any> => {
  let URL = prepareURL(BASE_URL + BASESTATIONHEALTHREPORT_MIGRATION_API, {
    tractor_serial_number,
  });
  URL += prepareQuery({
    source_organization_id,
    target_organization_id,
    basestation_id: tractor_id,
    get_from_tractor: true,
  });
  const response = await getAPI(URL);
  return response.data;
};

export const getFleetIndicatorHistory = async (
  from_date: string,
  to_date: string,
  tractor_id: string,
  page_number: number,
  page_size: number,
  indicator_key: string,
  organization_ids: any,
): Promise<any> => {
  let url = BASE_URL + FLEETINDICATORREPORT_MIGRATION_API;
  url += prepareQuery({
    from_date,
    to_date,
    tractor_id,
    page_number,
    page_size,
    indicator_key,
    organization_ids,
  });
  const response = await getAPI(url);
  return response.data;
};

export const getFleetOrgTractorsList = async (
  organization_id: any,
  in_active_tractors?: boolean,
): Promise<any> => {
  let url = BASE_URL + TRACTOR_API;
  url += prepareQuery({
    organization_id,
    fetch_all: true,
    in_active_tractors: true,
  });
  const response = await getAPI(url);
  return response.data;
};

export const getBasestationSurveyData = async (
  page_number: number,
  page_size: number,
  searchKey: string,
  customer_org_id: any,
  status: any,
): Promise<any> => {
  let url = BASE_URL + BASESTATION_SURVEY_API;
  url += prepareQuery({
    page_number,
    page_size,
    q: searchKey,
    customer_org_id,
    status,
  });
  const response = await getAPI(url);
  return response.data;
};

export const cancelBasestationSurvey = async (
  serialNumber: string,
  id: number,
): Promise<any> => {
  const URL = prepareURL(BASE_URL + CANCELBASESTATION_SURVEY_API, {
    serialNumber,
    id,
  });
  const response = await putAPI(URL, null);
  return response.data;
};

export const getBaseStation = async (): Promise<any> => {
  const url = BASE_URL + GETBASESTATION_SURVEY_API;

  const response = await getAPI(url);
  return response.data;
};

export const getSerialnumberChecking = async (
  serialNumber: string,
): Promise<any> => {
  const URL = prepareURL(BASE_URL + BASESTATION_SURVEY_HEALTH_API, {
    serialNumber,
  });
  const response = await getAPI(URL);
  return response.data;
};

export const saveStartMethod = async (
  serialNumber: string,
  payload: any,
): Promise<any> => {
  const URL = prepareURL(BASE_URL + SAVESTARTBASESTATION_SURVEY_API, {
    serialNumber,
  });
  const response = await postAPI(URL, payload);
  return response.data;
};
