import React from 'react';

interface Props {
  tractroSerialNumber: string;
  sourceOrgId: string;
  next: () => void;
}

const RestartTractor: React.FC<Props> = () => (
  <>
    <ul>
      <li>Rebooting the Basestation</li>
    </ul>
  </>
);

export default RestartTractor;
