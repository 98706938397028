import React, { useState } from 'react';
import { Tabs } from 'antd';
import '.././style.css';
import BasestationSurvey from './BasestationSurvey';

const { TabPane } = Tabs;

const Survey: React.FC = () => {
  const [tabid, setTabid] = useState<string>('Basestation Survey');

  return (
    <div className="rightSideContainer">
      <Tabs
        activeKey={tabid}
        centered
        id="org-tab-Organization"
        className="common_tabs implementTab"
        onChange={(key) => {
          setTabid(key);
        }}
      >
        <TabPane
          tab="Basestation Survey"
          key="Basestation Survey"
          className="attTableHeight mBottom0"
        >
          {tabid === 'Basestation Survey' && (
            <BasestationSurvey></BasestationSurvey>
          )}
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Survey;
