import { Input, notification, Popover, Row, Space } from 'antd';
import 'antd/dist/antd.css';
import Layout, { Content } from 'antd/lib/layout/layout';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { track } from '../../util/logger';
import { useHistory } from 'react-router-dom';
import sortDown from '../../assets/images/sort_down.svg';
import sortUp from '../../assets/images/sort_up.svg';
import Alerticon from '../../assets/images/Group 1296.svg';
import { getTractorsList } from '../../constants/Api';
import { product } from '../../constants/AppData';
import {
  alignItems,
  getStatusForTractor,
  getTractorStatusClass,
  initClearSelection,
  initScroller,
  tractorStatusSort,
} from '../../constants/Common';
import { privilagesConstants } from '../../constants/Privilages';
import { IndicatorsData, TractorsList } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import translate from '../../locale/en_translate.json';
import RoutesConstants from '../../routes/RoutesConstant';
import BaseStation from '../basestation/BaseStation';
import InfiniteScrollTable from '../common/InfiniteScrollTable';
import Battery from './Battery';
import Editfleet from './Editfleet';
import './style.css';
import { getIndicatorIcons } from '../remote_drive_new/common';
import { SearchOutlined } from '@ant-design/icons';
import CustomSelect from '../common/CustomSelect';
import usePaginate from '../../hooks/usePaginate';
import { mapTractorsData } from '../../lib/dataFormat';

interface Props {
  onSearchText?: (text: string) => void;
}
const Fleet: React.FC<Props> = ({ onSearchText }: Props) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const [pageSize] = useState<any>(25);
  const { userDetails, privilegeChecker } = useContext(ApplicationContext);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [fleetflage, setbyfleet] = useState<boolean>(false);
  const [fleetobj, setfleetobj] = useState<any>(null);
  const [search, setSearch] = useState<string>('');
  const [totalCount, setTotalCount] = useState<number>(0);
  const [sortedInfo, setSortedInfo] = useState<any>({
    columnKey: 'name',
    order: 'ascend',
  });
  const [refresh, setRefresh] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [sortedData, setSortedData] = useState<any>({
    name: 'ascend',
  });

  const [intialize, setIntialize] = useState<boolean>(false);
  const [productType, setProductType] = useState<string>('Tractor');
  const [inputValue, setInputValue] = useState('');

  const searchString = useRef<string>('');

  const { filterData, setData, activity, hasMore, checkActivity } =
    usePaginate();

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      loadTractors();
    }
    return () => {
      setfleetobj('');
      setProductType('Tractor');
      setSearch('');
      setIntialize(false);
      setInputValue('');
    };
  }, [userDetails]);

  const content = (record: any) => {
    return (
      <div>
        <p>
          {t(translate.fleet.updateAvailable)}
          <br />
          {t(translate.fleet.please)}{' '}
          <a
            onClick={() => {
              push({
                pathname: RoutesConstants.FleetHealth,
                state: {
                  action: 'SOFTWARE',
                  tractorId: record?.id,
                },
              });
            }}
            style={{ textDecoration: 'underline' }}
          >
            {t(translate.fleet.updateNow)}{' '}
          </a>
        </p>
      </div>
    );
  };

  const showUpdaterIcon = (record: any) => {
    if (
      record &&
      record?.heartbeat?.software_version !==
        record?.latest_software?.software_version
    ) {
      return true;
    }

    return false;
  };

  const columns = [
    {
      render: (name: string) => {
        return (
          <>
            <div
              className="flex-space-between jfStart"
              data-testid="Fleet-Table"
            >
              <span className="upTname" data-testid={`${name}-Fleet`}>
                {name}
              </span>
            </div>
          </>
        );
      },
      title: () => {
        return (
          <>
            {t(translate.fleet.name)}
            <span className="sort-pad">
              {sortedInfo.columnKey === 'name' && (
                <img
                  className="sort-pad"
                  src={sortedData['name'] === 'ascend' ? sortUp : sortDown}
                />
              )}
            </span>
          </>
        );
      },
      defaultSortOrder: 'ascend',
      sortDirections: ['descend', 'ascend', 'descend'],
      sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
      sorter: () => false,
      dataIndex: 'name',
      key: 'name',
      width: '200px',
      showSorterTooltip: false,
      onCell: (record: any) => ({
        onClick: () => getRow(record),
      }),
    },
    {
      title: `${t(translate.fleet.status)}`,
      width: '150px',
      render: (record: any) => {
        return (
          <span
            className={getTractorStatusClass(
              record.isAvailable,
              record?.heartbeat?.drive_action_details?.drive_action_created_at,
            )}
          >
            {getStatusForTractor(
              record?.isAvailable,
              record?.heartbeat?.drive_action_details?.drive_action_created_at,
            )}
          </span>
        );
      },
      onCell: (record: any) => ({
        onClick: () => getRow(record),
      }),
    },
    {
      title: `${t(translate.fleet.health)}`,
      dataIndex: 'indicatorsData',
      key: 'connectivity_level',
      width: '150px',
      render: (indicatorsData: IndicatorsData[], record: any) => (
        <span className={`indicatorimges`}>{getIndicatorIcons(record)}</span>
      ),
      onCell: (record: any) => ({
        onClick: () =>
          push({
            pathname: RoutesConstants.FleetHealth,
            state: {
              tractorId: record.id,
              action: 'INDICATOR',
            },
          }),
      }),
    },
    {
      title: `${t(translate.baseStation.hourMeter)}`,
      dataIndex: 'hour_meter',
      key: 'hour_meter',
      width: '150px',
      showSorterTooltip: false,
      render: (hour_meter: string) => {
        return <span>{hour_meter}</span>;
      },
      onCell: (record: any) => ({
        onClick: () => getRow(record),
      }),
    },
    {
      title: `${t(translate.fleet.softwareVersion)}`,
      dataIndex: 'softwareversion',
      key: 'softwareversion',
      width: '150px',
      render: (version: string, record: any) => (
        <div className="versionList">
          <span className="vSNo vs">{version}</span>
          {privilegeChecker(privilagesConstants.Begin_an_update) &&
            version &&
            showUpdaterIcon(record) && (
              <div>
                <Popover
                  overlayClassName="softwarePopover fleetPopover"
                  content={content(record)}
                  title=""
                >
                  <img src={Alerticon} height="24px" />
                </Popover>
              </div>
            )}
        </div>
      ),
      onCell: (record: any) => ({
        onClick: () => getRow(record),
      }),
    },
    {
      title: `${t(translate.fleet.pin)}`,
      dataIndex: 'tractor_pin',
      key: 'tractor_pin',
      width: '200px',
      render: (tractor_pin: string) => {
        return <span>{tractor_pin}</span>;
      },
      onCell: (record: any) => ({
        onClick: () => getRow(record),
      }),
    },
  ];

  const wrapResult = async (
    apiUrl: string,
    ogId: number,
    pageNumber: number,
    pageSize: number,
    baseStationId: number,
    sortedInfo: string,
    search: string,
  ) => {
    const result: any = await getTractorsList(
      apiUrl,
      ogId,
      pageNumber,
      pageSize,
      baseStationId,
      sortedInfo,
      search ? search : '',
    );
    return {
      result,
      searchKey: search,
    };
  };

  const getTractors = async () => {
    try {
      if (checkActivity()) initScroller();
      setLoading(true);
      const { result, searchKey } = await wrapResult(
        userDetails.organization.api_url,
        userDetails.organization_id,
        pageNumber,
        pageSize,
        0,
        sortedInfo,
        search ? search : '',
      );

      if (searchString.current !== searchKey) return;

      const { records } = result;
      let data = Array.isArray(records) ? records : [];
      data = mapTractorsData(data);
      setTotalCount(result._metadata?.total_records_count);

      data =
        sortedInfo.order === 'ascend' && sortedInfo.columnKey === 'name'
          ? data
          : data.reverse();

      setData(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoading(false);
      if (!intialize) setIntialize(true);
    }
  };

  const getRow = (record: TractorsList) => {
    setfleetobj(record);
    setbyfleet(true);
    track('Fleet', {
      event: `${record.name} Clicked`,
    });
  };

  const Close = () => {
    setbyfleet(false);
    initClearSelection();
    loadTractors();

    track('Edit Fleet', {
      event: 'Edit Fleet Closed',
    });
  };

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    activity.current = 'sort';
    const { columnKey, order } = sorter;
    setSortedData({ ...sortedData, [columnKey]: order });
    setSortedInfo(sorter);
  };

  const handleSearch = (value: any) => {
    const searchValue = value.trim().regX;
    activity.current = 'search';
    searchString.current = searchValue;
    setInputValue(value);
    onSearchText && onSearchText(searchValue);
    if (searchValue.length <= 2) {
      searchString.current = '';
      if (search != '') {
        if (productType === 'Tractor') {
          setData([]);
        }
      }
      setSearch('');
    } else {
      searchString.current = searchValue;
      setSearch(searchValue);
    }
  };

  useEffect(() => {
    if (sortedInfo.order && intialize && productType == 'Tractor') {
      loadTractors();
    }
  }, [sortedInfo.order, pageNumber, search, productType]);

  const loadTractors = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      pageNumber !== 1 ? setPageNumber(1) : getTractors();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getTractors();
    }
  };

  const handleProduct = (value: string) => {
    setProductType(value);
    searchString.current = '';
    setInputValue('');
    setSearch('');
  };

  return (
    <Layout>
      {fleetflage && (
        <Editfleet
          fleetobj={fleetobj}
          updateFleet={(data: any) => setfleetobj(data)}
          Close={Close}
          setRefresh={() => setRefresh(!refresh)}
          fleetflage={fleetflage}
        ></Editfleet>
      )}

      <div className="mainContent">
        <Content>
          <div className="tblContainer viewportContainer">
            <Row>
              <div className="common_wrapper wWidget_wrapper">
                <div className="filters_card personnelCard">
                  <Space size="large">
                    <Input
                      addonBefore={<SearchOutlined />}
                      placeholder="Search Directory"
                      data-testid="searchFleetInputField-Fleet"
                      className="common_search"
                      onChange={(e) => handleSearch(e.target.value)}
                      autoComplete="off"
                      value={inputValue}
                    />
                  </Space>

                  <CustomSelect
                    label="Products"
                    testId="productsDropdownModel-Fleet"
                    cssClass="min_width"
                    value={productType}
                    setValue={(id: any) => {
                      if (id) {
                        handleProduct(id);
                        setbyfleet(false);
                      } else {
                        handleProduct('Tractor');
                      }
                    }}
                    options={product}
                    optionKey="value"
                    optionDisplay="name"
                  />
                </div>
                <div className="common_table">
                  {productType === 'Tractor' && (
                    <div className="cPointer">
                      <InfiniteScrollTable
                        // xScroll={400}
                        columns={columns}
                        hasMore={hasMore}
                        loading={loading}
                        filterData={alignItems(filterData, userDetails?.id)}
                        totalcount={totalCount}
                        handleLoadMore={handleLoadMore}
                        filename="Tractors"
                        onChange={handleChange}
                      />
                    </div>
                  )}
                  {productType === 'Base Station' && (
                    <BaseStation search={search} />
                  )}
                  {productType === 'Battery' && <Battery search={search} />}
                </div>
              </div>
            </Row>
          </div>
        </Content>
      </div>
    </Layout>
  );
};

export default Fleet;
