import { Button, Drawer, Select, notification } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { getSupportRoom, getRoomsHistoryList } from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import AppLoader from '../common/AppLoader';
import SupportRequest from './SupportRequest';
import { ConverseChat } from './ConverseChat';
import { SupportRoomList, selectRoomHistory } from '../../constants/types';

interface Props {
  open: boolean;
  setOpen: (data: boolean) => void;
}

const Converse: React.FC<Props> = ({ open, setOpen }) => {
  const { userDetails } = useContext(ApplicationContext);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [supportRoom, setSupportRoom] = useState<SupportRoomList>();
  const [closeChat, setCloseChat] = useState<boolean>(false);
  const [roomsList, setRoomsList] = useState<selectRoomHistory[]>([]);
  const [selectRoom, setSelectRoom] = useState<number>();

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getSupportRooms();
      getRoomsOldList();
    }
  }, [userDetails]);

  const getSupportRooms = async () => {
    try {
      setShowLoader(true);
      const list = await getSupportRoom(
        userDetails.organization.api_url,
        userDetails.organization.id,
        userDetails.id,
      );
      setSupportRoom(list);
    } catch (error: any) {
      notification.error({ message: error.message });
    } finally {
      setShowLoader(false);
    }
  };

  const getRoomsOldList = async () => {
    try {
      setShowLoader(true);
      const list = await getRoomsHistoryList(
        userDetails.organization.api_url,
        userDetails.organization.id,
        userDetails.id,
      );
      setRoomsList(list.records);
    } catch (error: any) {
      notification.error({ message: error.message });
    } finally {
      setShowLoader(false);
    }
  };

  return (
    <Drawer
      title={
        supportRoom?.room_obj ? (
          <>
            {!selectRoom && (
              <Button
                className="chatCloseBtn Button"
                onClick={() => setCloseChat(true)}
              >
                Close Conversion
              </Button>
            )}
            {/* <Select
              placeholder="Select Room"
              className="selectRoomDropDown"
              value={selectRoom}
              onSelect={(e: any) => setSelectRoom(e)}
            >
              {roomsList &&
                roomsList.map((data: any) => (
                  <option key={data.id} value={data.room_uuid}>
                    {data.message}
                  </option>
                ))}
            </Select> */}
            <div className="convoTitle">{supportRoom?.room_obj?.message}</div>
          </>
        ) : (
          <div className="startConTxt">Start the converse</div>
        )
      }
      placement="right"
      width={500}
      onClose={() => setOpen(false)}
      visible={open}
    >
      <>
        {supportRoom && supportRoom?.room_obj ? (
          <ConverseChat
            supportRoomDetails={supportRoom}
            closeChat={closeChat}
            setCloseChat={setCloseChat}
            updateChat={() => {
              setCloseChat(false);
              setOpen(false);
            }}
            selectRoom={selectRoom as number}
            setSelectRoom={setSelectRoom}
          />
        ) : (
          <>
            {!showLoader && (
              <SupportRequest getSupportRooms={getSupportRooms} />
            )}
          </>
        )}
      </>

      <AppLoader loader={showLoader} />
    </Drawer>
  );
};

export default Converse;
