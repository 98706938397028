export const SERVICE_WORKORDER_EXT_COLUMN = {
  defaults: [
    'organizationName',
    'equipmentName',
    'work_order_number',
    'tractor_issue_description',
    'status',
    'ticketId',
    'workOrderReportUrl',
  ],
  labels: {
    organizationName: 'Organization',
    equipmentName: 'Equipment',
    work_order_number: 'Work Order Number',
    tractor_issue_description: 'Tractor Issue Description',
    status: 'Status',
    ticketId: 'Ticket Id',
    workOrderReportUrl: 'Action',
  },
};

export const TRACTOR_EXT_COLUMN = {
  defaults: [
    'name',
    'serial_number',
    'tractor_pin',
    'installation_key',
    'model_name',
    'customer_organization_name',
    'startTime',
    'id',
  ],
};

export const CUSTOMER_EXT_COLUMN = {
  defaults: [
    'orgName',
    'adminEmail',
    'startTime',
    'is_resend_email_sent_status',
  ],
  labels: {
    orgName: 'Name',
    adminEmail: 'Administrator Email',
    startTime: 'Date Added',
    is_resend_email_sent_status: 'Invitation Status',
  },
};

export const SOC_DROP_ALERT_EXT_COLUMN = {
  defaults: [
    'customer_name',
    'tractor_pin',
    'tractor_name',
    'last_known_good_soc',
    'last_known_good_soc_timestamp',
    'last_received_soc',
    'last_received_soc_timestamp',
    'tractor_hours',
  ],
  labels: {
    customer_name: 'Customer Name',
    tractor_pin: 'Tractor Pin',
    tractor_name: 'Tractor Name',
    last_known_good_soc: 'Last Known Good Soc',
    last_known_good_soc_timestamp: 'Last Known Good Soc Date Time',
    last_received_soc: 'Last received Soc',
    last_received_soc_timestamp: 'Last Received Soc Date Time',
    tractor_hours: 'Tractor Hours',
    software_version: 'Software Version',
    firmware_version: 'Firmware Version',
  },
};

export const TRACTOR_USAGE_REPORT_MONTHLY_EXT_COLUMN = {
  defaults: [
    'year',
    'month',
    'kms_driven',
    'energy',
    'co2',
    'human_detections',
    'manual_hours',
    'shadow_hours',
    'row_follow_hours',
    'copycat_hours',
    'autonomy',
    'driving_hours',
    'idle_hours',
  ],
  labels: {
    year: 'Year',
    month: 'Month',
    kms_driven: 'Kms driven',
    energy: 'Energy used(KWH)',
    co2: 'CO2 saved(KG)',
    human_detections: 'Human detections',
    manual_hours: 'Manual hrs',
    shadow_hours: 'Shadow hrs',
    row_follow_hours: 'Row Follow hrs',
    copycat_hours: 'Copycat hrs',
    autonomy: 'Autonomy hrs',
    driving_hours: 'Driving hrs',
    idle_hours: 'Idle hrs',
  },
};

export const TRACTOR_USAGE_REPORT_DAILY_EXT_COLUMN = {
  defaults: [
    'customer_name',
    'tractorname',
    'tractor_pin',
    'date',
    'distance',
    'energy_used',
    'co2_saved',
    'human_detections',
    'manual_hours',
    'shadow_hours',
    'row_follow_hours',
    'copycat_hours',
    'autonomy',
    'driving_hours',
    'idle_hours',
  ],
  labels: {
    tractorname: 'Tractor Name',
    tractor_pin: 'Tractor PIN',
    distance: 'Kms driven',
    energy_used: 'Energy used(KWH)',
    co2_saved: 'CO2 saved(KG)',
    human_detections: 'Human detections',
    manual_hours: 'Manual hrs',
    shadow_hours: 'Shadow hrs',
    row_follow_hours: 'Row Follow hrs',
    copycat_hours: 'Copycat hrs',
    autonomy: 'Autonomy hrs',
    driving_hours: 'Driving hrs',
    idle_hours: 'Idle hrs',
  },
};

export const TRACTOR_USAGE_REPORT_WEEKLY_EXT_COLUMN = {
  defaults: [
    'year',
    'week',
    'kms_driven',
    'energy',
    'co2',
    'human_detections',
    'manual_hours',
    'shadow_hours',
    'row_follow_hours',
    'copycat_hours',
    'autonomy',
    'driving_hours',
    'idle_hours',
  ],
  labels: {
    year: 'Year',
    week: 'Week',
    kms_driven: 'Kms driven',
    energy: 'Energy used(KWH)',
    co2: 'CO2 saved(KG)',
    human_detections: 'Human detections',
    manual_hours: 'Manual hrs',
    shadow_hours: 'Shadow hrs',
    row_follow_hours: 'Row Follow hrs',
    copycat_hours: 'Copycat hrs',
    autonomy: 'Autonomy hrs',
    driving_hours: 'Driving hrs',
    idle_hours: 'Idle hrs',
  },
};

export const TRACTOR_USAGE_REPORT_CUMMULATIVE_EXT_COLUMN = {
  defaults: [
    'year',
    'month',
    'kms_driven',
    'energy',
    'co2',
    'human_detections',
    'manual_hours',
    'shadow_hours',
    'row_follow_hours',
    'copycat_hours',
    'autonomy',
    'driving_hours',
    'idle_hours',
  ],
  labels: {
    year: 'Year',
    week: 'Week',
    kms_driven: 'Kms driven',
    energy: 'Energy used(KWH)',
    co2: 'CO2 saved(KG)',
    human_detections: 'Human detections',
    manual_hours: 'Manual hrs',
    shadow_hours: 'Shadow hrs',
    row_follow_hours: 'Row Follow hrs',
    copycat_hours: 'Copycat hrs',
    autonomy: 'Autonomy hrs',
    driving_hours: 'Driving hrs',
    idle_hours: 'Idle hrs',
  },
};

export const LOGVIEWER_EXT_COLUMNS = {
  defaults: [
    'createTime',
    'created_by',
    'tractorName',
    'modelName',
    'status',
    'remarks',
    'actions',
  ],
  labels: {
    createTime: 'created Time',
    created_by: 'Created By',
    tractorName: 'Tractor',
    orgName: 'Organization Name',
    modelName: 'Model Name',
    dataItems: 'Data Items',
    startTime: 'Start Time',
    endTime: 'End Time',
    status: 'Status',
    remarks: 'Remarks',
    actions: 'Actions',
    csv_status: 'Csv Status',
    csv_remarks: 'Csv Remarks',
  },
};
export const GOLDBUILD_EXT_COLUMNS = {
  defaults: [
    'software_version',
    'firmware_version',
    'created_by',
    'comments',
    'remarks',
    'id',
  ],
  labels: {
    software_version: 'Software Version',
    firmware_version: 'Firmware Version',
    created_by: 'Created By',
    comments: 'Comments',
    created_date_time: 'Created Time',
    remarks: 'Release Notes',
    id: 'Action',
  },
};

export const TRACTOR_USAGE_LLC_REPORT_MONTHLY_EXT_COLUMN = {
  defaults: [
    'year',
    'month',
    'kms_driven',
    'energy',
    'co2',
    'manual_hours',
    'adas_row_follow',
    'routines_auto_shadow',
    'autonomy_hours',
    'driving_hours',
    'idle_hours',
  ],
  labels: {
    year: 'Year',
    month: 'Month',
    kms_driven: 'Kms driven',
    energy: 'Energy used(KWH)',
    co2: 'CO2 saved(KG)',
    manual_hours: 'Manual hrs',
    adas_row_follow: 'ADAS Row Follow',
    routines_auto_shadow: 'Routines Shadow AutoDrive',
    autonomy_hours: 'Autonomy hrs',
    driving_hours: 'Driving hrs',
    idle_hours: 'Idle hrs',
  },
};

export const TRACTOR_USAGE_LLC_REPORT_WEEKLY_EXT_COLUMN = {
  defaults: [
    'year',
    'week',
    'kms_driven',
    'energy',
    'co2',
    'manual_hours',
    'adas_row_follow',
    'routines_auto_shadow',
    'autonomy_hours',
    'driving_hours',
    'idle_hours',
  ],
  labels: {
    year: 'Year',
    week: 'Week',
    kms_driven: 'Kms driven',
    energy: 'Energy used(KWH)',
    co2: 'CO2 saved(KG)',
    manual_hours: 'Manual hrs',
    adas_row_follow: 'ADAS Row Follow',
    routines_auto_shadow: 'Routines Shadow AutoDrive',
    autonomy_hours: 'Autonomy hrs',
    driving_hours: 'Driving hrs',
    idle_hours: 'Idle hrs',
  },
};

export const TRACTOR_USAGE_LLC_REPORT_CUMMULATIVE_EXT_COLUMN = {
  defaults: [
    'year',
    'month',
    'kms_driven',
    'energy',
    'co2',
    'manual_hours',
    'adas_row_follow',
    'routines_auto_shadow',
    'autonomy_hours',
    'driving_hours',
    'idle_hours',
  ],
  labels: {
    kms_driven: 'Kms driven',
    energy: 'Energy used(KWH)',
    co2: 'CO2 saved(KG)',
    manual_hours: 'Manual hrs',
    adas_row_follow: 'ADAS Row Follow',
    routines_auto_shadow: 'Routines Shadow AutoDrive',
    autonomy_hours: 'Autonomy hrs',
    driving_hours: 'Driving hrs',
    idle_hours: 'Idle hrs',
  },
};

export const TRACTOR_USAGE_LLC_REPORT_DAILY_EXT_COLUMN = {
  defaults: [
    'customer_name',
    'tractorname',
    'date',
    'distance',
    'energy_used',
    'co2_saved',
    'manual_hours',
    'autonomy',
    'driving_hours',
    'idle_hours',
    'tractor_pin',
    'adas_row_follow',
    'routines',
  ],
  labels: {
    tractorname: 'Tractor Name',
    distance: 'Kms driven',
    energy_used: 'Energy used(KWH)',
    co2_saved: 'CO2 saved(KG)',
    manual_hours: 'Manual hrs',
    autonomy: 'Autonomy hrs',
    driving_hours: 'Driving hrs',
    idle_hours: 'Idle hrs',
    tractor_pin: 'Tractor PIN',
    adas_row_follow: 'ADAS Row Follow',
    routines: 'Routines Shadow AutoDrive',
  },
};

export const TRACTOR_USAGE_EXT_COLUMN = {
  defaults: [
    'customer_name',
    'date',
    'distance',
    'energy_used',
    'co2_saved',
    'human_detections',
    'manual_hours',
    'pm_follow_me_hours',
    'pm_vinerow_hours',
    'pm_copycat_hours',
    'pm_remote_av_hours',
    'autonomy',
    'pm_driving_hours',
    'pm_standby_hours',
  ],
  labels: {
    tractorname: 'Tractor Name',
    tractor_pin: 'Tractor PIN',
    customer_name: 'Customer Name',
    distance: 'Kms driven',
    clientNodeName: 'Internal Name',
    energy_used: 'Energy used(KWH)',
    co2_saved: 'CO2 saved(KG)',
    human_detections: 'Human detections',
    manual_hours: 'Manual hrs',
    pm_follow_me_hours: 'Shadow hrs',
    pm_vinerow_hours: 'Row Follow hrs',
    pm_copycat_hours: 'Seated Routines hrs',
    pm_remote_av_hours: 'Auto Drive hrs',
    autonomy: 'Autonomy hrs',
    pm_driving_hours: 'Driving hrs',
    pm_standby_hours: 'Idle hrs',
  },
};

export const TRACTOR_USAGE1_EXT_COLUMN = {
  defaults: [
    'tractorname',
    'tractor_pin',
    'customer_name',
    'date',
    'distance',
    'energy_used',
    'co2_saved',
    'human_detections',
    'manual_hours',
    'pm_follow_me_hours',
    'pm_vinerow_hours',
    'pm_copycat_hours',
    'pm_remote_av_hours',
    'autonomy',
    'pm_driving_hours',
    'pm_standby_hours',
  ],
  labels: {
    tractorname: 'Tractor Name',
    tractor_pin: 'Tractor PIN',
    customer_name: 'Customer Name',
    distance: 'Kms driven',
    clientNodeName: 'Internal Name',
    energy_used: 'Energy used(KWH)',
    co2_saved: 'CO2 saved(KG)',
    human_detections: 'Human detections',
    manual_hours: 'Manual hrs',
    pm_follow_me_hours: 'Shadow hrs',
    pm_vinerow_hours: 'Row Follow hrs',
    pm_copycat_hours: 'Seated Routines hrs',
    pm_remote_av_hours: 'Auto Drive hrs',
    autonomy: 'Autonomy hrs',
    pm_driving_hours: 'Driving hrs',
    pm_standby_hours: 'Idle hrs',
  },
};

export const BASESTATION_EXT_COLUMN = {
  defaults: [
    'name',
    'model',
    'Organization',
    'bsin',
    'installation_key',
    'startTime',
    'is_active',
  ],
};

export const BASELOGVIEWER_EXT_COLUMNS = {
  defaults: [
    'createTime',
    'created_by',
    'tractorName',
    'modelName',
    'status',
    'remarks',
    'actions',
  ],
  labels: {
    createTime: 'created Time',
    created_by: 'Created By',
    tractorName: 'Basestation',
    orgName: 'Organization Name',
    modelName: 'Model Name',
    dataItems: 'Data Items',
    startTime: 'Start Time',
    endTime: 'End Time',
    status: 'Status',
    remarks: 'Remarks',
    actions: 'Actions',
  },
};

export const BASESTATION_HEALTH_COLUMNS = {
  defaults: [
    'name',
    'customerName',
    'model',
    'software_version',
    'datetime',
    'active_network',
    'status',
    'audit',
    'is_active',
    'rtkConfigured',
    'tractorsConnected',
  ],
};

export const TICKETS_EXT_COLUMN = {
  defaults: [
    'organization',
    'equipmentName',
    'ticket_description',
    'tags',
    'ticket_priority',
    'ticket_level',
    'lastInteractedWith',
    'durationHours',
  ],
  labels: {
    ticketId: 'Ticket Id',
    organization: 'Organization',
    equipmentName: 'Equipment',
    ticket_description: 'Description',
    team: 'Team',
    assignee: 'Assignee',
    tags: 'Tags',
    ticket_priority: 'Ticket Priority',
    ticket_level: 'Severity',
    status: 'Status',
    lastInteractedWith: 'Last Interacted With',
    durationHours: 'Duration',
  },
};

export const TRACTOR_HEALTH_EXT_COLUMN = {
  defaults: [
    'tractor_pin',
    'name',
    'jng',
    'soc',
    'orgName',
    'software_version',
    'firmware_version',
    'date_time',
    'tractor_hours',
    'active_network',
  ],
  labels: {
    tractor_pin: 'Tractor PIN',
    name: 'Name',
    clientNodeName: 'Internal Name',
    serial_number: 'Serial Number',
    soc: 'SOC %',
    jng: 'Status',
    orgName: 'Customer Name',
    software_version: 'Software Version',
    firmware_version: 'Firmware Version',
    date_time: 'Date Time',
    tractor_hours: 'Tractor Hours',
    offline_time: 'Offline Time',
    active_network: 'Active Network',
    camera_calibration: 'Camera Calibration',
    camera_calibration_count: 'Camera Calibration Count',
  },
};

export const TRACTOR_MIGRATION_EXT_COLUMN = {
  defaults: [
    'name',
    'clientNodeName',
    'customer_organization_name',
    'model_name',
    'connection',
    'migrationStatus',
  ],
  labels: {
    name: 'Name',
    clientNodeName: 'Client Node Name',
    customer_organization_name: 'Current organization',
    model_name: 'Target Organization',
    connection: 'Action',
    migrationStatus: 'Last Migration Status',
  },
};

export const BASESTATION_MIGRATION_EXT_COLUMN = {
  defaults: [
    'name',
    'serial_number',
    'clientNodeName',
    'customer_organization_name',
    'model_name',
    'connection',
    'migrationStatus',
  ],
  labels: {
    name: 'Name',
    serial_number: 'Serial Number',
    clientNodeName: 'Client Node Name',
    customer_organization_name: 'Current organization',
    model_name: 'Target Organization',
    connection: 'Action',
    migrationStatus: 'Last Migration Status',
  },
};

export const BASESTATION_SURVEY_EXT_COLUMN = {
  defaults: [
    'name',
    'organization',
    'serial_number',
    'createdBy',
    'status',
    'completion',
    'start_time',
    'hours',
    'remarks',
  ],
  labels: {
    name: 'Name',
    organization: 'Organization',
    serial_number: 'Serial Number',
    createdBy: 'Created By',
    status: 'Status',
    completion: 'Completion',
    start_time: 'Start time',
    hours: 'Hours',
    remarks: 'Remarks',
    latitude: 'Latitude',
    longitude: 'Longitude',
    altitude: 'Altitude',
  },
};

const defaultColumns: string[] = [
  'name',
  'organization',
  'serialNumber',
  'createdBy',
  'status',
  'completion',
  'start_time',
  'hours',
  'remarks',
];

const tablesColumnsMap: any = {
  ServiceWorkOrder: SERVICE_WORKORDER_EXT_COLUMN,
  Tractor: TRACTOR_EXT_COLUMN,
  SocDropAlert: SOC_DROP_ALERT_EXT_COLUMN,
  TractorUsageReportMonthly: TRACTOR_USAGE_REPORT_MONTHLY_EXT_COLUMN,
  TractorUsageReportDaily: TRACTOR_USAGE_REPORT_DAILY_EXT_COLUMN,
  TractorUsageReportWeekly: TRACTOR_USAGE_REPORT_WEEKLY_EXT_COLUMN,
  TractorUsageReportCummulative: TRACTOR_USAGE_REPORT_CUMMULATIVE_EXT_COLUMN,
  LogViewer: LOGVIEWER_EXT_COLUMNS,
  BaseLogViewer: BASELOGVIEWER_EXT_COLUMNS,
  GoldBuild: GOLDBUILD_EXT_COLUMNS,
  TractorUsageLLCReportMonthly: TRACTOR_USAGE_LLC_REPORT_MONTHLY_EXT_COLUMN,
  TractorUsageLLCReportWeekly: TRACTOR_USAGE_LLC_REPORT_WEEKLY_EXT_COLUMN,
  TractorUsageLLCReportDaily: TRACTOR_USAGE_LLC_REPORT_DAILY_EXT_COLUMN,
  TractorUsageLLCReportCummulative:
    TRACTOR_USAGE_LLC_REPORT_CUMMULATIVE_EXT_COLUMN,
  TractorUsage: TRACTOR_USAGE_EXT_COLUMN,
  TractorUsage1: TRACTOR_USAGE1_EXT_COLUMN,
  Basestation: BASESTATION_EXT_COLUMN,
  BasestationHealth: BASESTATION_HEALTH_COLUMNS,
  customers: CUSTOMER_EXT_COLUMN,
  Tickets: TICKETS_EXT_COLUMN,
  TractorHealth: TRACTOR_HEALTH_EXT_COLUMN,
  TractorMigration: TRACTOR_MIGRATION_EXT_COLUMN,
  BasestationMigration: BASESTATION_MIGRATION_EXT_COLUMN,
  BaseStationSurvey: BASESTATION_SURVEY_EXT_COLUMN,
};

export default tablesColumnsMap;
