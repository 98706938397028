/* eslint-disable no-console */
import { Button, DatePicker, notification, Space, Table } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import loading_logo from '../../../assets/images/loading_logo.gif';
import sortDown from '../../../assets/images/sort_down.svg';
import sortUp from '../../../assets/images/sort_up.svg';
import { ApplicationContext } from '../../../context/AppContext';
import CustomSelect from '../../common/CustomSelect';
import { getAutoDriveUsageData } from '../api';
import { operationsUsageFilters } from '../Common';

import './style.css';

const { RangePicker } = DatePicker;

interface Props {
  handleSelectCustomer?: (selectedCustomer: any) => void;
}

const OperationsUsage: React.FC<Props> = ({ handleSelectCustomer }) => {
  const { userDetails } = useContext(ApplicationContext);
  const [filter, setFilter] = useState<string>('Daily');
  const [filterObj, setFilterObj] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    from_date: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    to_date: moment().format('YYYY-MM-DD'),
    type: 'Daily',
  });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState<any>(0);
  const [sortedData, setSortedData] = useState<any>({
    report_date: 'ascend',
  });

  const getAutoDrive = async () => {
    try {
      setLoading(true);
      let data: any = await getAutoDriveUsageData(
        userDetails.organization.api_url,
        filterObj?.from_date,
        filterObj?.to_date,
        filter.toLocaleLowerCase(),
      );
      data = data && data.length > 0 ? data : [];
      const list = data.map((record: any) => {
        return {
          ad_environment_stops: record.ad_environment_stops,
          ad_operational_stops: record.ad_operational_stops,
          ad_system_stops: record.ad_system_stops,
          autodrive_dairy_hrs: Number(record?.autodrive_dairy_hrs).toFixed(2),
          autodrive_hrs: Number(record?.autodrive_hrs).toFixed(2),
          autodrive_vineyard_hrs: Number(
            record?.autodrive_vineyard_hrs,
          ).toFixed(2),
          manual_dairy_hrs: Number(record?.manual_dairy_hrs).toFixed(2),
          manual_hrs: Number(record?.manual_hrs).toFixed(2),
          manual_vineyard_hrs: Number(record?.manual_vineyard_hrs).toFixed(2),
          report_date: record.report_date,
          row_follow_dairy_hrs: Number(record?.row_follow_dairy_hrs).toFixed(2),
          row_follow_hrs: Number(record?.row_follow_hrs).toFixed(2),
          row_follow_vineyard_hrs: Number(
            record?.row_follow_vineyard_hrs,
          ).toFixed(2),
          total_active_customers: record.total_active_customers,
          total_active_tractors: record.total_active_tractors,
        };
      });
      setData(list);
      setTotal(list.length);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleFilter = (value: string) => {
    const [from_date, to_date] = getDatesByWeek();

    if (value === 'Cumulative') setFilterObj({ ...filterObj, type: filter });
    if (value === 'Yearly') {
      setFilterObj({
        ...filterObj,
        from_date: moment(from_date).startOf('year').format('YYYY-MM-DD'),
        to_date:
          moment(to_date).endOf('year') > moment(to_date).endOf('month')
            ? moment(to_date).endOf('month').format('YYYY-MM-DD')
            : moment(to_date).endOf('year').format('YYYY-MM-DD'),
      });
    }
    if (value === 'Weekly' || value === 'Daily') {
      const [from_date, to_date] = getDatesByWeek();
      setFilterObj({ ...filterObj, from_date, to_date, type: value });
    }
    if (value === 'Monthly') {
      setFilterObj({
        ...filterObj,
        from_date: moment(from_date).startOf('month').format('YYYY-MM-DD'),
        to_date: moment(to_date).endOf('month').format('YYYY-MM-DD'),
        type: value,
      });
    }
    setFilter(value);
  };

  const disabledDates = (current: any) => {
    return (
      (current && current > moment()) ||
      (current && current <= moment('12-31-2023'))
    );
  };

  const onYealy = (date: any, dateString: string) => {
    setFilterObj({
      ...filterObj,
      year: Number(dateString),
      type: 'Yearly',
    });
  };

  const onMonthly = (date: any, dateString: string) => {
    if (date) {
      const year = date.format('YYYY');
      const month = date.format('MM');
      const from_date = moment(date).startOf('month').format('YYYY-MM-DD');
      const to_date = moment(date).endOf('month').format('YYYY-MM-DD');
      setFilterObj({
        ...filterObj,
        year: Number(year),
        month: Number(month),
        from_date,
        to_date,
        type: 'Monthly',
      });
    }
  };

  const getDatesByWeek = (weekNumber = moment().week()) => {
    const from_date = moment()
      .day('Sunday')
      .week(weekNumber)
      .format('YYYY-MM-DD');
    const to_date = moment(from_date).add('6', 'days').format('YYYY-MM-DD');
    return [from_date, to_date];
  };

  const onWeekly = (date: any) => {
    const week = moment(date).week();
    const from_date = moment(date)
      .day('Sunday')
      .week(week)
      .format('YYYY-MM-DD');
    const to_date = moment(from_date).add('6', 'days').format('YYYY-MM-DD');
    setFilterObj({
      ...filterObj,
      from_date,
      to_date,
      type: 'Weekly',
    });
  };

  const onDateRangeChange = (dates: any) => {
    const [from_date, to_date] = dates;
    setFilterObj({
      ...filterObj,
      from_date: from_date.format('YYYY-MM-DD'),
      to_date: to_date.format('YYYY-MM-DD'),
      type: 'Daily',
    });
  };

  const handleSubmit = () => {
    if (userDetails && userDetails.organization) getAutoDrive();
  };
  useEffect(() => {
    userDetails && userDetails.organization && handleSubmit();
  }, [userDetails]);

  const onRowClick = (record: any) => {
    const { from_date, to_date } = filterObj;
    const selectedCustomer: any = {
      customer_id: record.customer_id,
      customer_name: record.customer_name,
      name: record.customer_name,
      totalHours: record.driving_hours,
      fromDate: from_date,
      toDate: to_date,
      filterObj,
      filter,
    };
    handleSelectCustomer && handleSelectCustomer(selectedCustomer);
  };
  const handleChange = (pagination: any, filters: any, sorter: any) => {
    const { columnKey, order } = sorter;
    setSortedData({ ...sortedData, [columnKey]: order });
  };

  const columns: any = [
    {
      title: () => {
        return (
          <>
            Activity Date
            <span className="sort-pad">
              <img
                className="sort-pad"
                src={sortedData['report_date'] === 'ascend' ? sortUp : sortDown}
              />
            </span>
          </>
        );
      },
      sorter: (a: any, b: any) =>
        moment(a.report_date).unix() - moment(b.report_date).unix(),
      dataIndex: 'report_date',
      key: 'report_date',
      width: 90,
      headerBg: 'red',
    },
    {
      title: () => {
        return (
          <>
            Total Active Tractors
            <span className="sort-pad">
              <img
                className="sort-pad"
                src={
                  sortedData['total_active_tractors'] === 'ascend'
                    ? sortUp
                    : sortDown
                }
              />
            </span>
          </>
        );
      },
      sorter: (a: any, b: any) =>
        a.total_active_tractors - b.total_active_tractors,
      dataIndex: 'total_active_tractors',
      key: 'total_active_tractors',
      width: 70,
    },
    {
      title: () => {
        return (
          <>
            Total Active Customers
            <span className="sort-pad">
              <img
                className="sort-pad"
                src={
                  sortedData['total_active_customers'] === 'ascend'
                    ? sortUp
                    : sortDown
                }
              />
            </span>
          </>
        );
      },
      sorter: (a: any, b: any) =>
        a.total_active_customers - b.total_active_customers,
      dataIndex: 'total_active_customers',
      key: 'total_active_customers',
      width: 80,
    },
    {
      title: 'Manual',
      children: [
        {
          title: 'Hours',
          children: [
            {
              title: () => {
                return (
                  <>
                    Total
                    <span className="sort-pad">
                      <img
                        className="sort-pad"
                        src={
                          sortedData['manual_hrs'] === 'ascend'
                            ? sortUp
                            : sortDown
                        }
                      />
                    </span>
                  </>
                );
              },
              sorter: (a: any, b: any) => a.manual_hrs - b.manual_hrs,
              dataIndex: 'manual_hrs',
              key: 'manual_hrs',
              width: 60,
            },
            {
              title: () => {
                return (
                  <>
                    VT
                    <span className="sort-pad">
                      <img
                        className="sort-pad"
                        src={
                          sortedData['manual_vineyard_hrs'] === 'ascend'
                            ? sortUp
                            : sortDown
                        }
                      />
                    </span>
                  </>
                );
              },
              sorter: (a: any, b: any) =>
                a.manual_vineyard_hrs - b.manual_vineyard_hrs,
              dataIndex: 'manual_vineyard_hrs',
              key: 'manual_vineyard_hrs',
              width: 50,
            },
            {
              title: () => {
                return (
                  <>
                    Dairy
                    <span className="sort-pad">
                      <img
                        className="sort-pad"
                        src={
                          sortedData['manual_dairy_hrs'] === 'ascend'
                            ? sortUp
                            : sortDown
                        }
                      />
                    </span>
                  </>
                );
              },
              sorter: (a: any, b: any) =>
                a.manual_dairy_hrs - b.manual_dairy_hrs,
              dataIndex: 'manual_dairy_hrs',
              key: 'manual_dairy_hrs',
              width: 60,
            },
            {
              title: 'Others',
              dataIndex:
                'stops_in_the_row_object_resume_commands_from_remote_operator',
              key: 'stops_in_the_row_object_resume_commands_from_remote_operator',
              width: 50,
              render: (text: string) => (text ? text : '0.00'),
            },
          ],
        },
      ],
    },
    {
      title: '(AD) Follow Block',
      children: [
        {
          title: 'Hours',
          children: [
            {
              title: () => {
                return (
                  <>
                    Total
                    <span className="sort-pad">
                      <img
                        className="sort-pad"
                        src={
                          sortedData['autodrive_hrs'] === 'ascend'
                            ? sortUp
                            : sortDown
                        }
                      />
                    </span>
                  </>
                );
              },
              sorter: (a: any, b: any) => a.autodrive_hrs - b.autodrive_hrs,
              dataIndex: 'autodrive_hrs',
              key: 'autodrive_hrs',
              width: 60,
            },
            {
              title: () => {
                return (
                  <>
                    VT
                    <span className="sort-pad">
                      <img
                        className="sort-pad"
                        src={
                          sortedData['autodrive_vineyard_hrs'] === 'ascend'
                            ? sortUp
                            : sortDown
                        }
                      />
                    </span>
                  </>
                );
              },
              sorter: (a: any, b: any) =>
                a.autodrive_vineyard_hrs - b.autodrive_vineyard_hrs,
              dataIndex: 'autodrive_vineyard_hrs',
              key: 'autodrive_vineyard_hrs',
              width: 50,
            },
            {
              title: () => {
                return (
                  <>
                    Dairy
                    <span className="sort-pad">
                      <img
                        className="sort-pad"
                        src={
                          sortedData['autodrive_dairy_hrs'] === 'ascend'
                            ? sortUp
                            : sortDown
                        }
                      />
                    </span>
                  </>
                );
              },
              sorter: (a: any, b: any) =>
                a.autodrive_dairy_hrs - b.autodrive_dairy_hrs,
              dataIndex: 'autodrive_dairy_hrs',
              key: 'autodrive_dairy_hrs',
              width: 60,
            },
            {
              title: 'Others',
              dataIndex:
                'stops_in_the_row_object_resume_commands_from_remote_operator',
              key: 'stops_in_the_row_object_resume_commands_from_remote_operator',
              width: 50,
              render: (text: string) => (text ? text : '0.00'),
            },
          ],
        },
        {
          title: 'Total Stops',
          children: [
            {
              title: () => {
                return (
                  <>
                    System
                    <span className="sort-pad">
                      <img
                        className="sort-pad"
                        src={
                          sortedData['ad_system_stops'] === 'ascend'
                            ? sortUp
                            : sortDown
                        }
                      />
                    </span>
                  </>
                );
              },
              sorter: (a: any, b: any) => a.ad_system_stops - b.ad_system_stops,
              dataIndex: 'ad_system_stops',
              key: 'ad_system_stops',
              width: 70,
            },
            {
              title: () => {
                return (
                  <>
                    Environmental
                    <span className="sort-pad">
                      <img
                        className="sort-pad"
                        src={
                          sortedData['ad_environment_stops'] === 'ascend'
                            ? sortUp
                            : sortDown
                        }
                      />
                    </span>
                  </>
                );
              },
              dataIndex: 'ad_environment_stops',
              key: 'ad_environment_stops',
              width: 100,
            },
            {
              title: () => {
                return (
                  <>
                    Operational
                    <span className="sort-pad">
                      <img
                        className="sort-pad"
                        src={
                          sortedData['ad_operational_stops'] === 'ascend'
                            ? sortUp
                            : sortDown
                        }
                      />
                    </span>
                  </>
                );
              },
              sorter: (a: any, b: any) =>
                a.ad_operational_stops - b.ad_operational_stops,
              dataIndex: 'ad_operational_stops',
              key: 'ad_operational_stops',
              width: 90,
            },
          ],
        },
      ],
    },
    {
      title: 'Row Follow',
      children: [
        {
          title: 'Hours',
          children: [
            {
              title: () => {
                return (
                  <>
                    Total
                    <span className="sort-pad">
                      <img
                        className="sort-pad"
                        src={
                          sortedData['row_follow_hrs'] === 'ascend'
                            ? sortUp
                            : sortDown
                        }
                      />
                    </span>
                  </>
                );
              },
              sorter: (a: any, b: any) => a.row_follow_hrs - b.row_follow_hrs,
              dataIndex: 'row_follow_hrs',
              key: 'row_follow_hrs',
              width: 60,
            },
            {
              title: () => {
                return (
                  <>
                    VT
                    <span className="sort-pad">
                      <img
                        className="sort-pad"
                        src={
                          sortedData['row_follow_vineyard_hrs'] === 'ascend'
                            ? sortUp
                            : sortDown
                        }
                      />
                    </span>
                  </>
                );
              },
              sorter: (a: any, b: any) =>
                a.row_follow_vineyard_hrs - b.row_follow_vineyard_hrs,
              dataIndex: 'row_follow_vineyard_hrs',
              key: 'row_follow_vineyard_hrs',
              width: 50,
            },
            {
              title: () => {
                return (
                  <>
                    Dairy
                    <span className="sort-pad">
                      <img
                        className="sort-pad"
                        src={
                          sortedData['row_follow_dairy_hrs'] === 'ascend'
                            ? sortUp
                            : sortDown
                        }
                      />
                    </span>
                  </>
                );
              },
              sorter: (a: any, b: any) =>
                a.row_follow_dairy_hrs - b.row_follow_dairy_hrs,
              dataIndex: 'row_follow_dairy_hrs',
              key: 'row_follow_dairy_hrs',
              width: 60,
            },
            {
              title: 'Others',
              dataIndex:
                'stops_in_the_row_object_resume_commands_from_remote_operator',
              key: 'stops_in_the_row_object_resume_commands_from_remote_operator',
              width: 50,
              render: (text: string) => (text ? text : '0.00'),
            },
          ],
        },
      ],
    },
  ];
  return (
    <>
      <div className="common_wrapper">
        <div className="filters_card">
          <div>
            <Space size="large">
              <div className="ad_filters_card ad_filters_card_new">
                <div className="ad_displayFlex">
                  <CustomSelect
                    label="Filters"
                    cssClass="ad_min_width"
                    value={filter}
                    setValue={handleFilter}
                    options={operationsUsageFilters}
                    optionKey="value"
                    optionDisplay="name"
                  />
                  {filter === 'Monthly' && (
                    <DatePicker
                      allowClear={false}
                      format="MMM-YYYY"
                      value={moment(`${filterObj.year}-${filterObj.month}`)}
                      onChange={onMonthly}
                      disabledDate={disabledDates}
                      picker="month"
                    />
                  )}
                  {filter === 'Weekly' && (
                    <DatePicker
                      allowClear={false}
                      value={moment(filterObj.from_date)}
                      onChange={onWeekly}
                      disabledDate={disabledDates}
                      picker="week"
                    />
                  )}
                  {filter === 'Yearly' && (
                    <DatePicker
                      allowClear={false}
                      value={moment(`${filterObj.year}-01-01`)}
                      onChange={onYealy}
                      disabledDate={disabledDates}
                      picker="year"
                    />
                  )}
                  {filter === 'Daily' && (
                    <RangePicker
                      allowClear={false}
                      value={[
                        moment(filterObj.from_date),
                        moment(filterObj.to_date),
                      ]}
                      onChange={onDateRangeChange}
                      disabledDate={disabledDates}
                    />
                  )}
                  <Button
                    className="submitBtn"
                    onClick={handleSubmit}
                    loading={loading}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </Space>
          </div>
        </div>
        <Table
          className="autoDriveReportTbl autoDriveUsage"
          columns={columns}
          dataSource={data}
          pagination={false}
          loading={{
            indicator: (
              <img
                style={{ width: '60px', height: '36px' }}
                src={loading_logo}
              />
            ),
            spinning: loading,
          }}
          bordered
          size="middle"
          scroll={{
            x: 'calc(700px + 50%)',
            y: 240,
          }}
          onRow={(record: any, rowIndex) => {
            return {
              onClick: (event) => {
                onRowClick && onRowClick(record);
              }, // click row
            };
          }}
          onChange={handleChange}
        />
        <div className="reportTotal">
          <b>Total:</b> {total} Autodrive Operations Usage Reports
        </div>
      </div>
    </>
  );
};

export default OperationsUsage;
