import React from 'react';
import TractorUsageReportIcon from '../../../assets/images/tractor_usage_report_icon.svg';
import ReportsIcon from '../../../assets/images/reports_icon.svg';
import { useHistory } from 'react-router-dom';
import RoutesConstants from '../../../routes/RoutesConstant';

const DealerAnalyticsPage: React.FC = () => {
  const { push } = useHistory();
  return (
    <>
      <div className="cardsContainer">
        <div className="cardsHeader"></div>
        <div className="cardsContentArea">
          <div className="cardsTitle mb20">Analytics</div>
          <div className="cardsBlockParent">
            <div
              className="cardsBlock"
              onClick={() => {
                push({
                  pathname: RoutesConstants.DealerAnalyticsTractor,
                });
              }}
            >
              <div className="cardIcon cardsmtb">
                <img src={TractorUsageReportIcon} />
              </div>
              <div className="cardsInfoSec">
                <div className="cardsHeadTxt">Tractor Usage Report</div>
                <div className="cardsDesc">
                  View detailed usage reports at both organization and
                  individual tractor levels for performance and insights.{' '}
                </div>
              </div>
            </div>
            <div
              className="cardsBlock"
              onClick={() => {
                push({
                  pathname: RoutesConstants.DealerReports,
                });
              }}
            >
              <div className="cardIcon cardsmtb">
                <img src={ReportsIcon} />
              </div>
              <div className="cardsInfoSec">
                <div className="cardsHeadTxt">Reports</div>
                <div className="cardsDesc">
                  Get daily insights into fleet performance with summaries and
                  key metrics across all vehicles.{' '}
                </div>
              </div>
            </div>
            <div className="cardsBlock cdbgnone"></div>
            <div className="cardsBlock cdbgnone"></div>
            <div className="cardsBlock cdbgnone"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DealerAnalyticsPage;
