import React, { useState } from 'react';
import { Tabs } from 'antd';
import '.././style.css';
import TractorMigration from './TractorMigration';
import BasestationMigration from './BasestationMigration/BasestationMigration';

const { TabPane } = Tabs;

const Index: React.FC = () => {
  const [tabid, setTabid] = useState<string>('Tractor Migration');

  return (
    <div className="rightSideContainer">
      <Tabs
        activeKey={tabid}
        centered
        id="org-tab-Organization"
        className="common_tabs implementTab"
        onChange={(key) => {
          setTabid(key);
        }}
      >
        <TabPane
          tab="Tractor Migration"
          key="Tractor Migration"
          className="attTableHeight mBottom0"
        >
          {tabid === 'Tractor Migration' && (
            <TractorMigration></TractorMigration>
          )}
        </TabPane>
        <TabPane
          tab="Basestation Migration"
          key="Basestation Migration"
          className="attTableHeight mBottom0"
        >
          {tabid === 'Basestation Migration' && <BasestationMigration />}
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Index;
