export const TRACTORS_API = '/nexus/factory/mt-tractor';
export const TRACTORS_EDIT_API = '/nexus/factory/mt-tractor/:tractorId';
export const DEALER_TOKEN_API = '/nexus/auth/api-token';
export const LOOKUP_API = '/nexus/factory/mt_lookup';
export const BASESTATION_API = '/nexus/factory/basestation';
export const CUSTOMERS_API = '/nexus/organization/customers-list';
export const SEND_NEW_ORG_EMAIL = '/nexus/organization/send-new-org-email-test';
export const CANCEL_EMAIL_REQUEST_API = '/nexus/organization/cancel-send-email';
export const TRACTOR_MIGRATION_API = '/nexus/tractor-migrate/tractor';
export const ORG_LIST_API = '/nexus/organization/customers/dropdown';
export const ORGANIZATION_API = '/nexus/organization';
export const ORG_USAGE_REPORT_API =
  '/customer/analytics/customer-tractor-usage';
export const TRACTOR_API = '/nexus/tractor';
export const TRACTOR_USAGE_REPORT = '/customer/analytics/tractor-usage-data';
export const TICKET_SUMMARY_API = '/nexus/fleet/maintenance-tickets/summary';
export const GROUPS_LOOKUP_API = '/nexus/organization';
export const CATEGORY_LOOKUP_API = '/nexus/factory/mt_lookup';
export const GROUP_MEMBER_API = '/nexus/organization';
export const TICKETS_API = '/nexus/fleet/maintenance-tickets';
export const FLEET_HEALTH_API = '/fleet/health';
export const FLEET_HEALTH_SUMMARY = '/tractor/health-summary';
export const ORG_LOOKUP_API = '/fleet/organization/lookup';
export const TRACTOR_HEALTH_SUMMARY_API = '/tractor/health-summary';
export const TRACTOR_HEALTH_API = '/fleet/health';
export const BASESTATION_HEALTH_API = '/basestation/fleet/health';
export const BASESTATION_AUDIT_API =
  '/nexus/organization/basestation/email-audit';
export const TRACTOR_HEALTH_REPORT_API =
  '/nexus/tractor-migrate/:tractor_serial_number/tractor-health-check';
export const TRACTOR_NETWORK_CHECK_API =
  '/nexus/basestation/:basestation_serial_number/basestation-network-check';
export const SAVE_TRACTOR_MIGRATION_API =
  '/nexus/tractor-migrate/:tractor_serial_number';
export const TRACTOR_MIGRATION_LOG_DETAILS_API =
  '/nexus/tractor-migrate/audit/:tractor_org_assignment_id/details';
export const RESOLVE_TICKET_API =
  '/nexus/fleet/maintenance-tickets/:ticketId/update-status';
export const TRACTOR_LIST_API = '/nexus/tractor/all-org-tractors';
export const LOGVIEWER_LIST_API = '/nexus/tractor/log-viewer';
export const LOGEXPORT_API = '/nexus/tractor/:tractor_serial_number/log-export';
export const DOWNLOAD_LOGVIEWER_API =
  '/nexus/tractor/log-viewer/:transaction_id';
export const CSV_LOGVIEWER_API = '/nexus/tractor/create-csv/:transaction_id';
export const CONVERSION_LOGVIEWER_API =
  '/nexus/tractor/log-viewer/:transaction_id//conversion-analysis';
export const FAVMODAL_API = '/user-preferences';
export const MODALATTRIBUTE_API = '/nexus/data-attributes/model';
export const MODALTYPETTRIBUTE_API = '/nexus/data-attributes/model-name/v1';
export const CANLOG_API = '/nexus/canlog/signals';
export const CANLOGSIGNAL_API = '/nexus/canlog/signal/data';
export const BASESTATION_LOGVIEWER_API = '/basestation/log-viewer';
export const BSDETAILS_LOGVIEWER_API = '/nexus/factory/basestation';
export const BSLOGEXPORT_API = '/basestation/:tractor_serial_number/log-export';
export const CSAT_LIST_API = '/analytics/csat-report';
export const CSAT_DOWNLOAD_API = '/analytics/csat-report/download';
export const AUTODRIVE_LIST_API = '/nexus/analytics/auto-drive-report';
export const AUTODRIVE_DOWNLOAD_API =
  '/nexus/analytics/auto-drive-report/download';
export const USER_MANUAL_API = '/nexus/repairmanual/chapter';
export const USER_MANUAL_DELETE_API = '/nexus/repairmanual/chapter/:id';
export const EDIT_TOPIC_API =
  '/nexus/repairmanual/chapter/:chapterId/topic/:topicId';
export const FILEPATH_TOPIC_API =
  '/nexus/repairmanual/chapter/:chapterId/topic/:topicId/file-path/update';
export const TOPIC_LIST_API = '/nexus/repairmanual/chapter/:chapterId/topic';
export const BUILD_VERSION_LIST_API = '/nexus/fleet/build-software';
export const PUBLISH_API = '/nexus/repairmanual/chapter/:id/publish';
export const ERRORCODES_API = '/nexus/fleet/indicator-error-code';
export const ERRORCODE_DROPDOWN_API =
  '/nexus/fleet/indicator-error-code/indicator/drop-down';

export const GET_ERRORCODE_API = '/nexus/fleet/indicator-error-code';
export const GET_REPORTMANAGER_API = '/reports-all';
export const ADD_DISTRIBUTION_API = '/reports/:report_id/update-distribution';
export const GET_GENERATED_API = '/reports/:report_id/generated';
export const GET_TRACTORMIGRATION_API = '/nexus/tractor-migrate/audit';
export const GET_SERVICE_WORKORDER_API = '/nexus/fleet/work-orders';
export const GET_USERS_API = '/user/drop-down';
export const GET_PARTNERS_API = '/nexus/fleet/work-orders/parts/dropdown';
export const GET_SERVICE_WORKORDER_DETAILS_API =
  '/nexus/fleet/work-orders/:workOrderId';
export const BASESTATION_MIGRATION_API =
  '/nexus/basestation-migrate/basestation';

export const BASESTATIONHISTORY_MIGRATION_API =
  '/nexus/basestation-migrate/audit';

export const BASESTATIONHISTORYAUDIT_MIGRATION_API =
  '/nexus/basestation-migrate/audit/:id';

export const BASESTATIONHISTORYLOGS_MIGRATION_API =
  '/nexus/basestation-migrate/audit/:id/details';

export const SAVEBASESTATIONHISTORY_MIGRATION_API =
  '/nexus/basestation-migrate/:tractor_serial_number';

export const BASESTATIONHEALTHREPORT_MIGRATION_API =
  '/nexus/basestation-migrate/:tractor_serial_number/basestation-health-check';

export const FLEETINDICATORREPORT_MIGRATION_API =
  '/nexus/organization/fleet-indicator-report';
export const BASESTATION_SURVEY_API = '/nexus/basestation-survey';
export const CANCELBASESTATION_SURVEY_API =
  '/nexus/basestation-survey/:serialNumber/cancel/:id';
export const GETBASESTATION_SURVEY_API =
  '/nexus/basestation-survey/basestation';

export const BASESTATION_SURVEY_HEALTH_API =
  '/nexus/basestation-survey/basestation/:serialNumber/health-check';
export const SAVESTARTBASESTATION_SURVEY_API =
  '/nexus/basestation-survey/:serialNumber';
