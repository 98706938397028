import Modal from 'antd/lib/modal';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import moment from 'moment';

import mtLogoGreen from './images/mt_logo_green.svg';
import htich_view from '../../assets/images/htich_view.svg';
import hitch_bottom_gray from '../../assets/images/hitch_bottom_gray.svg';
import hitch_icon from '../../assets/images/hitch_icon.svg';
import { Checkbox, notification } from 'antd';
import CustomSelect from '../common/CustomSelect';
import useAnalyticsStore from './store/operationAnalytics';
import tractorAnalyticDetailsStore from './store/tractorAnalyticDetails';
import { getTractorImplements, saveImplement } from './services/api';
import { HrsMins } from './ui/helper';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import AppLoader from '../common/AppLoader';
import { fetchName, getUserName, ProgressData } from './common';
import loading_logo from './../../assets/images/loading_logo.gif';

interface Props {
  onImplementSelect: (operationType: string) => void;
  jngData: ProgressData[];
  loader: boolean;
}

const EditImplement: React.FC<any> = forwardRef((props: Props, ref) => {
  const { onImplementSelect, jngData, loader } = props;
  const { selectedDate } = useAnalyticsStore();
  const {
    selectedTractor,
    operations: driveActions,
    selectTractor,
  } = tractorAnalyticDetailsStore();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [implementsList, setImplementsList] = useState([]);
  const [selectedImplement, setSelectedImplement] = useState<any>(null);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [implementLoader, setImplementLoader] = useState(false);

  const { t } = useTranslation();

  useImperativeHandle(ref, () => ({
    showModal(state: any) {
      setIsModalOpen(state);
    },
  }));

  const allIds = useMemo(
    () => driveActions.map(({ drive_action_uuid }) => drive_action_uuid),
    [driveActions],
  );

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    reset();
  };

  const reset = () => {
    setSelectedIds([]);
    setSelectAll(false);
  };

  const onImplementSave = async (implement: string) => {
    // const driveActionUUIDs = operations.map(
    //   ({ drive_action_uuid }: any) => drive_action_uuid,
    // );
    try {
      setImplementLoader(true);
      const payload = {
        drive_action_uuids: selectedIds?.join(','),
        tractor_id: selectedTractor?.tractorId || selectedTractor?.tractor_id,
        implement_name: implement,
      };
      await saveImplement(payload);
      selectTractor({
        ...selectedTractor,
        implement,
        implement_name: implement,
        implementName: implement,
      });
      setIsModalOpen(false);
      onImplementSelect(selectedImplement?.operation_type);
      reset();
    } catch (error: any) {
      notification.error(error);
    } finally {
      setImplementLoader(false);
    }
  };

  useEffect(() => {
    const init = async () => {
      try {
        const { tractorId } = selectedTractor;
        const response = await getTractorImplements(
          selectedDate?.startOf('day')?.toDate()?.getTime(),
          selectedDate?.endOf('day')?.toDate()?.getTime(),
          tractorId,
        );
        setImplementsList(response);
        setSelectedImplement(null);
      } catch (error: any) {
        notification.error({ message: error?.message });
      }
    };
    if (selectedTractor && selectedDate) init();
  }, [selectedTractor, selectedDate]);

  const selectImplement = (name: string) => {
    const [implement] = implementsList.filter(
      (implement: any) => implement.name == name,
    );
    setSelectedImplement(implement);
  };

  useEffect(() => {
    if (implementsList?.length && selectedTractor?.implement) {
      selectImplement(selectedTractor?.implement);
    }
  }, [implementsList, selectedTractor]);

  useEffect(() => {
    return () => {
      setImplementsList([]);
      setSelectedIds([]);
    };
  }, []);

  const onSelect = (driveAction: string) => {
    setSelectAll(false);
    const fDriveActions: string[] = selectedIds.includes(driveAction)
      ? selectedIds.filter((item) => item !== driveAction)
      : [...selectedIds, driveAction];
    setSelectedIds(fDriveActions);
  };

  useEffect(() => {
    if (jngData.length) {
      const [driveAction] = jngData;
      onSelect(driveAction?.drive_action_uuid);
    }
  }, [jngData]);
  return (
    <>
      <Modal
        visible={isModalOpen}
        footer={false}
        onOk={handleOk}
        onCancel={handleCancel}
        className="edit-implement popup-common"
      >
        <div className="imp-container">
          <div className="header">
            <div className="icon-title">
              <img src={mtLogoGreen} />
              <span className="edit-implement-2">Edit Implement</span>
            </div>
            <span className="selecting-operation">
              Selecting an implement with a different operation type will move
              this operation to that category and affect any overall statistics.
            </span>
          </div>
          <div className="body">
            <div className="jngs">
              {loader ? (
                <div className="loader inheritHeight">
                  <img
                    style={{ width: '40px', height: '40px' }}
                    src={loading_logo}
                  />
                </div>
              ) : (
                jngData?.map((driveAction: any) => {
                  const { operator, operatorData } = driveAction;
                  return (
                    <div
                      className={`operator-item ${
                        selectedIds.includes(driveAction?.drive_action_uuid)
                          ? 'active'
                          : ''
                      }`}
                      key={driveAction?.drive_action_uuid}
                      onClick={() => {
                        onSelect(driveAction?.drive_action_uuid);
                      }}
                    >
                      <div className="avatar-circle">
                        <span className="op-nm">{operator}</span>
                      </div>
                      <div className="name-duration">
                        <span className="operator-name">
                          {getUserName(
                            operatorData?.first_name,
                            operatorData?.last_name,
                          )}
                        </span>
                        <span className="h-m">
                          {moment(driveAction.from).format('hh:mm A')} -{' '}
                          {moment(driveAction.to).format('hh:mm A')}
                          {/* <HrsMins
hours={operator?.operationalHours.hours}
minutes={operator?.operationalHours.minutes}
/> */}
                        </span>
                      </div>
                    </div>
                  );
                })
              )}
              {/* <div className="operator-item">
                <div className="avatar-circle">
                  <span className="op-nm">MS</span>
                </div>
                <div className="name-duration">
                  <span className="operator-name">Mike Smith</span>
                  <span className="h-m">3h 34m</span>
                </div>
              </div> */}
            </div>
            <div className="right-col">
              <div className="implement-selection">
                <div className="drop-down-filter filters_card">
                  {/* <img src={ddImg} width="323" /> */}
                  <CustomSelect
                    required
                    validations={selectedImplement?.name || undefined}
                    testId="statusDropdownField-Employees"
                    label="Implement"
                    cssClass="min_width"
                    className="implementDropdown"
                    value={selectedImplement?.name || undefined}
                    setValue={(id: any) => {
                      // activity.current = 'filter';
                      selectImplement(id);
                    }}
                    options={implementsList.map(({ name, operation_type }) => ({
                      id: name,
                      name,
                      operation_type,
                    }))}
                    optionKey="id"
                    optionDisplay="name"
                    optionDisplay2="operation_type"
                  />
                </div>
                {selectedImplement && (
                  <>
                    <div className="impBlockBg">
                      <div className="implement-info">
                        <div className="imp-ellipse">
                          <img
                            src={selectedImplement?.image_url || hitch_icon}
                          />
                        </div>
                        <div className="imp-dtl-row">
                          <span className="imp-type">
                            {selectedImplement?.name}
                          </span>
                          <span className="imp-model">
                            {selectedImplement?.model}
                          </span>
                        </div>
                        <div className="imp-dtl-row">
                          <span className="imp-type">
                            {selectedImplement?.implement_type}
                          </span>
                          <span className="imp-model">
                            {selectedImplement?.attachment_type}
                          </span>
                        </div>
                      </div>
                      <h5 className="headline5 viewImpSubHead mt24 mb8">
                        {t(translate.implementView.dimensions)}
                      </h5>
                      <div className="impBox">
                        <div className="impInrBox flexBtnCenter gap28">
                          <div className="gridCenter gap20 w50per textCenter impDimesions">
                            <div className="">
                              <h6 className="body2">
                                {t(translate.implementView.height)}
                              </h6>
                              <p>
                                {selectedImplement?.height && (
                                  <>
                                    {selectedImplement?.height}{' '}
                                    <span>
                                      {t(translate.implementView.inches)}
                                    </span>
                                  </>
                                )}
                              </p>
                            </div>
                            <img src={htich_view} alt="" />
                          </div>
                          <div className="gridCenter gap20 w50per textCenter impDimesions">
                            <div className="">
                              <h6 className="body2">
                                {t(translate.implementView.length)}
                              </h6>
                              <p>
                                {selectedImplement?.length && (
                                  <>
                                    {selectedImplement?.length}{' '}
                                    <span>
                                      {t(translate.implementView.inches)}
                                    </span>
                                  </>
                                )}
                              </p>
                            </div>
                            <img src={hitch_bottom_gray} alt="" />
                          </div>
                        </div>
                        <div className="impInrBox flexBtnCenter gap28">
                          <div className="gridCenter gap20 w50per impDimesions">
                            <h6 className="body2 textCenter">
                              {t(translate.implementView.width)}
                            </h6>
                            <p>
                              {selectedImplement?.width && (
                                <>
                                  {selectedImplement?.width}{' '}
                                  <span>
                                    {t(translate.implementView.inches)}
                                  </span>
                                </>
                              )}
                            </p>
                          </div>
                          <div className="gridCenter gap20 w50per impDimesions">
                            <h6 className="body2 textCenter">
                              {t(translate.implementView.weight)}
                            </h6>
                            <p>
                              {selectedImplement?.weight && (
                                <>
                                  {selectedImplement?.weight}{' '}
                                  <span>{t(translate.implementView.lbs)}</span>
                                </>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="checkbox">
                  <Checkbox
                    className="checkbox-lbl"
                    checked={selectAll}
                    onChange={() => {
                      setSelectAll(!selectAll);
                      setSelectedIds(!selectAll ? allIds : []);
                    }}
                  >
                    Apply changes to all drive sessions
                  </Checkbox>
                </div>
              </div>
              <button
                className="imp-button-sec"
                disabled={
                  !(Boolean(selectedIds.length) && selectedImplement?.name)
                }
                onClick={() => onImplementSave(selectedImplement?.name)}
              >
                <span className="btn-save">Save Changes</span>
              </button>
              <AppLoader loader={implementLoader} />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
});

EditImplement.displayName = 'EditImplement';

export default EditImplement;
