import React from 'react';
import ticketIcon from '../../../assets/images/ticket_icon.svg';
import migrationIcon from '../../../assets/images/migration_icon.svg';
import fllethealthIcon from '../../../assets/images/fllethealth_icon.svg';
import manualIcon from '../../../assets/images/manual_icon.svg';
import updatesIcon from '../../../assets/images/updates_icon.svg';
import diagnosticsIcon from '../../../assets/images/diagnostics_icon.svg';
import surveyIcon from '../../../assets/images/survey_icon.svg';
import { useHistory } from 'react-router-dom';
import RoutesConstants from '../../../routes/RoutesConstant';

const DealerMaintenance: React.FC = () => {
  const { push } = useHistory();
  return (
    <div className="cardsContainer">
      <div className="cardsHeader"></div>
      <div className="cardsContentArea">
        <div className="cardsTitle mb20">Maintenance</div>
        <div className="cardsBlockParent">
          <div
            className="cardsBlock"
            onClick={() => {
              push({
                pathname: RoutesConstants.DealerTickets,
              });
            }}
          >
            <div className="cardIcon cardsmtb">
              <img src={ticketIcon} />
            </div>
            <div className="cardsInfoSec">
              <div className="cardsHeadTxt">Tickets</div>
              <div className="cardsDesc">
                Manage support tickets with status, priority, alerts, and quick
                actions for easy resolution.{' '}
              </div>
            </div>
          </div>
          <div
            className="cardsBlock"
            onClick={() => {
              push({
                pathname: RoutesConstants.DealerMigrate,
              });
            }}
          >
            <div className="cardIcon cardsmtb">
              <img src={migrationIcon} />
            </div>
            <div className="cardsInfoSec">
              <div className="cardsHeadTxt">Migration</div>
              <div className="cardsDesc">
                Easily migrate tractors or basestations between organizations
                with streamlined status updates and quick actions.{' '}
              </div>
            </div>
          </div>
          <div
            className="cardsBlock"
            onClick={() => {
              push({
                pathname: RoutesConstants.DealerFleetHealth,
              });
            }}
          >
            <div className="cardIcon cardsmtb">
              <img src={fllethealthIcon} />
            </div>
            <div className="cardsInfoSec">
              <div className="cardsHeadTxt">Fleet Health</div>
              <div className="cardsDesc">
                Monitor fleet health with real-time status, alerts, and quick
                actions to maintain optimal performance.{' '}
              </div>
            </div>
          </div>
          <div
            className="cardsBlock"
            onClick={() => {
              push({
                pathname: RoutesConstants.DealerManuals,
              });
            }}
          >
            <div className="cardIcon cardsmtb">
              <img src={manualIcon} />
            </div>
            <div className="cardsInfoSec">
              <div className="cardsHeadTxt">Manuals</div>
              <div className="cardsDesc">
                Access equipment manuals quickly for troubleshooting,
                maintenance, and operational guidance.
              </div>
            </div>
          </div>
          <div
            className="cardsBlock"
            onClick={() => {
              push({
                pathname: RoutesConstants.DealerUpdates,
              });
            }}
          >
            <div className="cardIcon cardsmtb">
              <img src={updatesIcon} />
            </div>
            <div className="cardsInfoSec">
              <div className="cardsHeadTxt">Updates</div>
              <div className="cardsDesc">
                Manage over-the-air updates seamlessly for firmware and
                software, ensuring devices stay up-to-date.
              </div>
            </div>
          </div>
          <div
            className="cardsBlock"
            onClick={() => {
              push({
                pathname: RoutesConstants.DealerDiagnostics,
              });
            }}
          >
            <div className="cardIcon cardsmtb">
              <img src={diagnosticsIcon} />
            </div>
            <div className="cardsInfoSec">
              <div className="cardsHeadTxt">Diagnostics</div>
              <div className="cardsDesc">
                Access Fleet Indicator History and view error codes with
                detailed descriptions for efficient troubleshooting.{' '}
              </div>
            </div>
          </div>
          <div
            className="cardsBlock"
            onClick={() => {
              push({
                pathname: RoutesConstants.DealerSurvey,
              });
            }}
          >
            <div className="cardIcon cardsmtb">
              <img src={surveyIcon} />
            </div>
            <div className="cardsInfoSec">
              <div className="cardsHeadTxt">Survey</div>
              <div className="cardsDesc">
                Perform precise surveys to capture and record basestation
                locations for optimal positioning and performance.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealerMaintenance;
