import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Input, Row, message, notification } from 'antd';
import Layout from 'antd/lib/layout/layout';
import translate from '../../../../locale/en_translate.json';
import InfiniteScrollTable from '../../../common/InfiniteScrollTable';
import usePaginate from '../../../../hooks/usePaginate';
import { ApplicationContext } from '../../../../context/AppContext';
import {
  alignItems,
  getStatusForTractor,
  getTractorStatusClass,
  initScroller,
} from '../../../../constants/Common';
import { useTranslation } from 'react-i18next';
import '.././style.css';
import CustomSelect from '../../../common/CustomSelect';
import tractorsDown from '../../../../assets/images/tractor_down.svg';
import tractorsNotUsed from '../../../../assets/images/tractor_not_being_used.svg';
import tractorsOnline from '../../../../assets/images/tractor_online.svg';
import tractorsSemiFunctional from '../../../../assets/images/tractor_semi_functional.svg';
import totlaTractors from '../../../../assets/images/tractors.svg';
import tractorsOffline from '../../../../assets/images/tractors_offline.svg';
import copyIcon from '../../../../assets/images/copy_icon.png';
import { getTractorHealth, getTractorHealthSummary } from '../../API';
import CropOrgFilter from '../../../common/CropOrgFilter';
import { SearchOutlined } from '@ant-design/icons';
import TableExt from '../../../widget/TableExt';
import constants from '../../../../constants/constant';
import useColumns from '../../../../hooks/useColumns';
import { mapNexusTractorHealthData } from '../../../../lib/dataFormat';
import TractorHealthDetails from './TractorHealthDetails';

const { DOWNLOAD_SIZE } = constants;
const title = 'TractorHealth';
let columnsData: any[] = [];

const TractorHealth = () => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const searchString = useRef<string>('');
  const [selectedOrgId, setSelectedOrgId] = useState<string[]>([]);
  const [search, setSearch] = useState<string>('');
  const [total, setTotal] = useState<number>(0);
  const [pageSize] = useState<any>(25);
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState<any>('');
  const [connection, setConnection] = useState<any>('online');
  const [toggleColumnsStatus, setToggleColumnsStatus] =
    useState<boolean>(false);
  const { filterData, setData, activity, hasMore, checkActivity } =
    usePaginate();
  const { headers, columns, setColumns, setColumnsData } =
    useColumns('TractorHealth');
  const tableRef = useRef<any>(null);
  const [csvData, setCSVData] = useState<any[]>([]);
  const [downloadData, setDownloadData] = useState({
    isDownloaded: false,
    percent: 0,
    status: '',
  });
  const [tractorState, setTractorState] = useState<number>();
  const [disableSelect, setDisableSelect] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [tractorHealthDetails, setTractorHealthDetails] = useState<any>('');
  const init = useRef(true);
  const connectionData = [
    { name: 'Online', value: 'online' },
    { name: 'Offline', value: 'offline' },
  ];
  const wrapResult = async (
    customer_org_id: any,
    page_number: number,
    page_size: number,
    connection?: string,
    searchKey?: string,
    tractorState?: number,
    sortInfo?: any,
  ) => {
    const connectionValue =
      connection === 'online' ? '1' : connection === 'offline' ? '0' : '';
    const result = await getTractorHealth(
      customer_org_id,
      page_number,
      page_size,
      connectionValue,
      searchKey,
      tractorState,
      sortInfo,
    );
    return {
      result,
      searchKey: search,
    };
  };
  const getTractorHealthList = async (orgData: any, page_number: any) => {
    if (!userDetails?.organization || selectedOrgId.length === 0) return;
    try {
      if (checkActivity()) initScroller();
      setLoading(true);
      const { result, searchKey } = await wrapResult(
        selectedOrgId,
        page_number,
        pageSize,
        connection,
        search,
        tractorState,
      );

      if (searchString.current !== searchKey) return;
      setTotal(result?._metadata?.total_records_count);

      const { records } = result;
      const data = Array.isArray(records) ? records : [];
      const updatedData = mapNexusTractorHealthData(data, orgData);
      const tdata = alignItems(updatedData, userDetails?.id);

      setData(tdata);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      if (!init.current) init.current = false;
      setLoading(false);
    }
  };

  const loadTractorHealth = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      pageNumber !== 1
        ? setPageNumber(1)
        : getTractorHealthList(selectedOrgId, pageNumber);
    } else if (activity.current === 'paginate' || activity.current === '') {
      getTractorHealthList(selectedOrgId, pageNumber);
    }
  };

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const handleSearch = (e: any) => {
    activity.current = 'search';
    const value = e.target.value.trim();
    if (value.length <= 1) {
      searchString.current = '';
      setSearch('');
    } else {
      searchString.current = value;
      setSearch(value);
    }
  };

  useEffect(() => {
    if (init.current && selectedOrgId.length > 0) {
      loadTractorHealth();
      getHealthSummary();
      init.current = false;
    }
  }, [
    userDetails,
    pageNumber,
    search,
    selectedOrgId,
    connection,
    tractorState,
  ]);

  const getHealthSummary = async () => {
    try {
      const tractorSummary = await getTractorHealthSummary(selectedOrgId);
      setSummary(tractorSummary);
    } catch (error: any) {
      notification.error({ message: error.message });
    }
  };

  useEffect(() => {
    columnsData = [
      {
        title: t(translate.healthData.tpin),
        dataIndex: 'tractor_pin',
        key: 'tractor_pin',
        width: '200px',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),

        render: (tractor_pin: string) => (
          <span className="emailAlign hoverWrapper">
            <span className="tractorPinStyle">{tractor_pin}</span>
            <img
              src={copyIcon}
              alt="Edit"
              height="32"
              width="32"
              className="marginLeftIcon hoverShow1 on-hover-hand-change"
              onClick={() => {
                navigator.clipboard.writeText(tractor_pin);
                notification.info({ message: 'Copied' });
              }}
            />
          </span>
        ),
      },
      {
        title: t(translate.healthData.name),
        dataIndex: 'name',
        key: 'name',
        width: '195px',
        render: (name: string) => <span>{name}</span>,
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
      },
      {
        title: t(translate.healthData.InternalName),
        dataIndex: 'clientNodeName',
        key: 'clientNodeName',
        width: '150px',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
      },
      {
        title: `${t(translate.healthData.customerName)}`,
        dataIndex: 'orgName',
        key: 'orgName',
        width: '200px',
        ellipsis: true,
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
      },
      {
        title: `${t(translate.healthData.serialNumber)}`,
        dataIndex: 'serial_number',
        key: 'serial_number',
        width: '150px',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
      },
      {
        title: `${t(translate.healthData.offlineTime)}`,
        dataIndex: 'offline_time',
        key: 'offline_time',
        width: '140px',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
      },
      {
        title: `${t(translate.healthData.soc)}`,
        dataIndex: 'soc',
        key: 'soc',
        width: '120px',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
      },
      {
        title: `${t(translate.healthData.status)}`,
        dataIndex: 'jng',
        key: 'jng',
        width: '150px',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
        render: (jng: any, record: any) => {
          const { isAvailable } = record;
          return (
            <span className={getTractorStatusClass(isAvailable)}>
              {getStatusForTractor(isAvailable)}
            </span>
          );
        },
      },
      {
        title: `${t(translate.healthData.tractorHours)}`,
        dataIndex: 'tractor_hours',
        key: 'tractor_hours',
        width: '140px',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
        render: (tractor_hours: string, record: any) => (
          <span data-testid={`${tractor_hours}-${record.name}-TractorHealth`}>
            {tractor_hours === 'NaN' ? '0' : tractor_hours}
          </span>
        ),
      },
      {
        title: `${t(translate.healthData.software)}`,
        dataIndex: 'software_version',
        key: 'software_version',
        width: '150px',
        render: (software_version: string) => <>{software_version || '-'} </>,
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
      },
      {
        title: `${t(translate.healthData.firmware)}`,
        dataIndex: 'firmware_version',
        key: 'firmware_version',
        width: '150px',
        render: (firmware_version: string) => <>{firmware_version || '-'} </>,
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
      },
      {
        title: `${t(translate.healthData.activeNetwork)}`,
        dataIndex: 'active_network',
        key: 'active_network',
        width: '140px',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
      },
      {
        title: `${t(translate.healthData.camera_calibration)}`,
        dataIndex: 'camera_calibration',
        key: 'camera_calibration',
        width: '200px',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
      },
      {
        title: `${t(translate.healthData.camera_calibration_count)}`,
        dataIndex: 'camera_calibration_count',
        key: 'camera_calibration_count',
        width: '250px',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
      },
      {
        title: `${t(translate.healthData.dateAndtime)}`,
        dataIndex: 'date_time',
        key: 'date_time',
        width: '180px',
        onCell: (record: any) => ({
          onClick: () => getRow(record),
        }),
      },
    ];
    setColumnsData(columnsData);
  }, []);

  const getRow = (record: any) => {
    setTractorHealthDetails(record);
    setShowModal(true);
  };
  const handleOrg = (ids: any) => {
    activity.current = 'filter';
    setSelectedOrgId(ids);
  };
  const handleRefresh = () => {
    activity.current = 'refresh';
    loadTractorHealth();
  };

  const handleDownload = async () => {
    try {
      if (downloadData.status === 'start') return;
      setDownloadData({
        ...downloadData,
        status: 'start',
        percent: 10,
      });
      let data: any[] = [];
      const pages = Math.ceil(total / DOWNLOAD_SIZE);
      for (const page of Array.from({ length: pages }, (_, i) => i + 1)) {
        const { result } = await wrapResult(
          selectedOrgId,
          page,
          DOWNLOAD_SIZE,
          search,
          connection,
          tractorState,
        );
        const { records } = result;
        let tdata = Array.isArray(records) ? records : [];
        tdata = mapNexusTractorHealthData(tdata, selectedOrgId);
        data = [...data, ...tdata];
        setDownloadData({
          ...downloadData,
          status: 'start',
          percent: Math.round((data.length / total) * 100),
        });
      }

      setCSVData([...data]);
      setDownloadData({
        ...downloadData,
        percent: 100,
        status: 'success',
      });
    } catch (error: any) {
      setDownloadData({
        ...downloadData,
        status: 'exception',
      });
      notification.error({ message: error.message });
    }
  };
  const handleTractorState = (type: string) => {
    setConnection('');
    setDisableSelect(false);
    if (type === 'Down') {
      setTractorState(1);
    } else if (type === 'semi_functional') {
      setTractorState(2);
    } else if (type === 'rarely_used') {
      setTractorState(3);
    }
  };

  const handleTractorConnection = (type: string) => {
    setTractorState(0);
    if (type === 'online') {
      setConnection('online');
    } else if (type === 'offline') {
      setConnection('offline');
    } else {
      setConnection('');
      setDisableSelect(true);
    }
  };

  const handleSubmit = () => {
    if (userDetails && selectedOrgId.length) {
      loadTractorHealth();
      getHealthSummary();
    } else {
      message.warning('Please select Crop');
    }
  };
  return (
    <>
      <Layout onClick={() => setToggleColumnsStatus(!toggleColumnsStatus)}>
        <div className="mainContent">
          <div className="gridTabsContainer">
            <div
              className={
                connection === '' && disableSelect
                  ? 'gridItem tractorHlth gridbackground'
                  : 'gridItem tractorHlth'
              }
              onClick={() => handleTractorConnection('')}
            >
              <img src={totlaTractors} alt="total Tractors" />
              <div className="widget_content">
                <div className="assignTxt cardTitle">Tractors</div>
                <div className="assignNum">
                  {summary?.total_no_of_tractors || 0}
                </div>
              </div>
            </div>
            <div
              className={
                connection === 'online'
                  ? 'gridItem tractorHlth gridbackground'
                  : 'gridItem tractorHlth'
              }
              onClick={() => handleTractorConnection('online')}
            >
              <img src={tractorsOnline} alt="Online Tractors" />
              <div className="widget_content">
                <div className="assignTxt cardTitle">Online</div>
                <div className="assignNum">
                  {' '}
                  {summary?.no_of_online_tractors || 0}
                </div>
              </div>
            </div>
            <div
              className={
                connection === 'offline'
                  ? 'gridItem tractorHlth gridbackground'
                  : 'gridItem tractorHlth'
              }
              onClick={() => handleTractorConnection('offline')}
            >
              <img src={tractorsOffline} alt="Offline Tractors" />
              <div className="widget_content">
                <div className="assignTxt cardTitle">Offline</div>
                <div className="assignNum">
                  {' '}
                  {summary?.no_of_offline_tractors || 0}
                </div>
              </div>
            </div>
            <div
              className={
                tractorState === 1
                  ? 'gridItem tractorHlth gridbackground'
                  : 'gridItem tractorHlth'
              }
              onClick={() => handleTractorState('Down')}
            >
              <img src={tractorsDown} alt="Down Tractors" />
              <div className="widget_content">
                <div className="assignTxt cardTitle">Down</div>
                <div className="assignNum">
                  {' '}
                  {summary?.no_of_tractors_down || 0}
                </div>
              </div>
            </div>
            <div
              className={
                tractorState === 2
                  ? 'gridItem tractorHlth gridbackground'
                  : 'gridItem tractorHlth'
              }
              onClick={() => handleTractorState('semi_functional')}
            >
              <img
                src={tractorsSemiFunctional}
                alt="Tractors Semi Functional"
              />
              <div className="widget_content">
                <div className="assignTxt cardTitle">Semi Functional</div>
                <div className="assignNum">
                  {' '}
                  {summary?.no_of_tractors_semi_functional || 0}
                </div>
              </div>
            </div>
            <div
              className={
                tractorState === 3
                  ? 'gridItem tractorHlth gridbackground'
                  : 'gridItem tractorHlth'
              }
              onClick={() => handleTractorState('rarely_used')}
            >
              <img src={tractorsNotUsed} alt="Tractors Not Used" />
              <div className="widget_content">
                <div className="assignTxt cardTitle">Not Being Used</div>
                <div className="assignNum">
                  {' '}
                  {summary?.no_of_tractors_rarely_used || 0}
                </div>
              </div>
            </div>
          </div>
          <div className="tblContainer viewportContainer">
            <Row>
              <div className="common_wrapper tabCardWidget_wrapper">
                <div className="filters_card personnelCard ad_filters_card filtersHealthScreen">
                  <Input
                    addonBefore={<SearchOutlined />}
                    placeholder="Search Directory"
                    className="common_search w160"
                    onChange={(e) => handleSearch(e)}
                    autoComplete="off"
                  />

                  <CropOrgFilter handleOrgs={handleOrg} isDealer />

                  <CustomSelect
                    label="Status"
                    cssClass="min_width"
                    value={connection}
                    setValue={(id: any) => {
                      activity.current = 'filter';
                      handleTractorConnection && handleTractorConnection(id);
                    }}
                    options={connectionData}
                    optionKey="value"
                    optionDisplay="name"
                    isAll
                  />
                  <Button
                    className="submitBtn"
                    onClick={handleSubmit}
                    loading={loading}
                  >
                    Submit
                  </Button>
                  <div style={{ marginLeft: 'auto' }}>
                    <TableExt
                      handleRefresh={handleRefresh}
                      handleDownload={handleDownload}
                      downloadData={downloadData}
                      placeholder="Search"
                      csvHeaders={headers}
                      csvData={csvData}
                      csvFilename={title}
                      sortColumns={['name', 'tractor_pin']}
                      tableRef={tableRef}
                      tableName="TractorHealth"
                      handleCallback={(args: any[]) => {
                        setColumns(args);
                      }}
                      columnsData={columnsData}
                      toggleColumnsStatus={toggleColumnsStatus}
                    />
                  </div>
                </div>

                <div className="common_table fullHeight">
                  <InfiniteScrollTable
                    columns={columns}
                    loading={loading}
                    handleLoadMore={handleLoadMore}
                    hasMore={hasMore}
                    filterData={filterData}
                    totalcount={total}
                    filename={total > 0 ? 'Tractors' : 'Tractor'}
                  />
                </div>
              </div>
            </Row>
          </div>
        </div>
        {tractorHealthDetails && (
          <TractorHealthDetails
            details={tractorHealthDetails}
            onClose={() => setTractorHealthDetails(null)}
            toggleWidget={!!tractorHealthDetails}
          />
        )}
      </Layout>
    </>
  );
};

export default TractorHealth;
