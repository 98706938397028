/* eslint-disable no-console */
import React, { useContext, useEffect, useState } from 'react';
import OperationAnalytics from '../operationalAnalytics';
import NoImplementView from '../operationalAnalytics/NoImplement';
import SearchResultView from '../operationalAnalytics/index4';
// import SearchResultDtl from '../operationalAnalytics/index5';
import OperationView from '../operationalAnalytics/index1';
import TractorView from '../operationalAnalytics/index2';
import OperatorView from '../operationalAnalytics/OperatorView';
import useAnalyticStore from '../operationalAnalytics/store/operationAnalytics';
import useAnalyticDetailsStore from '../operationalAnalytics/store/operationAnalyticDetails';
import usetractorDetailsStore from '../operationalAnalytics/store/tractorAnalyticDetails';
import AnalyticDashboardCard from '../common/AnalyticsDashbordCard';
import { ApplicationContext } from '../../context/AppContext';
// import AnalyticCard from '../common/AnalyticsDashbordCard/AnalyticCard';

type OperationType = 'operationalHours';

const OperationalAnalyticsContainer: React.FC = () => {
  const { analyticDashboardData } = useContext(ApplicationContext);

  const { view, fromView, setView, reset: analyticReset } = useAnalyticStore();
  const { reset: analyticDetailsReset } = useAnalyticDetailsStore();
  const {
    reset: tractorAnalyticDetailsReset,
    selectedTractor,
    selectTractor,
  } = usetractorDetailsStore();
  const [operationType, setOperationType] = useState('');
  const [selectedTab, setSelectedTab] = useState<OperationType | string>('');

  useEffect(() => {
    return () => {
      analyticReset();
      analyticDetailsReset();
      tractorAnalyticDetailsReset();
    };
  }, []);

  return (
    <div className="opAnalyticTab">
      {view === 'tractor' && (
        <TractorView
          onBack={() => {
            setView(fromView);
          }}
          setSelectedTab={setSelectedTab}
          setOperationType={setOperationType}
        />
      )}
      {view === 'default' && (
        <>
          <OperationAnalytics
            onChangeOperation={(type: string, selectedTab: string) => {
              setOperationType(type);
              setSelectedTab(selectedTab);
            }}
          />

          {!!analyticDashboardData?.show_analytics && <AnalyticDashboardCard />}
        </>
      )}
      {view === 'operator' && (
        <OperatorView
          onBack={() => {
            setOperationType('');
            setView('default');
          }}
          setOperationType={setOperationType}
          operationType={operationType}
          selectedTab={selectedTab}
        />
      )}
      {view === 'operations' && (
        <OperationView
          onBack={() => {
            setOperationType('');
            setView('default');
          }}
          operationType={operationType}
          selectedTab={selectedTab}
        />
      )}
      {view === 'no_implement' && (
        <NoImplementView
          onBack={() => {
            setOperationType('');
            setView('default');
          }}
          operationType={operationType}
          selectedTab={selectedTab}
        />
      )}
      {view === 'search' && (
        <>
          <div style={{ display: selectedTractor ? 'none' : 'block' }}>
            <SearchResultView
              onBack={() => {
                setOperationType('');
                setView('default');
              }}
            />
          </div>
          {selectedTractor && (
            <TractorView
              onBack={() => {
                selectTractor(null);
                // setView(fromView);
              }}
              setSelectedTab={setSelectedTab}
              setOperationType={setOperationType}
            />
          )}
        </>
      )}
    </div>
  );
};

export default OperationalAnalyticsContainer;
