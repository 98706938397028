import React, { useEffect, useState } from 'react';
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';
import Amplify from 'aws-amplify';
import { BrowserView, isMobile, MobileView } from 'react-device-detect';
import './App.css';
import './components/common/latest_common.css';
import './components/common/responsive.css';
import { checkAlphaFeature } from './constants/Common';
import { WINGSPANAI } from './constants/constant';
import AppContext from './context/AppContext';
import AutoDriveContext from './context/AutoDriveContext';
import ThemeContext from './context/ThemeContext';
import './firebaseInit';
import ErrorBoundary from './routes/ErrorBoundry';
import MobileRoot from './routes/MobileRoot';
import Root from './routes/Root';
import UserManualContext from './components/dealer/maintenance/UserManual/UserManualContext';

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
  },
});

Amplify.addPluggable(
  new AWSIoTProvider({
    aws_pubsub_region: process.env.REACT_APP_REGION,
    aws_pubsub_endpoint: `wss://${process.env.REACT_APP_MQTT_ID}.iot.${process.env.REACT_APP_REGION}.amazonaws.com/mqtt`,
  }),
);

const App: React.FC = () => {
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const [isCamView, setIsCamView] = useState<boolean>(false);

  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    let link: any = document.querySelector("link[rel~='icon']");
    document.title = checkAlphaFeature(WINGSPANAI)
      ? 'WingspanAI'
      : 'Monarch Customer Portal';
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
      link.href = checkAlphaFeature(WINGSPANAI)
        ? '/favicon1.ico'
        : '/favicon.ico';
    }
  }, []);

  useEffect(() => {
    const { pathname = '' } = location;
    if (pathname) {
      const arr = pathname?.split('/');
      if (Array.isArray(arr) && arr.length) {
        if (arr[1] && arr[1] === 'CameraView') setIsCamView(true);
      }
    }
  }, [location]);

  return (
    <ErrorBoundary>
      <AppContext>
        <UserManualContext>
          <AutoDriveContext>
            <ThemeContext>
              <>
                {!isCamView && isMobile && dimensions.width < 740 && (
                  <MobileView>
                    <MobileRoot />
                  </MobileView>
                )}
                {!isCamView &&
                  isMobile &&
                  dimensions.width >= 740 &&
                  dimensions.width < 1024 && (
                    <>
                      <div className="mobile_view"></div>
                    </>
                  )}
                {isMobile && (dimensions.width >= 1024 || isCamView) && (
                  <Root />
                )}
                {!isMobile && (
                  <BrowserView>
                    <Root />
                  </BrowserView>
                )}
              </>
            </ThemeContext>
          </AutoDriveContext>
        </UserManualContext>
      </AppContext>
    </ErrorBoundary>
  );
};

export default App;
