/* eslint-disable no-console */
import { Button, Checkbox, notification } from 'antd';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import ehitch_icon from '../../assets/images/ehitch_icon.svg';
import ehitch_white_icon from '../../assets/images/ehitch_white_icon.svg';
import hitch_float_mode from '../../assets/images/hitch_float_mode.svg';
import hitch_float_mode_white from '../../assets/images/hitch_float_mode_white.svg';
import infoGreen from '../../assets/images/info_green.svg';
import pto_off_icon from '../../assets/images/pto_icon.svg';
import pto_on_icon from '../../assets/images/pto_on_icon.svg';
import {
  getimplementbyId,
  getImplementConfigById,
  getptoRpmList,
} from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';

import { GearSpeedDataType, UserDetails } from '../../constants/types';

import { isVineYardOrDairy } from '../../constants/Common';
import {
  Buffer10,
  Buffer30,
  Buffer60,
  dairyAutoDrive,
  dairySpeedList,
  handleSeederShow,
  noImplementCode,
  noImplementGlobalIMMCode,
  vineYardAutoDrive,
} from '../../constants/constant';
import { BufferTimeOut } from '../../lib/types';
import { RemoteDriveAppCtx } from '../remote_drive_new/RemoteDriveContext';
import CustomInput from './CustomInput';
import CustomSelect from './CustomSelect';
import CustomSelect3 from './CustomSelect3';
import Spinner from './Spinner';

interface Props {
  id: string;
  tractorId?: number;
  driveActionUUID?: string;
  handleDetails?: (data: any) => void;
  handleValidate?: (state: boolean) => void;
}
let gId = '';
const DriveOptionSettings: React.FC<Props> = ({
  id,
  tractorId,
  driveActionUUID,
  handleDetails,
  handleValidate,
}) => {
  const { userDetails, user } = useContext(ApplicationContext);
  const { RDReducer } = useContext(RemoteDriveAppCtx);
  const [state] = RDReducer;
  const { selectedRoutine } = state;
  const [inPtoFlag, setInPtoFlag] = useState<boolean>(false);
  const [outPtoFlag, setOutPtoFlag] = useState<boolean>(false);
  const [inRPM, setInRPM] = useState<number>(540);
  const [outRPM, setOutRPM] = useState<number>(540);
  const [inSpeed, setInSpeed] = useState<string>('');
  const [inGear, setInGear] = useState<string>('');
  const [outGear, setOutGear] = useState<string>('');
  const [outSpeed, setOutSpeed] = useState<string>('');
  const [inHitchFlag, setInHitchFlag] = useState<boolean>(false);
  const [outHitchFlag, setOutHitchFlag] = useState<boolean>(false);
  const [inHeight, setInHeight] = useState<number>(0);
  const [outHeight, setOutHeight] = useState<number>(0);
  const [ingearSpeedData, setInGearSpeedData] = useState<GearSpeedDataType[]>(
    [],
  );
  const [inPosition, setinPosition] = useState<string>('');
  const [outPosition, setoutPosition] = useState<string>('');
  const [details, setDetails] = useState<any>();
  const [savedDetails, setSavedDetails] = useState<any>();
  const [loader, setLoader] = useState<boolean>(true);
  const [inRowGearSpeed, setInRowGearSpeed] = useState<number>(0);
  const [defaultSettings, setDefaultSettings] = useState<boolean>(false);
  const [currentCapacity, setCurrentCapacity] = useState<number>(0);
  const [applicationRate, setApplicationRate] = useState<number>(0);
  const [bufferTimeout, setBufferTimeout] = useState<BufferTimeOut>(0);
  const [inRowActivationDistance, setInRowActivationDistance] =
    useState<number>();
  const [OutRowActivationDistance, setOutRowActivationDistance] =
    useState<number>();
  const [trueActionDistance, setTrueActionDistance] = useState<number>();
  const [maximumSpeed, setMaximumSpeed] = useState<any>();

  const init = useRef<number>(0);
  const rpm = useRef<number>(inRPM);

  const OnInSpeed = (text: any) => {
    init.current = 1;
    setInSpeed(text?.speed);
    setInGear(text?.gear);
    setInRowGearSpeed(text?.id);
  };

  const getGearDataByRPM = async (type: string, RPM: number) => {
    try {
      const result: GearSpeedDataType[] = await getptoRpmList(
        userDetails.organization.api_url,
        RPM,
      );
      if (result && result.length > 0) {
        const data = result.map((ele: any, i: number) => {
          ele.id = i;
          return ele;
        });
        if (rpm.current !== RPM) return;
        setInGearSpeedData(data);
      }
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      init.current = 1;
    }
  };

  useEffect(() => {
    inRPM && getGearDataByRPM('in', inRPM);
  }, [inRPM]);

  const getImplement = async (saved = false) => {
    setLoader(true);
    try {
      const result =
        saved && tractorId && driveActionUUID
          ? await getImplementConfigById(
              userDetails.organization.api_url,
              tractorId,
              driveActionUUID,
              id,
            )
          : await getimplementbyId(
              userDetails.organization.api_url,
              userDetails.organization.fleet.id,
              id,
            );
      saved && tractorId && driveActionUUID
        ? setSavedDetails(result)
        : setDetails(result);
      if (!saved) getImplement(true);
    } catch (err: any) {
      notification.error({
        message: err?.message,
      });
    } finally {
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  };
  useEffect(() => {
    gId = id;
    setTimeout(() => {
      (gId === '' || id === noImplementCode) && setLoader(false);
    }, 10000);
  }, [id]);
  useEffect(() => {
    userDetails &&
      userDetails.organization &&
      id &&
      id !== '' &&
      id !== noImplementCode &&
      getImplement();
  }, [userDetails, id]);

  useEffect(() => {
    if (details) {
      setInHeight(details?.imp_ctrl_in_row_3pt_hitch_height);
      setOutHeight(details?.imp_ctrl_out_of_row_3pt_hitch_height);
      setoutPosition(
        details?.imp_ctrl_out_of_row_3pt_hitch_mode?.trim() !== ''
          ? details?.imp_ctrl_out_of_row_3pt_hitch_mode
          : 'Position',
      );
      setinPosition(
        details?.imp_ctrl_in_row_3pt_hitch_mode?.trim() !== ''
          ? details?.imp_ctrl_in_row_3pt_hitch_mode
          : 'Position',
      );
      setOutGear(details?.imp_ctrl_in_row_gear);
      setInGear(details?.imp_ctrl_in_row_gear);
      setInSpeed(details?.imp_ctrl_in_row_tractor_speed);
      setOutSpeed(details?.imp_ctrl_in_row_tractor_speed);
      setOutRPM(details?.imp_ctrl_out_of_row_pto_rpm);
      setInRPM(details?.imp_ctrl_in_row_pto_rpm);
      rpm.current = details?.imp_ctrl_in_row_pto_rpm;
      setOutPtoFlag(details?.imp_ctrl_out_of_row_pto);
      setInPtoFlag(details?.imp_ctrl_in_row_pto);
      if (details?.imp_ctrl_in_row_3pt_hitch_mode) {
        if (details?.imp_ctrl_in_row_3pt_hitch_mode === 'Position') {
          setInHitchFlag(true);
        } else if (details?.imp_ctrl_in_row_3pt_hitch_mode === 'Float') {
          setInHitchFlag(false);
          setInHeight(0);
        }
      }
      if (details?.imp_ctrl_out_of_row_3pt_hitch_mode) {
        if (details?.imp_ctrl_out_of_row_3pt_hitch_mode === 'Position') {
          setOutHitchFlag(true);
        } else if (details?.imp_ctrl_out_of_row_3pt_hitch_mode === 'Float') {
          setOutHitchFlag(false);
          setOutHeight(0);
        }
      }
      setCurrentCapacity(details?.current_capacity);
      setApplicationRate(details?.application_rate);
      setBufferTimeout(details?.buffer_timeout || 0);
      setInRowActivationDistance(details?.in_row_activation_distance || 0);
      setOutRowActivationDistance(details?.out_row_activation_distance || 0);
      setTrueActionDistance(details?.true_activation_distance || 0);
      setMaximumSpeed(details?.max_tractor_speed || 8.0);
    }
  }, [details]);

  useEffect(() => {
    const data =
      savedDetails &&
      savedDetails.config_implements_uuid &&
      savedDetails.config_implements_uuid.length > 0
        ? savedDetails
        : null;
    init.current = 1;
    if (!data) return;
    setDetails({
      ...details,
      imp_ctrl_in_row_3pt_hitch_height:
        data.imp_ctrl_in_row_3pt_hitch_mode === 'Float'
          ? 0
          : data.imp_ctrl_in_row_3pt_hitch_height,
      imp_ctrl_out_of_row_3pt_hitch_height:
        data.imp_ctrl_out_of_row_3pt_hitch_mode === 'Float'
          ? 0
          : data.imp_ctrl_out_of_row_3pt_hitch_height,
      imp_ctrl_out_of_row_3pt_hitch_mode:
        data.imp_ctrl_out_of_row_3pt_hitch_mode,
      imp_ctrl_in_row_3pt_hitch_mode: data.imp_ctrl_in_row_3pt_hitch_mode,
      imp_ctrl_out_row_gear: data.imp_ctrl_in_row_gear,
      imp_ctrl_in_row_gear: data.imp_ctrl_in_row_gear,
      imp_ctrl_in_row_tractor_speed: data.imp_ctrl_in_row_tractor_speed,
      imp_ctrl_out_of_row_tractor_speed: data.imp_ctrl_in_row_tractor_speed,
      imp_ctrl_out_of_row_pto_rpm: data.imp_ctrl_out_of_row_pto_rpm,
      imp_ctrl_in_row_pto_rpm: data.imp_ctrl_in_row_pto_rpm,
      imp_ctrl_out_of_row_pto: data.imp_ctrl_out_of_row_pto,
      imp_ctrl_in_row_pto: data.imp_ctrl_in_row_pto,
      application_rate: data.application_rate,
      current_capacity: data.current_capacity,
      maximum_capacity: data.maximum_capacity,
      buffer_timeout: data?.buffer_timeout || 0,
      in_row_activation_distance: data.in_row_activation_distance || 0,
      out_row_activation_distance: data.out_row_activation_distance || 0,
      true_activation_distance: data.true_activation_distance || 0,
      max_tractor_speed: data.max_tractor_speed || 8.0,
    });
  }, [savedDetails]);

  useEffect(() => {
    const [fdata] = ingearSpeedData.filter((item: any) => {
      return item.speed == details?.imp_ctrl_in_row_tractor_speed;
    });
    setInRowGearSpeed(fdata?.id || 0);
  }, [details, ingearSpeedData]);

  useEffect(() => {
    if (inRowGearSpeed >= 0 && ingearSpeedData.length) {
      const tdata = ingearSpeedData[inRowGearSpeed];
      if (tdata) {
        const { speed, gear } = tdata;
        setInSpeed(String(speed));
        setInGear(gear);
        setOutSpeed(String(speed));
        setOutGear(gear);
      }
    }
  }, [inRowGearSpeed, ingearSpeedData]);

  useMemo(() => {
    const data = {
      ...details,
      imp_ctrl_in_row_3pt_hitch_height: inHeight,
      imp_ctrl_out_of_row_3pt_hitch_height: outHeight,
      imp_ctrl_out_of_row_3pt_hitch_mode: outPosition,
      imp_ctrl_in_row_3pt_hitch_mode: inPosition,
      imp_ctrl_out_row_gear: outGear,
      imp_ctrl_in_row_gear: inGear,
      imp_ctrl_in_row_tractor_speed: inSpeed,
      imp_ctrl_out_of_row_tractor_speed: outSpeed,
      imp_ctrl_out_of_row_pto_rpm: outRPM,
      imp_ctrl_in_row_pto_rpm: inRPM,
      imp_ctrl_out_of_row_pto: outPtoFlag,
      imp_ctrl_in_row_pto: inPtoFlag,
      is_global_auto_drive_implement: defaultSettings,
      application_rate: Number(applicationRate),
      current_capacity: Number(currentCapacity),
      buffer_timeout: bufferTimeout,
      in_row_activation_distance: inRowActivationDistance,
      out_row_activation_distance: OutRowActivationDistance,
      true_activation_distance: trueActionDistance,
      in_row_tractor_speed: inSpeed,
      in_row_3pt_hitch_height: inHeight,
      out_row_3pt_hitch_height: outHeight,
    };
    if (
      isVineYardOrDairy(userDetails as UserDetails) === vineYardAutoDrive &&
      selectedRoutine &&
      selectedRoutine.route_uuid &&
      user?.isSupportUser()
    ) {
      data.max_tractor_speed = maximumSpeed;
    }
    // setting gear as M2
    if (isVineYardOrDairy(userDetails as UserDetails) === dairyAutoDrive) {
      data.imp_ctrl_out_row_gear = 'M2';
      data.imp_ctrl_in_row_gear = 'M2';
    }
    if (id === noImplementCode) {
      data.config_imm = { global_imm_uuid: noImplementGlobalIMMCode };
    }
    handleDetails && handleDetails(data);
    return data;
  }, [
    inGear,
    outHeight,
    inHeight,
    inPosition,
    outPosition,
    outGear,
    inSpeed,
    outSpeed,
    outRPM,
    inRPM,
    inHitchFlag,
    outHitchFlag,
    inPtoFlag,
    outPtoFlag,
    defaultSettings,
    currentCapacity,
    applicationRate,
    bufferTimeout,
    inRowActivationDistance,
    trueActionDistance,
    OutRowActivationDistance,
    maximumSpeed,
    selectedRoutine,
    id,
    user,
  ]);

  const isEmpty = (value: any) => {
    if (typeof value === 'string' && value?.trim() === '') return true;
    if (typeof value === 'number' && isNaN(value)) return true;
    if (typeof value === 'undefined') return true;
    return false;
  };

  const isDisabled = useMemo(() => {
    console.log('maximumSpeed', maximumSpeed);
    if (
      bufferTimeout === null ||
      bufferTimeout === undefined ||
      bufferTimeout === 0
    )
      return true;
    if (id !== noImplementCode) {
      if (isEmpty(inHeight)) return true;
      if (isEmpty(outHeight)) return true;
      if (inPtoFlag && isEmpty(inRPM)) return true;
      if (outPtoFlag && isEmpty(outRPM)) return true;
    }

    if (
      isVineYardOrDairy(userDetails as UserDetails) === vineYardAutoDrive &&
      selectedRoutine &&
      selectedRoutine?.id &&
      user?.isSupportUser()
    ) {
      if (
        Number(maximumSpeed) > 10 ||
        maximumSpeed === undefined ||
        maximumSpeed === ''
      )
        return true;
    }

    return false;
  }, [
    inHeight,
    outHeight,
    inRPM,
    outRPM,
    inPtoFlag,
    outPtoFlag,
    bufferTimeout,
    maximumSpeed,
  ]);

  useEffect(() => {
    handleValidate && handleValidate(!isDisabled);
  }, [isDisabled]);
  useEffect(() => {
    if (id === noImplementCode) {
      // handleValidate && handleValidate(true);
      setLoader(false);
    }
  }, [id]);
  const handleDairySpeed = (speed: string) => {
    setInSpeed(speed);
    setOutSpeed(speed);
  };

  return (
    <Spinner loader={loader}>
      <div className="autodriveSettings widget_card">
        {isVineYardOrDairy(userDetails as UserDetails) ===
          vineYardAutoDrive && (
          <>
            {selectedRoutine?.id && user?.isSupportUser() ? (
              <div className="impFlexRow">
                <div className="impFlexLbl status2">Maximum Speed</div>
                <div className="impFlexCont">
                  <div className="impFlex3 impFlex3Bt">
                    <div className="w100Per">
                      <CustomInput
                        label="Max Speed (0.0 to 10.0)"
                        value={maximumSpeed}
                        setValue={setMaximumSpeed}
                        type="RangeForMaxSpeed"
                        showLabel="MPH"
                        required={true}
                      />
                    </div>
                    <div className=""></div>
                  </div>
                  {Number(maximumSpeed) < 0 ||
                    Number(maximumSpeed) > 10 ||
                    maximumSpeed === undefined ||
                    (maximumSpeed === '' && (
                      <div className="hintTxt hintTxtRed maxSpeedPos">
                        Max Speed (0.0 to 10.0)
                      </div>
                    ))}
                </div>
              </div>
            ) : (
              <>
                <div className="impFlexRow">
                  <div className="impFlexLbl"></div>
                  <div className="impFlexCont">
                    <div className="impFlex2">
                      <div className="operationLeftTxt">In Row</div>
                      <div className="operationLeftTxt">Out of Row</div>
                    </div>
                  </div>
                </div>

                <div className="impFlexRow">
                  <div className="impFlexLbl status2">PTO</div>
                  <div className="impFlexCont">
                    <div className="impFlex2">
                      <div className="flexA">
                        <div
                          className={inPtoFlag ? 'ptoOn' : 'ptoOff'}
                          onClick={() => {
                            !inPtoFlag && setInRPM(540);
                            setInPtoFlag(!inPtoFlag);
                          }}
                        >
                          <img
                            src={inPtoFlag ? pto_on_icon : pto_off_icon}
                            alt=""
                          />
                          <span>{inPtoFlag ? 'ON' : 'OFF'}</span>
                        </div>

                        {inPtoFlag && (
                          <CustomInput
                            label="RPM"
                            value={inRPM}
                            setValue={(value) => {
                              setInRPM(() => {
                                rpm.current = value;
                                return value;
                              });
                            }}
                            type="Number"
                          />
                        )}
                      </div>
                      <div className="flexA">
                        <div
                          className={outPtoFlag ? 'ptoOn' : 'ptoOff'}
                          onClick={() => {
                            !outPtoFlag && setOutRPM(540);
                            setOutPtoFlag(!outPtoFlag);
                          }}
                        >
                          <img
                            src={outPtoFlag ? pto_on_icon : pto_off_icon}
                            alt=""
                          />
                          <span>{outPtoFlag ? 'ON' : 'OFF'}</span>
                        </div>

                        {outPtoFlag && (
                          <CustomInput
                            label="RPM"
                            value={outRPM}
                            setValue={setOutRPM}
                            type="Number"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="impFlexRow">
                  <div className="impFlexLbl status2">Drive</div>
                  <div className="impFlexCont">
                    <div className="w100Per">
                      <CustomSelect3
                        label="Speed"
                        cssClass="w100Per msDD"
                        value={ingearSpeedData.length ? inRowGearSpeed : ''}
                        setValue={(value: any) => {
                          OnInSpeed(ingearSpeedData[value]);
                        }}
                        options={ingearSpeedData}
                        optionDisplay="speed"
                        optionDisplay2="gear"
                      />
                      <div className="hintTxt">
                        Out of row speed is controlled by Autodrive
                      </div>
                    </div>
                  </div>
                </div>
                {user?.isSupportUser() && (
                  <>
                    <div className="impFlexRow">
                      <div className="impFlexLbl status2">Activation</div>
                      <div className="impFlexCont">
                        <div className="impFlex3 impFlex3Bt">
                          <div className="w100Per">
                            <CustomInput
                              label="Entry Row Distance"
                              value={inRowActivationDistance}
                              setValue={setInRowActivationDistance}
                              type="Range"
                              showLabel="IN"
                              min={-80}
                              max={80}
                            />
                          </div>
                          <div className=""></div>
                        </div>
                        <div className="impFlex3">
                          <div className="w100Per">
                            <CustomInput
                              label="Exit Row Distance"
                              value={OutRowActivationDistance}
                              setValue={setOutRowActivationDistance}
                              type="Range"
                              showLabel="IN"
                              min={-80}
                              max={80}
                            />
                          </div>
                          <div className=""></div>
                        </div>
                      </div>
                    </div>
                    <div className="impFlexRow">
                      <div className="impFlexLbl status2">True Activation</div>
                      <div className="impFlexCont">
                        <div className="impFlex3">
                          <div className="w100Per">
                            <CustomInput
                              label="Distance"
                              value={trueActionDistance}
                              setValue={setTrueActionDistance}
                              type="Number"
                              showLabel="IN"
                            />
                          </div>
                          <div className=""></div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="impFlexRow">
                  <div className="impFlexLbl status2">Hitch</div>
                  <div className="impFlexCont">
                    <div className="impFlex3">
                      <div className="flex40">
                        <div className="">
                          <div className="hitchFlex mb20">
                            <div className="hitchIcon txtCenter">
                              <div
                                className={
                                  inHitchFlag === true
                                    ? 'hitchImg hitchImgActive'
                                    : 'hitchImg'
                                }
                                onClick={() => {
                                  setInHitchFlag(true);
                                  setinPosition('Position');
                                }}
                              >
                                <img
                                  src={
                                    inHitchFlag
                                      ? ehitch_white_icon
                                      : ehitch_icon
                                  }
                                  alt=""
                                />
                              </div>
                              <span>POSITION</span>
                            </div>
                            <div className="hitchIcon txtCenter">
                              <div
                                className={
                                  !inHitchFlag && inPosition === 'Float'
                                    ? 'hitchImg hitchImgActive'
                                    : 'hitchImg'
                                }
                                onClick={() => {
                                  setInHitchFlag(false);
                                  setinPosition('Float');
                                }}
                              >
                                <img
                                  src={
                                    !inHitchFlag && inPosition === 'Float'
                                      ? hitch_float_mode_white
                                      : hitch_float_mode
                                  }
                                  alt=""
                                />
                              </div>
                              <span>FLOAT</span>
                            </div>
                          </div>
                          {inHitchFlag && (
                            <CustomInput
                              label="Height"
                              value={inHeight}
                              showLabel="%"
                              setValue={setInHeight}
                              type="Per"
                            />
                          )}
                        </div>
                        <div className="">
                          <div className="hitchFlex mb20">
                            <div className="hitchIcon txtCenter">
                              <div
                                className={
                                  outHitchFlag === true
                                    ? 'hitchImg hitchImgActive'
                                    : 'hitchImg'
                                }
                                onClick={() => {
                                  setOutHitchFlag(true);
                                  setoutPosition('Position');
                                }}
                              >
                                <img
                                  src={
                                    outHitchFlag
                                      ? ehitch_white_icon
                                      : ehitch_icon
                                  }
                                  alt=""
                                />
                              </div>
                              <span>POSITION</span>
                            </div>
                            <div className="hitchIcon txtCenter">
                              <div
                                className={
                                  !outHitchFlag && outPosition === 'Float'
                                    ? 'hitchImg hitchImgActive'
                                    : 'hitchImg'
                                }
                                onClick={() => {
                                  setOutHitchFlag(false);
                                  setoutPosition('Float');
                                }}
                              >
                                <img
                                  src={
                                    !outHitchFlag && outPosition === 'Float'
                                      ? hitch_float_mode_white
                                      : hitch_float_mode
                                  }
                                  alt=""
                                />
                              </div>
                              <span>FLOAT</span>
                            </div>
                          </div>
                          {outHitchFlag && (
                            <CustomInput
                              label="Height"
                              value={outHeight}
                              showLabel="%"
                              setValue={setOutHeight}
                              type="Per"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {handleSeederShow(details) && (
                  <>
                    <div className="blockTxt seederScroll">
                      <p className="txtLeft mb20">Seeder Refill Stops</p>
                      <h6 className="seedTxt">
                        Let us know the seed quantity and application rate and
                        we’ll schedule stops at row entrance for refills
                      </h6>
                      <div className="seederFields">
                        <CustomInput
                          label="Seed Quantity"
                          value={currentCapacity}
                          showLabel="LB"
                          setValue={setCurrentCapacity}
                          type="Number"
                        />
                        <CustomInput
                          label="Application Rate"
                          value={applicationRate}
                          showLabel="LB / AC"
                          setValue={setApplicationRate}
                          type="Number"
                        />
                      </div>
                      <div className="infoSection">
                        <img src={infoGreen} alt="" />
                        <div>
                          <span>
                            Tractor will stop when box has 20% seed left
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
        {isVineYardOrDairy(userDetails as UserDetails) === dairyAutoDrive && (
          <>
            <div className="implement_list_sec">
              <div className="adj_op_speed_sec mb20">
                <h2 className="adj_speed">Adjust operating speed</h2>
                <CustomSelect
                  label="Speed"
                  cssClass="w100Per msDD"
                  value={inSpeed}
                  setValue={(speed) => handleDairySpeed(speed)}
                  optionKey="value"
                  options={dairySpeedList}
                  optionDisplay="name"
                />
              </div>
            </div>
          </>
        )}

        <div className="bufferBlk">
          <p className="headline3">
            Connectivity Loss<span className="hintTxtRed">*</span>
          </p>
          <h6 className="seedTxt">
            If you lose connectivity you will not be able to pause/resume or
            review safety stops from WingspanAI.
          </h6>
          <h6 className="seedTxt">
            Select how long the operation should continue without a connection
            to WingspanAI.
          </h6>
          <div className="bufferFlex">
            <Button
              className={`${bufferTimeout === Buffer10 ? 'selected' : ''}`}
              onClick={() => setBufferTimeout(Buffer10)}
            >
              Cautious <span>{Buffer10}s</span>
            </Button>
            <Button
              className={`${bufferTimeout === Buffer30 ? 'selected' : ''}`}
              onClick={() => setBufferTimeout(Buffer30)}
            >
              Moderate <span>{Buffer30}s</span>
            </Button>
            <Button
              className={`${bufferTimeout === Buffer60 ? 'selected' : ''}`}
              onClick={() => setBufferTimeout(Buffer60)}
            >
              Relaxed <span>{Buffer60}s</span>
            </Button>
          </div>
          {bufferTimeout !== Buffer10 &&
            bufferTimeout !== Buffer30 &&
            bufferTimeout !== Buffer60 && (
              <div className="hintTxt hintTxtRed">
                Please select Connectivity Buffer
              </div>
            )}
          {bufferTimeout === Buffer10 && (
            <div className="infoSection">
              <img src={infoGreen} alt="" />
              <div>
                <div>
                  If connectivity isn&apos;t restored within the chosen time,
                  the tractor will stop. Collision avoidance remains active even
                  without a connection.
                </div>
              </div>
            </div>
          )}
          {bufferTimeout === Buffer30 && (
            <div className="infoSection">
              <img src={infoGreen} alt="" />
              <div>
                <div>
                  If connectivity isn&apos;t restored within the chosen time,
                  the tractor will stop. Collision avoidance remains active even
                  without a connection.
                </div>
              </div>
            </div>
          )}
          {bufferTimeout === Buffer60 && (
            <div className="infoSection">
              <img src={infoGreen} alt="" />
              <div>
                <div>
                  If connectivity isn&apos;t restored within the chosen time,
                  the tractor will stop. Collision avoidance remains active even
                  without a connection.
                </div>
              </div>
            </div>
          )}
        </div>
        {isVineYardOrDairy(userDetails as UserDetails) ===
          vineYardAutoDrive && (
          <div className="save_settings_chk">
            <div className="checkBoxItem3">
              <Checkbox
                checked={defaultSettings}
                onChange={(e) => {
                  setDefaultSettings(e.target.checked);
                }}
                className="default-parameter"
              >
                Save settings as default
              </Checkbox>
            </div>
          </div>
        )}
      </div>
    </Spinner>
  );
};

export default DriveOptionSettings;
