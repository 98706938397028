import React, { useState } from 'react';
import { Tabs } from 'antd';
import DealerTractors from './Tractors';
import Customers from './Customers';
import BaseStation from './BaseStation';

const { TabPane } = Tabs;

const Inventory: React.FC = () => {
  const [tabid, setTabid] = useState<string>('tractors');

  return (
    <div className="rightSideContainer">
      <Tabs
        activeKey={tabid}
        centered
        id="org-tab-Organization"
        className="common_tabs implementTab"
        onChange={(key) => {
          setTabid(key);
        }}
      >
        <TabPane
          tab="Tractors"
          key="tractors"
          className="attTableHeight mBottom0"
        >
          {tabid === 'tractors' && <DealerTractors />}
        </TabPane>
        <TabPane
          tab="Customers"
          key="customers"
          className="attTableHeight mBottom0"
        >
          {tabid === 'customers' && <Customers />}
        </TabPane>
        <TabPane
          tab="Basestations"
          key="basestation"
          className="attTableHeight mBottom0"
        >
          {tabid === 'basestation' && <BaseStation />}
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Inventory;
