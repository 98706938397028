import {
  Button,
  Input,
  Modal,
  notification,
  Select,
  Spin,
  ConfigProvider,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import translate from '../../../../locale/en_translate.json';
import {
  repairLocationData,
  repairTypeData,
  resolvedByData,
  travelRequiredData,
  warrantyRepairData,
} from '../../../../constants/constant';
import LaborAndPartsList from '.././serviceWorkOrder/LaborAndPartsList';
import { getWorkOrderDetails, resolveTicketSync } from '../../API';

const { Option } = Select;

interface Props {
  showModal: boolean;
  closeModal: () => void;
  selectedTicketId: number;
  getTicketDetails: () => void;
  dataList: any;
}
const { TextArea } = Input;
const ResolvedModal: React.FC<Props> = ({
  showModal,
  closeModal,
  selectedTicketId,
  getTicketDetails,
  dataList,
}: Props) => {
  const { t } = useTranslation();
  const [loader, setLoader] = useState<boolean>(false);
  const [ticketDescription, setTicketDescription] = useState<string>('');
  const [orderDetails, setOrderDetails] = useState<any>();
  const [resolutionType, setResolutionType] = useState<string>('');
  const [resolutionDescription, setResolutionDescription] =
    useState<string>('');
  const [resolvedby, setResolvedby] = useState<string>('');
  const [repairLocation, setRepairLocation] = useState<string>('');
  const [travelRequired, setTravelRequired] = useState<string>('');
  const [repairType, setRepairType] = useState<string>('');
  const [WarrantyRepair, setWarrantyRepair] = useState<string>('');
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const resolutionTypes = [
    {
      value: t(translate.resolution_types.engQualityOrManufacuture),
      name: t(translate.resolution_types.engQualityOrManufacuture),
    },
    {
      value: t(translate.resolution_types.softwareOrFirmwareResolved),
      name: t(translate.resolution_types.softwareOrFirmwareResolved),
    },
    {
      value: t(translate.resolution_types.physicalServiceRequired),
      name: t(translate.resolution_types.physicalServiceRequired),
    },
    {
      value: t(translate.resolution_types.customerEduTrainingRequired),
      name: t(translate.resolution_types.customerEduTrainingRequired),
    },
    {
      value: t(translate.resolution_types.customerRequired),
      name: t(translate.resolution_types.customerRequired),
    },
    {
      value: t(translate.resolution_types.fleetwideIssue),
      name: t(translate.resolution_types.fleetwideIssue),
    },
    {
      value: t(translate.resolution_types.testTickets),
      name: t(translate.resolution_types.testTickets),
    },
    {
      value: t(translate.resolution_types.other),
      name: t(translate.resolution_types.other),
    },
  ];

  useEffect(() => {
    if (
      ticketDescription &&
      ticketDescription.trim().length > 0 &&
      resolutionType &&
      resolutionType.trim().length > 0 &&
      resolvedby &&
      resolvedby.trim().length > 0 &&
      repairLocation &&
      repairLocation.trim().length > 0 &&
      travelRequired &&
      travelRequired.trim().length > 0 &&
      repairType &&
      repairType.trim().length > 0 &&
      WarrantyRepair &&
      WarrantyRepair.trim().length > 0 &&
      resolutionDescription &&
      resolutionDescription.trim().length > 0
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [
    ticketDescription,
    resolutionType,
    resolutionDescription,
    resolvedby,
    repairLocation,
    travelRequired,
    repairType,
    WarrantyRepair,
  ]);

  const resolveTicket = async () => {
    const payload = {
      ticket_status: 'completed',
      reason_message: ticketDescription,
      resolution_type: resolutionType,
      resolution_description: resolutionDescription,
      resolved_by: resolvedby,
      repair_type: repairType,
      repair_location: repairLocation,
      travel_required: travelRequired === 'true',
      warranty_repair: WarrantyRepair === 'true',
    };
    try {
      setLoader(true);
      const data = await resolveTicketSync(selectedTicketId, payload);
      closeModal();
      getTicketDetails();
      notification.success({
        message: data.msg,
      });
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    const orderList = async () => {
      try {
        setLoader(true);
        const order = await getWorkOrderDetails(dataList?.work_order_id);
        setOrderDetails(order);
      } catch (error: any) {
        notification.error({
          message: error.message,
        });
      } finally {
        setLoader(false);
      }
    };
    if (dataList?.work_order_id) orderList();
  }, [dataList]);

  const selectResolution = (value: string) => {
    setResolutionType(value);
  };
  const selectResolvedby = (value: string) => {
    setResolvedby(value);
  };
  const selectRepairLocation = (value: string) => {
    setRepairLocation(value);
  };
  const selectTravelRequired = (value: string) => {
    setTravelRequired(value);
  };
  const selectRepairType = (value: string) => {
    setRepairType(value);
  };

  const selectWarrantyRepair = (value: string) => {
    setWarrantyRepair(value);
  };

  return (
    <Modal
      visible={showModal}
      onCancel={closeModal}
      width={1200}
      bodyStyle={{ height: 'auto' }}
      footer={false}
      className={
        dataList?.work_order_id
          ? 'customModal statusModal ticketModel suptTcktModal'
          : 'opPopup statusModal ticketModel suptTcktModal'
      }
    >
      <>
        {' '}
        <h3>{t(translate.supportTicket.areYouSure)}</h3>
        <p>{t(translate.supportTicket.resoveThisTicket)}</p>
        <p>{t(translate.supportTicket.unDone)}</p>
      </>
      <ConfigProvider
        getPopupContainer={(triggerNode: any) => triggerNode?.parentNode}
      >
        <div className="mt15">
          <p className="descLabel">
            {t(translate.supportTicket.resolutionType)}
            <span style={{ color: 'red' }}> *</span>
          </p>
          <Select
            style={{ width: '100%' }}
            placeholder="Resolution Type"
            onChange={selectResolution}
          >
            {resolutionTypes.map(({ name, value }) => (
              <Option key={value} value={value}>
                {name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="mt15">
          <p className="descLabel">
            {t(translate.supportTicket.resolvedBy)}
            <span style={{ color: 'red' }}> *</span>
          </p>
          <Select
            style={{ width: '100%' }}
            placeholder="Resolved By"
            onChange={selectResolvedby}
          >
            {resolvedByData.map(({ name, value }) => (
              <Option key={value} value={value}>
                {name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="dFlex">
          <div className="mt15 width50">
            <p className="descLabel">
              {t(translate.supportTicket.repairType)}
              <span style={{ color: 'red' }}> *</span>
            </p>
            <Select
              style={{ width: '100%' }}
              placeholder="Repair Type"
              onChange={selectRepairType}
            >
              {repairTypeData.map(({ name, value }) => (
                <Option key={value} value={value}>
                  {name}
                </Option>
              ))}
            </Select>
          </div>
          <div className="mt15 width50">
            <p className="descLabel">
              {t(translate.supportTicket.repairLocation)}
              <span style={{ color: 'red' }}> *</span>
            </p>
            <Select
              style={{ width: '100%' }}
              placeholder="Repair Location"
              onChange={selectRepairLocation}
            >
              {repairLocationData.map(({ name, value }) => (
                <Option key={value} value={value}>
                  {name}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        <div className="dFlex">
          <div className="mt15 width50">
            <p className="descLabel">
              {t(translate.supportTicket.travelRequired)}
              <span style={{ color: 'red' }}> *</span>
            </p>
            <Select
              style={{ width: '100%' }}
              placeholder="Travel Required"
              onChange={selectTravelRequired}
            >
              {travelRequiredData.map(({ name, value }) => (
                <Option key={value} value={value}>
                  {name}
                </Option>
              ))}
            </Select>
          </div>
          <div className="mt15 width50">
            <p className="descLabel">
              {t(translate.supportTicket.warrantyRepair)}
              <span style={{ color: 'red' }}> *</span>
            </p>
            <Select
              style={{ width: '100%' }}
              placeholder="Warranty Repair"
              onChange={selectWarrantyRepair}
            >
              {warrantyRepairData.map(({ name, value }) => (
                <Option key={value} value={value}>
                  {name}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        <div className="mt15">
          <p className="descLabel">
            {t(translate.supportTicket.resolutionDescription)}
            <span style={{ color: 'red' }}> *</span>
          </p>
          <TextArea
            rows={4}
            placeholder="Enter description here"
            maxLength={500}
            showCount
            onChange={(e) => setResolutionDescription(e.target.value)}
          />
        </div>
        <div className="mt15">
          <p className="descLabel">
            {t(translate.supportTicket.enterDescription)}
            <span style={{ color: 'red' }}> *</span>
          </p>
          <TextArea
            rows={4}
            placeholder="Enter description here"
            maxLength={500}
            showCount
            onChange={(e) => setTicketDescription(e.target.value)}
          />
        </div>
      </ConfigProvider>
      {dataList?.work_order_id && orderDetails && (
        <LaborAndPartsList
          closeModal={closeModal}
          workOrderId={dataList?.work_order_id}
          ticketDescription={ticketDescription}
          resolveTicket={resolveTicket}
          orderDetails={orderDetails}
          disabled={isDisabled}
        />
      )}
      {!dataList?.work_order_id && (
        <div className="forwardTicketButtons supportTcktBtns">
          <Button className="canelBtn" onClick={closeModal}>
            {t(translate.buttons.canCel)}
          </Button>

          <Button
            disabled={isDisabled}
            className="tcktResolveBtn"
            onClick={resolveTicket}
          >
            {t(translate.serviceticket.resolved)}
          </Button>
        </div>
      )}
      <div className="edit-loader">
        {loader && (
          <div className="loader1">
            <Spin size="large" />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ResolvedModal;
