import React, { useContext, useEffect, useState } from 'react';
import { DatePicker, Form, Modal, Space, Table, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import type { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { ApplicationContext } from '../../../../context/AppContext';
import translate from '../../../../locale/en_translate.json';
import { fromToDateWrapper } from '../../../../constants/Common';
import { getAudit } from '../../API';

const { RangePicker } = DatePicker;

interface Props {
  onClose: () => void;
  baseStationHealthDetails: any;
}
const BaseAudit: React.FC<Props> = ({ onClose, baseStationHealthDetails }) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [loader, setLoader] = useState<boolean>(false);
  const [auditData, setAuditData] = useState<any>([]);
  const [fromtime, setfromTime] = useState<any>(
    moment().subtract(30, 'day').toDate(),
  );
  const [totime, settoTime] = useState<any>(moment().toDate());

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getemailaudit();
    }
    return () => {
      setAuditData([]);
      setLoader(false);
    };
  }, [userDetails, fromtime, totime]);
  const getemailaudit = async () => {
    try {
      setLoader(true);
      const data = {
        bs_serial_number: baseStationHealthDetails?.serial_number,
        start_date: fromtime ? moment(fromtime).format('YYYY-MM-DD') : '',
        end_date: totime ? moment(totime).format('YYYY-MM-DD') : '',
      };

      let records: any = await getAudit(data);
      records = records.records.map((record: any) => {
        record.sbc_status = record.meta_data.sbc_status;
        record.router_status = record.meta_data.router_status;
        record.remarks = record.meta_data.remarks;
        record.log_date = moment(record.log_date).format('YYYY-MM-DD-HH-00-00');

        return record;
      });
      setAuditData(records);
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setLoader(false);
    }
  };
  const columns: ColumnsType<any> = [
    {
      title: `${t(translate.audit.sbcstatus)}`,
      dataIndex: 'sbc_status',
    },
    {
      title: `${t(translate.audit.routerstatus)}`,
      dataIndex: 'router_status',
    },
    {
      title: `${t(translate.audit.remarks)}`,
      dataIndex: 'remarks',
    },
    {
      title: `${t(translate.audit.email)}`,
      dataIndex: 'user_email',
    },
    {
      title: `${t(translate.audit.lagDate)}`,
      dataIndex: 'log_date',
    },
  ];

  const onDateRangeChange = (dates: any) => {
    setAuditData([]);
    if (dates) {
      const [from, to] = fromToDateWrapper(dates);
      setfromTime(from.toDate());
      settoTime(to.toDate());
    } else {
      setfromTime(null);
      settoTime(null);
    }
  };

  return (
    <>
      <Modal
        title={t(translate.healthData.audit)}
        visible
        onOk={() => onClose()}
        onCancel={() => onClose()}
        width={1000}
        okButtonProps={{
          className: 'displaynone',
        }}
        className="errorCodeModal"
      >
        <div>
          <div style={{ marginBottom: 16 }}>
            <Space>
              <Form.Item>
                <RangePicker
                  format="MM-DD-YYYY"
                  style={{ width: '250px' }}
                  onChange={(dates: any) => {
                    onDateRangeChange(dates);
                  }}
                  defaultValue={[moment(fromtime), moment(totime)]}
                />
              </Form.Item>
            </Space>
          </div>
          <Table columns={columns} dataSource={auditData} loading={loader} />
        </div>
      </Modal>
    </>
  );
};

export default BaseAudit;
