/* eslint-disable no-console */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import './css/styles.scss';
import './css/global.scss';
import { Col, Row, Space, Table, Tabs, Tooltip } from 'antd';
import user_fill from './images/user-fill-1.svg';
import trip_outline from './images/trip_outline-01.svg';
import { ColumnsType } from 'antd/lib/table';
import howtoAnalytics from './images/howto-analytics.svg';
import opSavings from './images/opa_savings.svg';
import distancenimp from './images/distancenimp.svg';
import avgSpeednimp from './images/avgSpeednimp.svg';

import { ApplicationContext } from '../../context/AppContext';
import useAnalyticsDetails from './hooks/useAnalyticsDetails';
import StackedBarChart from '../charts/StackedBarChart';
import useOperationalAnalyticDetailsStore from './store/operationAnalyticDetails';
import { isEmpty, fetchName, getHoursAndMinutes } from './common';
import useAnalytics from './hooks/useAnalytics';
import tractorAnalyticDetailsStore from './store/tractorAnalyticDetails';
import useOperationAnalyticsStore from './store/operationAnalytics';
import useOperationAnalyticsDetailsStore from './store/operationAnalyticDetails';
import moment from 'moment';
import { HrsMins, WeekendsFilter } from './ui/helper';
import OperationalCoverageMap from './OperationalCoverageMap/OperationalCoverageMap';
import Spinner from '../common/Spinner';
import CDateRangePicker from './ui/CDateRangePicker';
import { ChartSkeleton } from '../common/Skeleton';
import { unSelectedType } from './constants';

const { TabPane } = Tabs;

interface Props {
  onBack: () => void;
  operationType: string;
  selectedTab: string;
}

const NoImplement: React.FC<Props> = ({
  onBack,
  operationType,
  selectedTab,
}: Props) => {
  const [tabid, setTabid] = useState('1');
  const { completedWork, addCompletedWorkData } =
    useOperationalAnalyticDetailsStore();
  const {
    includeWeekends,
    dateRange,
    toggleWeekends,
    setDateRange,
    setView,
    setSelectedDate,
    setFromView,
    view,
  } = useOperationAnalyticsStore();

  const { selectTractor } = tractorAnalyticDetailsStore();
  const {
    noImplementSummary,
    operationalHours,
    coverage,
    operationalHoursByOperations,
    coverageByOperations,
  } = useOperationAnalyticsDetailsStore();
  const {
    operationsLoader,
    completedWorkLoader,
    initCompletedWorkData,
    initImplementTypeEfficiencyData,
    initOperationalHoursAndCoverageData,
  } = useAnalyticsDetails();
  const { dateRangeText } = useAnalytics();
  const { userDetails } = useContext(ApplicationContext);
  const [cardSize, setCardSize] = useState({ width: 0, height: 0 });
  const cardRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setTabid(selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    if (userDetails?.organization) {
      const [fromDate, toDate] = dateRange;
      const startDate = fromDate?.startOf('day')?.toDate()?.getTime();
      const endDate = toDate?.endOf('day')?.toDate()?.getTime();
      initCompletedWorkData(operationType, startDate, endDate);
      initOperationalHoursAndCoverageData(startDate, endDate, operationType);
      initImplementTypeEfficiencyData(startDate, endDate, operationType);
    }
    return () => {
      addCompletedWorkData([]);
    };
  }, [userDetails, dateRange]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setCardSize({
          width: entry.contentRect.width,
          height: entry.contentRect.height,
        });
      }
    });

    if (cardRef.current) {
      resizeObserver.observe(cardRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const operatorsBlock = useCallback((operators = []) => {
    return operators?.slice(0, 3).map((operator: any, key: number) => {
      const { first_name, last_name, id } = operator;
      return (
        <div key={id} className={`avatar-circles${key === 0 ? '' : key}`}>
          <div className="mm uppercase">
            {key !== 2
              ? fetchName(first_name, last_name)
              : `+${operators.length - 2}`}
          </div>
        </div>
      );
    });
  }, []);

  const columns: ColumnsType<any> = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: '50px',
      render: (date) => {
        return date ? moment(date).format('MM/DD') : '';
      },
    },
    {
      title: 'Tractor',
      dataIndex: 'tractorShortPin',
      key: 'tractorShortPin',
      width: '60px',
      render: (value, record) => {
        return (
          <Tooltip title={record?.tractorPin}>
            <span>{value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Implement',
      dataIndex: 'implement',
      key: 'implement',
      render: (implement) => (!isEmpty(implement) ? implement : 'No Implement'),
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
      width: '80px',
      render(value) {
        const data = getHoursAndMinutes(value);
        return <HrsMins hours={data?.hours} minutes={data?.minutes} />;
      },
    },
    {
      title: 'Distance',
      dataIndex: 'distance',
      key: 'distance',
      width: '80px',
      render(value) {
        return <>{isEmpty(value) ? 0 : value} mi</>;
      },
    },
    // {
    //   title: 'Coverage',
    //   dataIndex: 'coverage',
    //   key: 'coverage',
    //   render(value) {
    //     return <>{Number(value) ?? 0} ac</>;
    //   },
    // },
    {
      title: 'Operator',
      dataIndex: 'operators',
      key: 'operators',
      width: '80px',
      render(value) {
        return (
          <Space>{Array.isArray(value) ? operatorsBlock(value) : ''}</Space>
        );
      },
    },
  ];

  const getRow = (record: any) => {
    const implement = record.implementName || unSelectedType;
    selectTractor({
      ...record,
      implement,
      implementName: implement,
    });
    setSelectedDate(moment(record.date));
    setFromView(view);
    setView('tractor');
  };

  return (
    <>
      <section className="background-parent">
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={10}>
            <div className="frame-parent grid3">
              <div className="frame-wrapper">
                <div className="frame-group">
                  <div className="back-header">
                    <div className="backButtonBig" onClick={onBack}></div>
                    {/* <img src={Back_Button} height="55" onClick={onBack} /> */}
                    <div className="header">
                      <div className="mowing">
                        {operationType === 'No Implement'
                          ? 'Unselected'
                          : operationType}
                      </div>
                      <div className="date-picker-section">
                        <div className="date-month-selection">
                          {/* November 3<sup>rd</sup> - 9<sup>th</sup> */}
                          {dateRangeText}
                        </div>
                        <div className="">
                          <CDateRangePicker
                            handleOnChange={(dates) => setDateRange(dates)}
                            selectedDate={dateRange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="operations-graph noImpSel-block" ref={cardRef}>
                {operationsLoader && <ChartSkeleton />}
                <section className="op_tabs_sec">
                  <div className="chip_container">
                    <div className="shape-compiler">
                      <img
                        className="implement-1-icon1"
                        alt=""
                        src={user_fill}
                      />

                      <div className="image-parser">
                        {operationalHoursByOperations?.length}
                      </div>
                    </div>
                  </div>
                  <Tabs
                    defaultActiveKey={tabid}
                    onChange={(key) => {
                      setTabid(key);
                    }}
                    activeKey={tabid}
                  >
                    <TabPane
                      tab={
                        <>
                          <div>
                            <div className="op_tab_title">
                              Operational Hours
                            </div>
                          </div>

                          <div className="wrapper">
                            <div className="div34">
                              <span className="span">
                                {operationalHours?.hours}
                              </span>
                              <span className="h">h</span>
                              <span className="span1">
                                {' '}
                                {operationalHours?.minutes}
                              </span>
                              <span className="m1">m</span>
                            </div>
                          </div>
                        </>
                      }
                      key="operationalHours"
                    >
                      {/* <img src={graph_image} className="imgResp" /> */}

                      <StackedBarChart
                        loader={operationsLoader}
                        chartId="operationalHoursByOperators2"
                        barColors={['#67afb7', '#f1ce90', '#ec9322', '#cfcfcf']}
                        labels={['level1', 'level2', 'level4', 'level5']}
                        data={operationalHoursByOperations}
                        yTitle="Hours of Operation"
                        // height="100px"
                        dataType="time"
                        tabId={tabid}
                        cardWidth={cardSize.width}
                        page="No Implement"
                      />
                    </TabPane>
                    {/* <TabPane
                      tab={
                        <div>
                          <div className="op_tab_title">Coverage (ac)</div>
                          <div className="container">
                            <div className="div35">{coverage}</div>
                          </div>
                        </div>
                      }
                      key="coverage"
                    >
                      <StackedBarChart
                        loader={operationsLoader}
                        chartId="CoverageByOperators2"
                        barColors={['#4078C0']}
                        labels={['level1']}
                        data={coverageByOperations}
                        yTitle="Acres Covered"
                        // height="100px"
                        dataType="acres"
                        tabId={tabid}
                        cardWidth={cardSize.width}
                        page="No Implement"
                      />
                    </TabPane> */}
                  </Tabs>
                </section>
              </div>
              <div className="bottom-left-graphs implement_sec_tbl">
                <div className="drive_sessions">
                  <div className="header2">
                    <div className="title-info">
                      <div className="operations9">Drive Sessions</div>
                      <div className="info-bubbles">
                        {/* <img className="info-3-icon" src={info_img} /> */}
                      </div>
                    </div>
                    <div className="shape-compiler">
                      <img
                        className="implement-1-icon1"
                        alt=""
                        src={trip_outline}
                      />

                      <div className="image-parser">
                        {completedWork?.length}
                      </div>
                    </div>
                  </div>
                  <div className="howto-improve-sec">
                    <div className="howto-head">
                      <img src={howtoAnalytics} />
                      <span>How To Improve Analytics</span>
                    </div>
                    <div className="howto-desc">
                      Select the implement that was used in each of these
                      operations to include this data in operational overviews.
                    </div>
                  </div>
                </div>

                <div className="tableParent scrollBarPos1">
                  <Table
                    className="completedWorkTable"
                    columns={columns}
                    dataSource={completedWork}
                    scroll={{ y: 150 }}
                    pagination={{ pageSize: completedWork?.length }}
                    rowClassName={() => 'operationsTableRowClassName'}
                    onRow={(record: any) => ({
                      onClick: () => {
                        getRow(record);
                      },
                    })}
                    tableLayout="auto"
                    loading={{
                      indicator: (
                        <div>
                          <Spinner loader={true} />
                        </div>
                      ),
                      spinning: completedWorkLoader,
                    }}
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={14}>
            <div className="scale-scientist">
              <WeekendsFilter
                includeWeekends={includeWeekends}
                toggleWeekends={toggleWeekends}
              />

              <OperationalCoverageMap operationType={operationType} />

              <div
                className={`${
                  noImplementSummary?.savings ? 'container-3' : 'container-2'
                } implement_actions`}
              >
                {!!noImplementSummary?.savings && (
                  <div className="cardBoxNew">
                    <div>
                      <img src={opSavings} />
                    </div>
                    <div className="box-title-sec">
                      <span className="box-title">Savings</span>
                      <span className="box-value">
                        ${noImplementSummary?.savings}
                      </span>
                    </div>
                  </div>
                )}
                <div className="cardBoxNew">
                  <div>
                    <img src={distancenimp} />
                  </div>
                  <div className="box-title-sec">
                    <span className="box-title">Distance (mi)</span>
                    <span className="box-value">
                      {noImplementSummary?.distance}
                    </span>
                  </div>
                </div>
                <div className="cardBoxNew">
                  <div>
                    <img src={avgSpeednimp} />
                  </div>
                  <div className="box-title-sec">
                    <span className="box-title">Average Speed (mph)</span>
                    <span className="box-value">
                      {noImplementSummary?.avgSpeed}
                    </span>
                  </div>
                </div>
              </div>
              {/* <div className="savings">
                <div className="left-side">
                  <div className="graph-call-outs2">
                    <div className="title-info">
                      <div className="operations9">Total Savings</div>
                      <div className="info-bubbles">
                        <img className="info-3-icon" src={info_img} />
                      </div>
                    </div>
                    <div className="filter-merger">$287.32</div>
                  </div>
                  <div className="image-cropper">
                    <div className="graph-call-outs3">
                      <div className="operations10">CO2 Savings</div>
                      <div className="div49">62.8 lbs</div>
                    </div>
                    <div className="numerical-summarizer">
                      <img className="co2-icon" src={co2_img} />
                    </div>
                  </div>
                </div>
                <div className="savings-graph">
                  <img src={savings_graph} style={{ height: '123px' }} />
                </div>
              </div> */}
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default NoImplement;
